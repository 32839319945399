import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import './SectionedContent.less'

export type SectionedSection =
  | {
      content: React.ReactNode
      verticalPaddingClassName?: string
      coloredIn?: boolean
      flex?: number
    }
  | JSX.Element

export type SectionedContentProps = {
  small?: boolean
  className?: string
  style?: React.CSSProperties
  sections: SectionedSection[]
  dashed?: boolean
}

export const SectionedContent = React.memo<SectionedContentProps>(
  ({ small, sections, className, dashed, style }) => {
    const isMobile = useIsMobile()

    return (
      <div
        className={classNames(
          'flex flex-1 flex-col overflow-auto',
          dashed
            ? [
                'sectioned-content-container-dashed',
                isMobile
                  ? 'sectioned-content-container-dashed-mobile'
                  : 'sectioned-content-container-dashed-not-mobile',
              ]
            : 'divide-y divide-solid divide-bz-border',
          small ? 'text-sm' : 'text-base',
          className,
        )}
        style={style}
      >
        {sections.map((section, index) => {
          let coloredIn: boolean | undefined = false
          let content: React.ReactNode = null
          let verticalPaddingClassName = small
            ? 'py-2'
            : isMobile
            ? 'py-4'
            : 'pt-4 pb-6'

          let flex = 0

          if ('content' in section) {
            content = section.content
            coloredIn = section.coloredIn
            verticalPaddingClassName =
              section.verticalPaddingClassName ?? verticalPaddingClassName
            flex = section.flex ?? 0
          } else {
            content = section
          }

          return (
            <div
              key={index}
              className={classNames(
                'border-0',
                verticalPaddingClassName,
                coloredIn ? 'bg-bz-gray-200' : 'bg-white',
              )}
              style={{
                flex,
              }}
            >
              {content}
            </div>
          )
        })}
      </div>
    )
  },
)
