import classNames from 'classnames'
import React from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import useIsMobile from '../../hooks/useIsMobile'
import { Card, CardProps } from '../Card/Card'
import { SectionedContent, SectionedContentProps } from './SectionedContent'

type SectionedCardProps = SectionedContentProps &
  CardProps & {
    accentBarColor?: string
    anchoredFooter?: React.ReactNode
    loading?: boolean
  }

export const SectionedCard = React.memo<SectionedCardProps>(
  ({
    className,
    accentBarColor,
    noBorder = false,
    heightClassName = 'h-full',
    anchoredFooter,
    loading = false,
    dataTestId,
    style,
    small,
    ...rest
  }) => {
    const isMobile = useIsMobile()

    return (
      <Card
        style={style}
        className={className}
        heightClassName={heightClassName}
        dataTestId={dataTestId}
        paddingClassName=""
      >
        {accentBarColor && (
          <div
            className={classNames('absolute inset-0 z-20 h-[10px]')}
            style={{ backgroundColor: accentBarColor }}
          />
        )}
        {loading ? (
          <div className="center-vh flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : null}
        {!loading && (
          <div className="flex flex-1 flex-col overflow-auto">
            <SectionedContent
              className={small ? '*:px-3' : isMobile ? '*:px-4' : '*:px-6'}
              small={small}
              {...rest}
            />
          </div>
        )}
        {anchoredFooter ? (
          <div
            className={classNames(
              'border-0 border-t border-solid border-bz-border bg-bz-gray-100',
              isMobile ? 'px-4 pb-4 pt-2' : 'px-6 py-5',
            )}
          >
            {anchoredFooter}
          </div>
        ) : null}
      </Card>
    )
  },
)
