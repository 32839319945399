import {
  Guid,
  InstalledEquipment,
  InstalledEquipmentSummary,
} from '@breezy/shared'
import { useCallback, useMemo, useState } from 'react'
import { OnsiteModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { EquipmentUpsertModalFormV2 } from '../../../components/EquipmentUpsertModalFormV2/EquipmentUpsertModalFormV2'
import {
  ItemPicker,
  ItemPickerItem,
} from '../../../components/Pricebook/ItemPicker'
import { InstalledEquipmentTitleAndContent } from '../components/InstalledEquipmentCard'

type InstalledEquipmentPickerProps = {
  mode?: 'modal' | 'inline'
  locationGuid: Guid
  installedEquipment: InstalledEquipmentSummary[]
  selectedEquipmentGuids: Guid[]
  onSave: (selectedEquipment: InstalledEquipmentSummary[]) => void
  onCancel: () => void
  refetch: () => void
  setIsDirty?: (isDirty: boolean) => void
}

export const InstalledEquipmentPicker = ({
  locationGuid,
  installedEquipment,
  selectedEquipmentGuids = [],
  onSave,
  onCancel: onCancelExternal,
  refetch,
  mode = 'modal',
  setIsDirty: setExternalIsDirty,
}: InstalledEquipmentPickerProps) => {
  const [selectedItemCountMap, setSelectedItemCountMap] = useState<
    Record<string, number>
  >(
    selectedEquipmentGuids.reduce((acc, id) => {
      acc[id] = 1
      return acc
    }, {} as Record<string, number>),
  )

  const [isDirty, setIsDirtyInternal] = useState(false)

  const setIsDirty = useCallback(
    (flag: boolean) => {
      setIsDirtyInternal(flag)
      setExternalIsDirty?.(flag)
    },
    [setIsDirtyInternal, setExternalIsDirty],
  )

  const onItemSelect = useCallback(
    (id: string, count: number) => {
      setSelectedItemCountMap({ ...selectedItemCountMap, [id]: count })
      setIsDirty(true)
      setExternalIsDirty?.(true)
    },
    [selectedItemCountMap, setExternalIsDirty, setIsDirty],
  )

  const ourOnSave = useCallback(() => {
    const selectedEquipment = installedEquipment.filter(
      item => selectedItemCountMap[item.installedEquipmentGuid] > 0,
    )
    onSave(selectedEquipment)
  }, [installedEquipment, onSave, selectedItemCountMap])

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal(isDirty)

  const items = useMemo(() => {
    return installedEquipment.map(
      (item): ItemPickerItem => ({
        id: item.installedEquipmentGuid,
        name: InstalledEquipment.calculateFriendlyName(item),
        disabled: !!selectedEquipmentGuids.find(
          se => se === item.installedEquipmentGuid,
        ),
        customNode: (
          <div className="w-full py-5">
            <InstalledEquipmentTitleAndContent
              installedEquipment={item}
              leftAlign
            />
          </div>
        ),
        value: 0,
      }),
    )
  }, [installedEquipment, selectedEquipmentGuids])

  const onCancel = useCallback(
    () =>
      withConfirmation(() => {
        onCancelExternal()
        setIsDirty(false)
      }),
    [withConfirmation, onCancelExternal, setIsDirty],
  )

  const content = useMemo(
    () => (
      <>
        <ItemPicker
          canSelectMultiple
          items={items}
          title="Add equipment"
          emptyState="No equipment has been added to this location yet"
          onCancel={onCancel}
          onBackFallback={mode === 'inline' ? onCancel : undefined}
          onSave={ourOnSave}
          selectedItemCountMap={selectedItemCountMap}
          onItemSelect={onItemSelect}
          isSubmitting={false}
          isLoading={false}
          hideMultipleQuantityPicker
          hideCart
          showItemValue={false}
          renderCreateItem={({ onCancel }) => (
            <EquipmentUpsertModalFormV2
              locationGuid={locationGuid}
              onCancel={() => withConfirmation(onCancel)}
              onSave={installedEquipmentGuid => {
                refetch()
                onCancel()
                onItemSelect(installedEquipmentGuid, 1)
                setIsDirty(false)
              }}
              setIsDirty={setIsDirty}
            />
          )}
        />
        <CloseConfirmModal {...closeConfirmProps} />
      </>
    ),
    [
      items,
      onCancel,
      mode,
      ourOnSave,
      selectedItemCountMap,
      onItemSelect,
      closeConfirmProps,
      locationGuid,
      setIsDirty,
      withConfirmation,
      refetch,
    ],
  )

  if (mode === 'inline') {
    return content
  }

  return (
    <OnsiteModal onClose={onCancel} size="large">
      {content}
    </OnsiteModal>
  )
}
