import {
  EndOfAppointmentNextStepsV1JsonSchema,
  EndOfAppointmentNextStepsV2JsonSchema,
  ReviewType,
  ReviewTypeMetadata,
} from '@breezy/shared'
import { emailAddressValueSchema } from '@breezy/shared/src/domain/Email/EmailTypes'
import { telephoneNumberSchema } from '@breezy/shared/src/domain/PhoneNumbers/Phone'
import { zodResolver } from '@hookform/resolvers/zod'
import { Divider, Form, Radio } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { QRCode } from '../../../../../adam-components/QrCode/QrCode'
import FormBody from '../../../../../elements/Forms/FormBody'
import { ReactHookFormItem } from '../../../../../elements/Forms/ReactHookFormItem'
import { TextAreaField } from '../../../../../elements/Forms/TextAreaField'
import { TextField } from '../../../../../elements/Forms/TextField'
import { useEndOfVisitReviewSettings } from '../../../../../hooks/useEndOfVisitReviewSettings'
import { LoadingSpinner } from '../../../../LoadingSpinner'
import angiLogo from './angi_logo.svg'
import facebookLogo from './facebook_logo.svg'
import googleLogo from './google_logo.svg'
import yelpLogo from './yelp_logo.svg'

const EndOfAppointmentNextStepsV1Schema =
  EndOfAppointmentNextStepsV1JsonSchema.shape.data.refine(
    data => {
      return data.allOnsiteWorkCompleted === false
        ? data.allOnsiteWorkNotCompletedDetails
        : true
    },
    {
      message: 'Required when all onsite work is not completed',
      path: ['allOnsiteWorkNotCompletedDetails'],
    },
  )

export type EndOfAppointmentNextStepsV1Data = z.infer<
  typeof EndOfAppointmentNextStepsV1Schema
>

type EndOfAppointmentNextStepsFormV1InnerProps = {
  onSubmit: (data: EndOfAppointmentNextStepsV1Data) => void
  submitElement: JSX.Element
}

export const EndOfAppointmentNextStepsFormV1Inner =
  React.memo<EndOfAppointmentNextStepsFormV1InnerProps>(
    ({ onSubmit, submitElement }) => {
      const {
        control,
        formState: { errors },
        watch,
        handleSubmit,
      } = useForm<EndOfAppointmentNextStepsV1Data>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(EndOfAppointmentNextStepsV1Schema),
      })

      const allOnsiteWorkCompleted = watch('allOnsiteWorkCompleted')

      return (
        <Form
          className="flex flex-1 flex-col sm:min-h-0"
          layout="vertical"
          onSubmitCapture={handleSubmit(onSubmit)}
        >
          <FormBody>
            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="allOnsiteWorkCompleted"
              label="Has all on-site work for the job been completed?"
              helperText="Select 'Yes' if all work for this job are complete. This refers to the entire scope of work, not just today’s visit"
              errors={errors}
              render={({ field }) => (
                <Radio.Group
                  value={field.value}
                  onChange={e => field.onChange(e.target.value)}
                  optionType="button"
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            />

            {allOnsiteWorkCompleted === false && (
              <ReactHookFormItem
                control={control}
                className="flex-1"
                name="allOnsiteWorkNotCompletedDetails"
                label="What work still remains?"
                errors={errors}
                render={({ field }) => (
                  <TextAreaField
                    placeholder="e.g. Waiting on Parts Order, Repair / Install not complete"
                    autoSize={{ minRows: 3 }}
                    {...field}
                  />
                )}
              />
            )}

            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="dispatcherNote"
              label="Add note for dispatch (optional)"
              errors={errors}
              render={({ field }) => (
                <TextAreaField
                  placeholder="e.g. All work completed and paid for."
                  autoSize={{ minRows: 3 }}
                  {...field}
                />
              )}
            />
          </FormBody>
          {submitElement}
        </Form>
      )
    },
  )

// TODO: update to v2 schema
// use a tRPC endpoint so we can fire off the email/sms side effects
const EndOfAppointmentNextStepsV2Schema =
  EndOfAppointmentNextStepsV2JsonSchema.shape.data
    .refine(
      data => {
        return data.allOnsiteWorkCompleted === false
          ? data.allOnsiteWorkNotCompletedDetails
          : true
      },
      {
        message: 'Required when all onsite work is not completed',
        path: ['allOnsiteWorkNotCompletedDetails'],
      },
    )
    .refine(
      data => {
        return data.sendReviewLink && data.notificationType !== 'QR_CODE'
          ? !!data.to
          : true
      },
      {
        message: 'Required when sending a review link via SMS or Email',
        path: ['to'],
      },
    )
    .refine(
      data => {
        if (data.sendReviewLink && data.notificationType === 'SMS') {
          return telephoneNumberSchema.safeParse(data.to).success
        }
        return true
      },
      {
        message: 'Please enter a valid phone number',
        path: ['to'],
      },
    )
    .refine(
      data => {
        if (data.sendReviewLink && data.notificationType === 'EMAIL') {
          return emailAddressValueSchema.safeParse(data.to).success
        }
        return true
      },
      {
        message: 'Please enter a valid email address',
        path: ['to'],
      },
    )

export type EndOfAppointmentNextStepsV2Data = z.infer<
  typeof EndOfAppointmentNextStepsV2Schema
>

type EndOfAppointmentNextStepsFormV2InnerProps = {
  onSubmit: (data: EndOfAppointmentNextStepsV2Data) => void
  submitElement: JSX.Element
  customerFirstName: string
  customerPhoneNumber?: string
  customerEmailAddress?: string
}

export const EndOfAppointmentNextStepsFormV2Inner =
  React.memo<EndOfAppointmentNextStepsFormV2InnerProps>(
    ({
      onSubmit,
      submitElement,
      customerFirstName,
      customerPhoneNumber,
      customerEmailAddress,
    }) => {
      const {
        isEndOfVisitReviewLinkEnabled,
        endOfVisitReviewLinkType,
        endOfVisitReviewTypeIdMap,
        fetching,
      } = useEndOfVisitReviewSettings()

      const {
        control,
        formState: { errors },
        watch,
        handleSubmit,
        setValue,
      } = useForm<EndOfAppointmentNextStepsV2Data>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(EndOfAppointmentNextStepsV2Schema),
        defaultValues: {
          sendReviewLink: true,
          notificationType: 'SMS',
        },
      })

      const allOnsiteWorkCompleted = watch('allOnsiteWorkCompleted')

      const shouldSendReviewLink = watch('sendReviewLink')

      const notificationType = watch('notificationType')

      const endOfVisitReviewId = useMemo(() => {
        if (!endOfVisitReviewLinkType) {
          return undefined
        }

        return endOfVisitReviewTypeIdMap[endOfVisitReviewLinkType]
      }, [endOfVisitReviewTypeIdMap, endOfVisitReviewLinkType])

      useEffect(() => {
        if (notificationType === 'SMS') {
          setValue('to', customerPhoneNumber ?? '')
        }
        if (notificationType === 'EMAIL') {
          setValue('to', customerEmailAddress ?? '')
        }
      }, [
        customerEmailAddress,
        customerPhoneNumber,
        notificationType,
        setValue,
      ])
      if (fetching) {
        return <LoadingSpinner />
      }

      return (
        <Form
          className="flex flex-1 flex-col sm:min-h-0"
          layout="vertical"
          onSubmitCapture={handleSubmit(onSubmit)}
        >
          <FormBody>
            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="allOnsiteWorkCompleted"
              label="Has all on-site work for the job been completed?"
              required
              helperText="Select 'Yes' if all work for this job are complete. This refers to the entire scope of work, not just today’s visit"
              errors={errors}
              render={({ field }) => (
                <Radio.Group
                  value={field.value}
                  onChange={e => field.onChange(e.target.value)}
                  optionType="button"
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            />

            {allOnsiteWorkCompleted === false && (
              <ReactHookFormItem
                control={control}
                className="flex-1"
                name="allOnsiteWorkNotCompletedDetails"
                label="What work still remains?"
                errors={errors}
                required
                render={({ field }) => (
                  <TextAreaField
                    placeholder="e.g. Waiting on Parts Order, Repair / Install not complete"
                    autoSize={{ minRows: 3 }}
                    {...field}
                  />
                )}
              />
            )}

            {allOnsiteWorkCompleted && isEndOfVisitReviewLinkEnabled && (
              <ReactHookFormItem
                control={control}
                className="flex-1"
                name="sendReviewLink"
                label="Would you like to send a review link to the customer?"
                errors={errors}
                required
                render={({ field }) => (
                  <Radio.Group
                    value={field.value}
                    onChange={e => field.onChange(e.target.value)}
                    optionType="button"
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              />
            )}

            {allOnsiteWorkCompleted &&
              isEndOfVisitReviewLinkEnabled &&
              shouldSendReviewLink && (
                <ReactHookFormItem
                  control={control}
                  className="flex-1"
                  name="notificationType"
                  label="Choose a method for providing the review link"
                  required
                  errors={errors}
                  render={({ field }) => (
                    <Radio.Group
                      value={field.value}
                      onChange={e => field.onChange(e.target.value)}
                      optionType="button"
                    >
                      <Radio value="SMS">SMS</Radio>
                      <Radio value="EMAIL">Email</Radio>
                      <Radio value="QR_CODE">QR Code</Radio>
                    </Radio.Group>
                  )}
                />
              )}

            {allOnsiteWorkCompleted &&
              isEndOfVisitReviewLinkEnabled &&
              shouldSendReviewLink &&
              notificationType !== 'QR_CODE' && (
                <ReactHookFormItem
                  control={control}
                  className="flex-1"
                  name="to"
                  required
                  label={
                    notificationType === 'SMS'
                      ? 'Customer phone number'
                      : 'Customer email address'
                  }
                  errors={errors}
                  render={({ field }) => <TextField {...field} />}
                />
              )}

            {allOnsiteWorkCompleted &&
              isEndOfVisitReviewLinkEnabled &&
              shouldSendReviewLink &&
              endOfVisitReviewLinkType &&
              endOfVisitReviewId && (
                <ReviewCallout
                  type={endOfVisitReviewLinkType}
                  customerFirstName={customerFirstName}
                  reviewLinkId={endOfVisitReviewId}
                  className="mt-6"
                  isQrCode={notificationType === 'QR_CODE'}
                />
              )}

            <Divider />

            <ReactHookFormItem
              control={control}
              className="flex-1"
              name="dispatcherNote"
              label="Add note for dispatch (optional)"
              errors={errors}
              render={({ field }) => (
                <TextAreaField
                  placeholder="e.g. All work completed and paid for."
                  autoSize={{ minRows: 3 }}
                  {...field}
                />
              )}
            />
          </FormBody>
          {submitElement}
        </Form>
      )
    },
  )

type ReviewCalloutProps = {
  type: ReviewType
  reviewLinkId: string
  customerFirstName: string
  className?: string
  isQrCode?: boolean
}

export const ReviewTypeToSvg = {
  GOOGLE: googleLogo,
  YELP: yelpLogo,
  FACEBOOK: facebookLogo,
  ANGI: angiLogo,
} as const

const ReviewCallout = React.memo<ReviewCalloutProps>(
  ({ type, customerFirstName, className, isQrCode = false, reviewLinkId }) => {
    const metadata = ReviewTypeMetadata[type]
    const reviewUrl = metadata.reviewLinkFn(reviewLinkId)

    return (
      <div
        className={classNames(
          'flex flex-col gap-3 rounded-xl border border-solid border-bz-border bg-bz-fill-quaternary p-4 md:max-w-[50%]',
          className,
        )}
      >
        <div className="flex gap-3">
          <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-bz-fill-secondary">
            <img
              src={ReviewTypeToSvg[type]}
              alt={metadata.displayName}
              className="h-5 w-5"
            />
          </div>
          <div className="flex flex-col gap-[2px] text-base text-bz-text">
            <div className="font-semibold ">
              {isQrCode
                ? `Scan ${metadata.reviewLinkDisplayName} QR Code`
                : `${metadata.reviewLinkDisplayName} Review Link`}
            </div>
            <div>
              {`${customerFirstName} will be directed to your ${metadata.fullReviewLinkDisplayName}.`}
            </div>
          </div>
          {isQrCode && (
            <QRCode
              title="Scan QR Code"
              url={reviewUrl}
              size={64}
              fullsizeEnlargeMultiplier={4}
              ddActionName="bz-end-of-visit-review-qr-code-expanded"
              className="my-auto"
            />
          )}
        </div>
      </div>
    )
  },
)
