import { DocumentType, gql } from '../../generated'

export const UPDATE_VIEWED_AT_TIME_FOR_USER = gql(/* GraphQL */ `
  mutation AccountDetailsPageUpdatedAccountViewedAtTimeForUserMutation(
    $accountGuid: uuid!
    $userGuid: uuid!
    $viewedAt: timestamptz!
  ) {
    insertAccountsViewedByUserOne(
      object: {
        accountGuid: $accountGuid
        userGuid: $userGuid
        viewedAt: $viewedAt
      }
      onConflict: {
        constraint: accounts_viewed_by_user_pkey
        updateColumns: [viewedAt]
      }
    ) {
      __typename
    }
  }
`)

export const COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query AccountsDetailsPageCompanyConfigQuery($companyGuid: uuid!) {
    companyConfigByPk(companyGuid: $companyGuid) {
      companyGuid
      accountManagerEnabled
    }
  }
`)

export const PHOTO_RECORD_FRAGMENT = gql(/* GraphQL */ `
  fragment PhotoRecord on PhotoLinks {
    photo {
      cdnUrl
      photoGuid
      resourceUrn
      createdAt
      createdByUserGuid
      companyGuid
    }
  }
`)

export const FILE_RECORD_FRAGMENT = gql(/* GraphQL */ `
  fragment FileRecord on FileLinks {
    file {
      cdnUrl
      storageStrategy
      resourceUrn
      fileGuid
      fileName
      metadata
      createdByUserGuid
      fileSizeBytes
      fileTypeMime
      createdAt
    }
  }
`)

export const MAINTENANCE_PLAN_COLLAPSIBLE_FRAGMENT = gql(/* GraphQL */ `
  fragment MaintenancePlanCollapsibleData on MaintenancePlansQueryable {
    maintenancePlanGuid
    locationGuid
    status
    paymentFlow
    maintenancePlanDefinition {
      marketingInfo {
        name
      }
      flare {
        primaryColorHex
      }
    }
    yearlyPriceUsc
    maintenancePlanVisits {
      ...MaintenancePlanVisit
    }
    credits
    discounts
    coveredInstalledEquipmentGuids
    location {
      address {
        line1
      }
    }
  }
`)

export const INSTALLED_EQUIPMENT_FRAGMENT = gql(/* GraphQL */ `
  fragment InstalledEquipment on InstalledEquipment {
    installedEquipmentGuid
    equipmentType
    installationDate
    installationParty
    estimatedEndOfLifeDate
    averageLifeExpectancyYears
    description
    manufacturer
    modelNumber
    serialNumber
    manufacturerWarrantyEndDate
    manufacturerWarrantyStartDate
    manufacturerWarrantyTerms
    operationalStatus
    laborWarrantyStartDate
    laborWarrantyEndDate
    laborWarrantyTerms
  }
`)

export const ACCOUNT_DETAILS_QUERY = gql(/* GraphQL */ `
  query AccountsDetails($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountGuid
      companyGuid
      accountType
      accountDisplayName
      accountManagerUserGuid
      archived
      accountLeadSource(limit: 1) {
        companyLeadSourceGuid
        referringContactGuid
        attributionDescription
        referringContact {
          firstName
          lastName
        }
        companyLeadSource {
          canonicalLeadSourceName
          canonicalLeadSourceNameOverride
        }
      }
      tags {
        tagGuid
        tag {
          name
          color
        }
      }
      photoLinks {
        ...PhotoRecord
      }
      accountLocations {
        photoLinks {
          ...PhotoRecord
        }
        fileLinks {
          ...FileRecord
        }
        location {
          installedHvacSystems {
            installedHvacSystemGuid
            filterSize
            friendlyName
            notes
            zoningInfo: zoningInfoJson
            installedEquipment {
              ...InstalledEquipment
            }
          }
          installedEquipment {
            ...InstalledEquipment
          }
          locationGuid
          maintenancePlans {
            maintenancePlanGuid
          }
          address {
            addressGuid
            line1
            line2
            stateAbbreviation
            zipCode
            city
          }
          companyGuid
          displayName
          estimatedBuildDate
          estimatedSquareFootage
          propertyType
          municipality
        }
        accountGuid
        companyGuid
        isArchived
      }
      jobs {
        jobGuid
        appointments {
          assignments {
            photoLinks {
              ...PhotoRecord
            }
            fileLinks {
              ...FileRecord
            }
            assignmentStatus {
              jobAppointmentAssignmentStatusType
            }
            jobAppointmentAssignmentGuid
            technicianUserGuid
            assignmentEnd
            assignmentStart
            technician {
              firstName
              lastName
              userGuid
            }
          }
          photoLinks {
            ...PhotoRecord
          }
          fileLinks {
            ...FileRecord
          }
          jobAppointmentGuid
          cancellationStatus {
            canceled
          }
          appointmentWindowEnd
          appointmentWindowStart
          appointmentType
          appointmentReferenceNumber
        }
        photoLinks {
          ...PhotoRecord
        }
        fileLinks {
          ...FileRecord
        }
        jobType {
          jobClass
          name
        }
        displayId
        location {
          address {
            line2
            line1
            city
            stateAbbreviation
            zipCode
          }
        }
        createdAt
        jobLifecycleStatus {
          name
        }
        tags {
          tagGuid
          addedByUserGuid
          tag {
            name
            color
          }
        }
      }
      fileLinks {
        ...FileRecord
      }
      maintenancePlans {
        ...MaintenancePlanCollapsibleData
      }
      accountReferenceNumber
      accountCreatedAt
      accountManager {
        firstName
        lastName
      }
      wisetackLoanRecords {
        ...LoanRecord
      }
      wisetackPrequalRecords {
        ...PrequalRecord
      }
      paymentRecords {
        externalPaymentId
        amountUsd
        paymentMethod
        paymentRecordGuid
        occurredAt
        paymentReferenceNumber
        paymentStatuses(orderBy: { occurredAt: DESC }, limit: 1) {
          paymentStatus
        }
        invoiceGuid
        paymentLink {
          wisetackLoanRecordGuid
        }
      }
      mailingAddress {
        city
        line1
        line2
        zipCode
        stateAbbreviation
      }
      accountContacts {
        accountContactGuid
        archived
        primary
        companyGuid
        accountGuid
        contact {
          contactGuid
          companyGuid
          firstName
          lastName
          salutation
          title
          notificationPreferenceType
          primaryEmailAddress {
            emailAddress
            emailAddressGuid
            companyGuid
          }
          primaryPhoneNumber {
            phoneNumber
            phoneNumberGuid
            companyGuid
            type
            unsubscribed
          }
          additionalEmailAddress {
            emailAddress
            emailAddressGuid
            companyGuid
          }
          additionalPhoneNumber {
            phoneNumber
            phoneNumberGuid
            companyGuid
            type
            unsubscribed
          }
        }
      }
    }
  }
`)

export const SET_ACCOUNT_ARCHIVED_MUTATION = gql(/* GraphQL */ `
  mutation SetAccountArchived($accountGuid: uuid!, $archived: Boolean!) {
    updateAccountsByPk(
      pkColumns: { accountGuid: $accountGuid }
      _set: { archived: $archived }
    ) {
      __typename
    }
  }
`)

export const LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription AccountDetailsPageLinkedPhoneCallsSubscription(
    $accountGuid: uuid!
    $companyGuid: uuid!
  ) {
    accounts(
      where: {
        accountGuid: { _eq: $accountGuid }
        _and: { companyGuid: { _eq: $companyGuid } }
      }
    ) {
      integratedPhoneCalls {
        ...LinkedIntegratedPhoneCall
      }
    }
  }
`)

export type LinkedPhoneCallsForAccount = DocumentType<
  typeof LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION
>
