import { useMemo } from 'react'
import { useSubscription } from 'urql'
import { InvoicesBoolExp } from '../../generated/user/graphql'
import { postErrorAlertWithDD } from '../../utils/GraphqlAlerts'
import {
  INVOICES_WIDGET_DATA_SUBSCRIPTION,
  InvoicesWidgetInvoice,
} from './InvoicesWidget.gql'

type InvoicesWidgetGuids = {
  jobGuid?: never
  accountGuid?: never
  locationGuid?: never
}

export type InvoicesWidgetGuidsProp =
  | (Omit<InvoicesWidgetGuids, 'jobGuid'> & { jobGuid: string })
  | (Omit<InvoicesWidgetGuids, 'accountGuid'> & { accountGuid: string })
  | (Omit<InvoicesWidgetGuids, 'locationGuid'> & { locationGuid: string })

type UseInvoicesWidgetDataRes = {
  fetching: boolean
  invoices: InvoicesWidgetInvoice[]
}

export const useInvoicesWidgetData = ({
  jobGuid,
  accountGuid,
  locationGuid,
}: InvoicesWidgetGuidsProp): UseInvoicesWidgetDataRes => {
  const where = useMemo<InvoicesBoolExp>(() => {
    if (jobGuid) {
      return { jobInvoices: { jobGuid: { _eq: jobGuid } } }
    } else if (accountGuid) {
      return { jobInvoices: { job: { accountGuid: { _eq: accountGuid } } } }
    } else if (locationGuid) {
      return { locationInvoices: { locationGuid: { _eq: locationGuid } } }
    } else {
      const msg = 'No valid guid supplied to useInvoicesWidgetData'
      console.error(msg)
      postErrorAlertWithDD(msg)
      throw new Error(msg)
    }
  }, [accountGuid, jobGuid, locationGuid])
  const [{ fetching, data }] = useSubscription({
    query: INVOICES_WIDGET_DATA_SUBSCRIPTION,
    variables: {
      where,
    },
  })

  return useMemo(
    () => ({
      invoices: data?.invoices ?? [],
      fetching,
    }),
    [data?.invoices, fetching],
  )
}
