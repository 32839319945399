import './common/wdyr' // Developer tool to help understand why a component is rendering in non-production environments

import Layout, { Content } from 'antd/lib/layout/layout'
import classNames from 'classnames'
import { BackButtonOverridesWrapper } from '../components/BackButtonOverridesWrapper/BackButtonOverridesWrapper'
import { ActionMenuProvider } from '../components/TechnicianApp/BottomNavBar/ActionMenu/ActionMenuProvider'
import TechnicianAppBottomNavBar from '../components/TechnicianApp/BottomNavBar/TechnicianAppBottomNavBar'
import { PWAInstallPromptWrapper } from '../components/TechnicianApp/PWAInstallPrompt/PWAInstallPrompt'
import ScrollToTop from '../components/TechnicianApp/ScrollToTop/ScrollToTop'
import TechExpDrawersWrapper from '../components/TechnicianApp/TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import TopNavbar from '../components/TechnicianApp/TopNavbar/TopNavBar'
import {
  AnalyticsWrapper,
  initializeDatadogRum,
} from '../providers/AnalyticsWrapper'
import { AppContextWrapper } from '../providers/AppContextWrapper'
import { BlockerWrapper } from '../providers/BlockerWrapper'
import Router from '../providers/Router'
import '../themes/tech-experience.less'
import { TechnicianReactRoutes } from '../utils/TechnicianReactRoutes'
import { Applications } from '../utils/application-type'
import { AuthenticatedAppWrapper, withApp } from './common/CommonAppWrapper'

initializeDatadogRum()

withApp(
  <AuthenticatedAppWrapper>
    <PWAInstallPromptWrapper>
      <TechExpDrawersWrapper>
        <Router
          applicationContext={Applications.TECHNICIAN}
          routes={TechnicianReactRoutes}
          routeWrapper={(
            children,
            header = <TopNavbar />,
            whiteBackground = false,
          ) => (
            <BlockerWrapper>
              <AnalyticsWrapper>
                <AppContextWrapper appType={Applications.TECHNICIAN}>
                  <BackButtonOverridesWrapper>
                    <ActionMenuProvider>
                      <Layout
                        className={classNames(
                          'max-w-screen flex h-screen max-h-screen w-screen flex-col',

                          {
                            'bg-white': whiteBackground,
                          },
                        )}
                      >
                        <ScrollToTop />
                        {header}
                        <div className="h-full overflow-y-auto overflow-x-hidden">
                          <Content className="relative flex min-h-full flex-1 flex-col items-center space-y-2 overflow-auto p-4">
                            {children}
                          </Content>
                        </div>
                        <TechnicianAppBottomNavBar />
                      </Layout>
                    </ActionMenuProvider>
                  </BackButtonOverridesWrapper>
                </AppContextWrapper>
              </AnalyticsWrapper>
            </BlockerWrapper>
          )}
        />
      </TechExpDrawersWrapper>
    </PWAInstallPromptWrapper>
  </AuthenticatedAppWrapper>,
)
