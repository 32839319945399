import {
  BzDateFns,
  CalculatePaths,
  dates,
  phoneUtils,
  streetAddressLine1And2Condensed,
  toPlural,
} from '@breezy/shared'
import {
  faBell,
  faCalendar,
  faCheck,
  faList,
  faMap,
  faPhone,
  faScrewdriverWrench,
  faTimer,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookie } from 'react-use'
import { useSubscription } from 'urql'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageCollapsibleSection } from '../../../adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { OnsitePageContainer } from '../../../adam-components/OnsitePage/OnsitePageContainer'
import { OnsitePageSection } from '../../../adam-components/OnsitePage/OnsitePageSection'
import { OnsitePageSimpleSectionItem } from '../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import { useCurrentOnsitePageDimensions } from '../../../adam-components/OnsitePage/onsitePageUtils'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import useAppNavigation from '../../../hooks/useAppNav'
import useIsMobile, { useIsTinyScreen } from '../../../hooks/useIsMobile'
import { Carousel } from '../../../pages/EstimatesFlow/components/Carousel'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedUserGuid,
} from '../../../providers/PrincipalUser'
import { useModalState } from '../../../utils/react-utils'
import { EstimatesWidget } from '../../EstimatesWidget/EstimatesWidget'
import { useEstimatesWidgetData } from '../../EstimatesWidget/useEstimatesWidgetData'
import { JobClassAccentBadge } from '../../JobClassAccentBadge'
import { FullScreenLoadingSpinner } from '../../LoadingSpinner'
import { useOpenMap } from '../../LocationInfoLink'
import { useNewNotificationCount } from '../../Notifications/Notifications'
import { useTimeClock } from '../../UserTimeClockStatusCard/useTimeClock'
import { WeatherWidget } from '../../WeatherWidget'
import { ProgressSteps } from '../MyAppointmentsPage/AppointmentDetail/AssignmentStatusSteps/AssignmentStatusSteps'
import {
  TECH_APP_HOME_PAGE_DATA_SUBSCRIPTION,
  TechAppHomeAssignment,
} from './HomePage.gql'
import './HomePage.less'

type HomePageVisitCardProps = TechAppHomeAssignment

const HomePageVisitCard = React.memo<HomePageVisitCardProps>(
  ({
    jobAppointment,
    job,
    appointmentGuid,
    assignmentStatus,
    assignmentStart,
    assignmentEnd,
  }) => {
    const navigate = useNavigate()
    const tzId = useExpectedCompanyTimeZoneId()

    const { appointmentType, cancellationStatus } = jobAppointment
    const { jobType, displayId, location, pointOfContact } = job
    const { maintenancePlans, address } = location

    const openMap = useOpenMap(address)

    const maintenancePlan = maintenancePlans?.[0]

    const assignmentWindow = useMemo(() => {
      const range = dates.calculateDateTimeWindow(
        assignmentStart,
        assignmentEnd,
        tzId,
        {
          includeDate: false,
          alwaysShowMinutes: true,
        },
      )
      return range
    }, [assignmentEnd, assignmentStart, tzId])

    const phoneNumber = pointOfContact.primaryPhoneNumber?.phoneNumber

    const completed =
      assignmentStatus?.jobAppointmentAssignmentStatusType === 'COMPLETED'

    return (
      <SectionedCard
        sections={[
          <div
            className={classNames({
              'text-bz-text-tertiary line-through': completed,
            })}
          >
            <div className="flex flex-row items-center">
              {completed && (
                <div className="mr-1.5 flex h-7 w-7 items-center justify-center rounded-full bg-bz-green-700 text-white">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
              <JobClassAccentBadge jobClass={jobType.jobClass} />
              <div className="ml-1.5 text-xl font-semibold">
                {appointmentType}
              </div>
              <div
                className={classNames(
                  'mx-2 flex-1 truncate whitespace-nowrap',
                  completed
                    ? 'text-bz-text-tertiary'
                    : 'text-bz-text-secondary',
                )}
              >
                ({jobType.name} #{displayId})
              </div>
              {maintenancePlan && (
                <div
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-bz-fill-tertiary"
                  style={{
                    color:
                      maintenancePlan.maintenancePlanDefinition?.flare
                        ?.primaryColorHex,
                  }}
                >
                  <FontAwesomeIcon icon={faScrewdriverWrench} />
                </div>
              )}
            </div>
            <ProgressSteps
              assignmentStatus={
                assignmentStatus?.jobAppointmentAssignmentStatusType ?? 'TO_DO'
              }
              assignmentStatusUpdatedAt={assignmentStatus?.updatedAt}
              appointmentCancellationStatus={cancellationStatus}
            />
          </div>,
          <div
            className={classNames('flex flex-row items-start', {
              'text-bz-text-tertiary': completed,
            })}
          >
            <div
              className={classNames(
                'mr-3 flex h-8 w-8 items-center justify-center rounded-md bg-bz-fill-tertiary',
                completed ? 'text-bz-text-tertiary' : 'text-bz-text-secondary',
              )}
            >
              <FontAwesomeIcon icon={faList} />
            </div>
            <div className="min-w-0 flex-1">
              {(
                [
                  [assignmentWindow, '(Assigned)'],
                  [
                    streetAddressLine1And2Condensed(
                      address.line1,
                      address.line2,
                    ),
                    `• ${address.city}, ${address.stateAbbreviation} ${address.zipCode}`,
                  ],
                  [
                    pointOfContact.fullName,
                    phoneNumber ? `• ${phoneUtils.tryFormat(phoneNumber)}` : '',
                  ],
                ] as const
              ).map(([left, right]) => (
                <div key={left} className="truncate whitespace-nowrap">
                  <span className="font-semibold">{left}</span>
                  &nbsp;
                  <span
                    className={
                      completed
                        ? 'text-bz-text-tertiary'
                        : 'text-bz-text-secondary'
                    }
                  >
                    {right}
                  </span>
                </div>
              ))}
            </div>
          </div>,
          <div className="flex flex-row items-center justify-between">
            <Button
              data-testid={`home-page-visit-details-button--${pointOfContact.fullName}`}
              size="large"
              type={completed ? 'default' : 'primary'}
              block={completed}
              onClick={() =>
                navigate(CalculatePaths.appointmentDetails({ appointmentGuid }))
              }
            >
              Details
            </Button>
            {!completed && (
              <div>
                <Button
                  type="link"
                  size="large"
                  icon={<FontAwesomeIcon icon={faMap} />}
                  onClick={openMap}
                >
                  Route
                </Button>
                {phoneNumber && (
                  <Button
                    type="link"
                    size="large"
                    icon={<FontAwesomeIcon icon={faPhone} />}
                    href={`tel:${phoneNumber.replace(/[^0-9]/g, '')}`}
                  >
                    Call
                  </Button>
                )}
              </div>
            )}
          </div>,
        ]}
      />
    )
  },
)

type HomePageVisitsProps = {
  assignments: TechAppHomeAssignment[]
}

const HomePageVisits = React.memo<HomePageVisitsProps>(({ assignments }) => {
  const isMobile = useIsMobile()
  const { contentWidth, pageContentMargin } = useCurrentOnsitePageDimensions()

  const margin = useMemo(
    () => (isMobile ? 16 : pageContentMargin),
    [isMobile, pageContentMargin],
  )

  const carouselItemWidth = useMemo(
    () => contentWidth - (isMobile ? 32 : 0),
    [contentWidth, isMobile],
  )

  if (!assignments.length) {
    return (
      <div className="flex h-[116px] items-center justify-center rounded-xl border border-solid border-bz-border bg-bz-fill-quaternary text-bz-text-tertiary">
        You have no visits scheduled for today.
      </div>
    )
  }
  if (assignments.length === 1) {
    return (
      <div style={{ width: `${carouselItemWidth}px` }}>
        <HomePageVisitCard
          key={assignments[0].assignmentGuid}
          {...assignments[0]}
        />
      </div>
    )
  }
  return (
    <div
      style={{
        margin: `0 -${margin}px`,
      }}
    >
      <Carousel margin={margin}>
        {assignments.map(assignment => {
          return (
            <div className="pb-4" style={{ width: `${carouselItemWidth}px` }}>
              <HomePageVisitCard
                key={assignment.assignmentGuid}
                {...assignment}
              />
            </div>
          )
        })}
      </Carousel>
    </div>
  )
})

const OpenEstimates = React.memo(() => {
  const userGuid = useExpectedUserGuid()
  const data = useEstimatesWidgetData({
    openForUserGuid: userGuid,
  })
  return (
    <OnsitePageCollapsibleSection
      title="Open Estimates"
      noContentMargin
      count={data.estimates.length}
    >
      <div className="mb-3 text-bz-text-tertiary">Last 90 days</div>
      <EstimatesWidget includeContextMetadata includeCallButton {...data} />
    </OnsitePageCollapsibleSection>
  )
})

type NotClockInBannerProps = {
  clockIn: () => void
}

const NotClockInBanner = React.memo<NotClockInBannerProps>(({ clockIn }) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const [dismissed, updateDismissedCookie] = useCookie('clockInBannerDismissed')

  const onDismiss = useCallback(() => {
    const startOfTomorrow = BzDateFns.startOfDay(
      BzDateFns.addDays(BzDateFns.now(tzId), 1),
    )

    updateDismissedCookie('true', {
      expires: startOfTomorrow,
    })
  }, [tzId, updateDismissedCookie])

  if (dismissed) {
    return null
  }
  return (
    <OnsitePageSection hideBottomBorder>
      <OnsitePageSimpleSectionItem theme="warning">
        <div className="flex flex-row flex-wrap items-center justify-between gap-3">
          <div>
            <div>Not clocked in!</div>
            <div className="font-normal">Your time is not being recorded.</div>
          </div>
          <div className="space-x-2">
            <Button type="primary" size="large" onClick={clockIn}>
              Clock In
            </Button>
            <Button size="large" onClick={onDismiss}>
              Dismiss
            </Button>
          </div>
        </div>
      </OnsitePageSimpleSectionItem>
    </OnsitePageSection>
  )
})

type TimeClockWidgetProps = {
  isClockedIn: boolean
  elapsedMinutes: number
  clockedInTime?: Date
  currentActivity?: string
  clockIn: () => void
}

const TimeClockWidget = React.memo<TimeClockWidgetProps>(
  ({
    isClockedIn,
    elapsedMinutes,
    clockedInTime,
    currentActivity,
    clockIn,
  }) => {
    const [clockModalOpen, openClockModal, closeClockModal] = useModalState()
    const [hours, minutes] = useMemo(() => {
      const hours = Math.floor(elapsedMinutes / 60)
      const minutes = elapsedMinutes % 60
      return [hours, minutes]
    }, [elapsedMinutes])

    const onClockIn = useCallback(() => {
      clockIn()
      closeClockModal()
    }, [clockIn, closeClockModal])

    return (
      <>
        {isClockedIn ? (
          <Button
            key="clockedIn"
            shape="round"
            size="large"
            onClick={openClockModal}
            className="flex min-w-0 shrink flex-row items-center overflow-hidden border-bz-green-700 bg-bz-green-100 px-4 text-right leading-none text-bz-green-700"
            style={{ direction: 'rtl' }}
          >
            <div className="ml-2 min-w-0 flex-1 overflow-hidden">
              {`${hours}`.padStart(2, '0')}
              <span className="flashing-timer-colon">:</span>
              {`${minutes}`.padStart(2, '0')}
            </div>

            <FontAwesomeIcon icon={faTimer} className="h-[18px] w-[18px]" />
          </Button>
        ) : (
          <Button
            key="clockedOut"
            shape="circle"
            size="large"
            onClick={openClockModal}
            className="flex items-center justify-center border-bz-orange-700 bg-bz-orange-100 text-bz-orange-700"
            icon={<FontAwesomeIcon icon={faTimer} />}
          />
        )}

        <OnsiteBasicModal
          header="Time tracking"
          open={clockModalOpen}
          onClose={closeClockModal}
        >
          <div>
            {isClockedIn ? (
              <div className="grid grid-cols-2 gap-3">
                {(
                  [
                    [
                      'Clocked In',
                      clockedInTime
                        ? BzDateFns.format(clockedInTime, 'h:mm a')
                        : 'N/A',
                    ],
                    [
                      'Elapsed',
                      <span
                        className={classNames(
                          'font-semibold',
                          ['IDLE', 'DRIVE-TIME', 'ON-SITE'].includes(
                            currentActivity ?? '',
                          )
                            ? 'text-bz-green-700'
                            : 'text-bz-orange-700',
                        )}
                      >
                        {hours ? `${hours}h ` : ''}
                        {minutes}m
                      </span>,
                    ],
                  ] as const
                ).map(([label, value]) => (
                  <div key={label}>
                    <div className="font-semibold">{label}</div>
                    <div>{value}</div>
                  </div>
                ))}
              </div>
            ) : (
              'Your time is not being recorded'
            )}
            <Button
              block
              size="large"
              className="mt-6"
              type={isClockedIn ? 'default' : 'primary'}
              danger={isClockedIn}
              ghost={isClockedIn}
              onClick={onClockIn}
            >
              Clock {isClockedIn ? 'Out' : 'In'}
            </Button>
          </div>
        </OnsiteBasicModal>
      </>
    )
  },
)

export const HomePage = React.memo(() => {
  const tzId = useExpectedCompanyTimeZoneId()
  const userGuid = useExpectedUserGuid()
  const appNav = useAppNavigation()

  const [newNotificationsCount, setLatestNotificationReadAtDate] =
    useNewNotificationCount()

  const title = useMemo(
    () => BzDateFns.format(BzDateFns.now(tzId), 'EEEE, MMM d'),
    [tzId],
  )

  const [today, tomorrow] = useMemo(() => {
    const now = BzDateFns.now(tzId)
    const today = BzDateFns.startOfDay(now)
    const tomorrow = BzDateFns.startOfDay(BzDateFns.addDays(today, 1))
    return [
      BzDateFns.formatISO(today, tzId),
      BzDateFns.formatISO(tomorrow, tzId),
    ]
  }, [tzId])

  const [{ fetching, data }] = useSubscription({
    query: TECH_APP_HOME_PAGE_DATA_SUBSCRIPTION,
    variables: {
      userGuid,
      today,
      tomorrow,
    },
  })

  const numVisits = data?.assignments.length ?? 0

  const numCompletedVisits = useMemo(() => {
    let count = 0
    for (const assignment of data?.assignments ?? []) {
      if (
        assignment.assignmentStatus?.jobAppointmentAssignmentStatusType ===
        'COMPLETED'
      ) {
        count++
      }
    }
    return count
  }, [data?.assignments])

  const onClickNotifications = useCallback(() => {
    appNav.navigateToNotifications()
    setLatestNotificationReadAtDate(undefined)
  }, [appNav, setLatestNotificationReadAtDate])

  const isTinyScreen = useIsTinyScreen()

  const {
    isClockedIn,
    clockedInTime,
    localElapsedDurationMin,
    clockIn,
    currentAssignment,
  } = useTimeClock({ realtime: true })

  if (fetching) {
    return FullScreenLoadingSpinner
  }

  return (
    <OnsitePageContainer>
      <div className="max-w-screen fixed inset-x-0 top-0 z-[1004] flex flex-row items-center justify-between space-x-3 bg-white px-4 py-2">
        <div className="rounded-full border border-solid border-bz-border px-3 py-[3px]">
          <WeatherWidget showCondition={!isTinyScreen} />
        </div>
        <div className="flex min-w-0 flex-row space-x-3">
          <TimeClockWidget
            isClockedIn={isClockedIn}
            elapsedMinutes={localElapsedDurationMin}
            clockedInTime={clockedInTime}
            currentActivity={currentAssignment?.activity}
            clockIn={clockIn}
          />
          <Button
            shape="circle"
            size="large"
            icon={<FontAwesomeIcon icon={faBell} />}
            onClick={onClickNotifications}
          />
          {newNotificationsCount > 0 && (
            <div className="absolute right-px top-px h-2 w-2 rounded-full bg-red-600" />
          )}
        </div>
      </div>
      {!isClockedIn && <NotClockInBanner clockIn={clockIn} />}
      <OnsitePageSection title={title} largeTitle titleBottomMargin={false}>
        <div className="mb-4 mt-2 text-bz-text-tertiary">
          <FontAwesomeIcon icon={faCalendar} className="mr-1.5" />
          {numVisits} {toPlural(numVisits, 'visit')}
          {numVisits > 0 && ` / ${numCompletedVisits} completed`}
        </div>
        <HomePageVisits assignments={data?.assignments ?? []} />
      </OnsitePageSection>
      <OnsitePageSection
        title="Docket"
        largeTitle
        hideBottomBorder
        titleBottomMargin={false}
      />
      <OpenEstimates />
    </OnsitePageContainer>
  )
})
