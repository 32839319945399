import { toNotificationPreferenceTypeDisplayName } from '@breezy/shared'
import { Tag } from 'antd'
import React, { useMemo } from 'react'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import { FormattedEmDash } from '../../elements/EmDash/EmDash'
import { LoadingSpinner } from '../LoadingSpinner'
import { ContactsWidgetContact } from './ContactsWidget.gql'

type ContactProps = ContactsWidgetContact & {
  readonly?: boolean
}

// TODO: https://getbreezyapp.atlassian.net/browse/BZ-4413
const Contact = React.memo<ContactProps>(({ primary, contact }) => {
  const {
    fullName,
    primaryEmailAddress,
    primaryPhoneNumber,
    notificationPreferenceType,
  } = contact

  const sections = useMemo(() => {
    const sections: SectionedSection[] = [
      {
        verticalPaddingClassName: 'pb-2.5 pt-4',
        content: (
          <div className="flex flex-row justify-between">
            <div className="font-semibold">{fullName}</div>
            {primary && <Tag className="mr-0">Primary</Tag>}
          </div>
        ),
      },
      {
        verticalPaddingClassName: 'py-3',
        content: (
          <LabeledItemGrid
            items={[
              'Email',
              primaryEmailAddress?.emailAddress ?? FormattedEmDash,
              'Phone',
              primaryPhoneNumber?.phoneNumber ?? FormattedEmDash,
              'Notifications',
              toNotificationPreferenceTypeDisplayName(
                notificationPreferenceType,
              ),
            ]}
          />
        ),
      },
    ]

    return sections
  }, [
    fullName,
    primary,
    primaryEmailAddress?.emailAddress,
    primaryPhoneNumber?.phoneNumber,
    notificationPreferenceType,
  ])

  return <SectionedCard dashed sections={sections} />
})

export type ContactsWidgetProps = {
  contacts: ContactsWidgetContact[]
  readonly?: boolean
  fetching?: boolean
}

export const ContactsWidget = React.memo<ContactsWidgetProps>(
  ({ contacts, readonly, fetching }) => {
    if (fetching) {
      return <LoadingSpinner />
    }
    return (
      <div className="space-y-3">
        {contacts.map(contact => (
          <Contact key={contact.contactGuid} readonly={readonly} {...contact} />
        ))}
      </div>
    )
  },
)
