import { gql } from '../../generated'
import { InvoicesWidgetInvoiceFragment } from '../../generated/user/graphql'

gql(/* GraphQL */ `
  fragment InvoicesWidgetInvoice on Invoices {
    invoiceGuid
    displayId
    status
    totalUsc
    issuedAt
    createdByUser {
      fullName
    }
    cartItems: invoiceCartItems(orderBy: { seq: ASC }) {
      cartItem {
        name
        unitPriceUsc
        quantity
        isDiscountable
      }
    }
    discounts: invoiceDiscounts {
      discountRate
      type: discountType
      discountAmountUsc
    }
    paymentRecords {
      paymentMethod
      amountUsd
      paymentRecordGuid
    }
  }
`)

export type InvoicesWidgetInvoice = InvoicesWidgetInvoiceFragment

export const INVOICES_WIDGET_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription InvoicesWidgetData($where: InvoicesBoolExp!) {
    invoices(where: $where) {
      ...InvoicesWidgetInvoice
    }
  }
`)
