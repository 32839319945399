import { gql } from '../../generated'
import { EquipmentWidgetEquipmentFragment } from '../../generated/user/graphql'

gql(/* GraphQL */ `
  fragment EquipmentWidgetEquipment on InstalledEquipment {
    installedEquipmentGuid
    manufacturer
    installationDate
    installationParty
    estimatedEndOfLifeDate
    equipmentType
    serialNumber
    modelNumber
    operationalStatus
    averageLifeExpectancyYears
    manufacturerWarrantyEndDate
    manufacturerWarrantyStartDate
    laborWarrantyStartDate
    laborWarrantyEndDate
    location {
      jobs(limit: 1) {
        account {
          lastJobDate
        }
      }
    }
  }
`)

export type EquipmentWidgetEquipment = EquipmentWidgetEquipmentFragment

export const EQUIPMENT_WIDGET_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription EquipmentWidgetData($where: InstalledEquipmentBoolExp!) {
    equipment: installedEquipment(where: $where) {
      ...EquipmentWidgetEquipment
    }
  }
`)
