import { Alert } from './Alert'
import { isNullish } from './type-utils'

export interface LoggerConfig {
  level: string
  logPath?: string
  format?: 'prettyJson' | 'simple' | 'default'
  alertWithOptionalParamsOnErr?: boolean
}

/**
 * Converts a given value to a JSON string. If the value is an Error object, it converts it to a plain object
 * including the Error's properties. If the value is nullish, it converts it to a string representation.
 * If the value cannot be stringified, it returns a default message.
 *
 * @param {any} value - The value to stringify.
 * @returns {string} - The JSON string representation of the value or a default error message.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function safeStringify(value: any, pretty: boolean = false): string {
  if (value instanceof Error) {
    // Convert Error object to a plain object that includes its properties
    const errorObject = {
      // Standard error properties
      name: value.name,
      message: value.message,
      stack: value.stack,
      // Enumerate own properties (which could include custom properties on the error object)
      ...Object.getOwnPropertyNames(value).reduce((obj, key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        obj[key] = (value as any)[key]
        return obj
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as Record<string, any>),
    }
    try {
      return pretty ? JSON.stringify(errorObject, null, 2) : JSON.stringify(errorObject)
    } catch (e) {
      return 'Unstringifiable Error Object'
    }
  } else if (isNullish(value)) {
    return String(value)
  } else {
    try {
      return pretty ? JSON.stringify(value, null, 2) : JSON.stringify(value)
    } catch (e) {
      return 'Unstringifiable Object'
    }
  }
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface Logger {
  debug: (message?: any, ...optionalParams: any[]) => void
  error: (message?: any, ...optionalParams: any[]) => void
  info: (message?: any, ...optionalParams: any[]) => void
  trace: (message?: any, ...optionalParams: any[]) => void
  fatal: (message?: any, ...optionalParams: any[]) => Promise<void>
  warn: (message?: any, ...optionalParams: any[]) => void
}

class BlackHoleLogger implements Logger {
  debug(message?: any, ...optionalParams: any[]) {}
  error(message?: any, ...optionalParams: any[]) {}
  info(message?: any, ...optionalParams: any[]) {}
  trace(message?: any, ...optionalParams: any[]) {}
  fatal(message?: any, ...optionalParams: any[]) {
    return Promise.resolve()
  }
  warn(message?: any, ...optionalParams: any[]) {}
}

const andAlert = (fn: () => void, message: string) => {
  fn()
  Alert.fireAndForget({ key: `Logged Error - ${message}`, message })
}

const andAlertFirst = async (fn: () => Promise<void>, message: string): Promise<void> => {
  try {
    await Alert.publish({ key: `Logged Error - ${message}`, message })
  } catch (e) {
    console.error('Error publishing alert', e)
  }
  await fn()
}

export class WithAlertOnErrorAndFatal implements Logger {
  static MISSING_ERROR_MESSAGE = 'Missing Error Message'

  constructor(private readonly logger: Logger, private readonly alertWithOptionalParamsOnErr = false) {}

  debug = (message?: any, ...optionalParams: any[]) => this.logger.debug(message, ...optionalParams)
  error = (message?: any, ...optionalParams: any[]) => {
    const errorMessage = message ?? WithAlertOnErrorAndFatal.MISSING_ERROR_MESSAGE
    andAlert(
      () => this.logger.error(message, ...optionalParams),
      this.alertWithOptionalParamsOnErr && optionalParams.length > 0
        ? `${errorMessage}\`\`\`${optionalParams.map(o => safeStringify(o, true)).join(', ')}\`\`\``
        : errorMessage,
    )
  }
  info = (message?: any, ...optionalParams: any[]) => this.logger.info(message, ...optionalParams)
  trace = (message?: any, ...optionalParams: any[]) => this.logger.trace(message, ...optionalParams)
  fatal = async (message?: any, ...optionalParams: any[]) => {
    const errorMessage = message ?? WithAlertOnErrorAndFatal.MISSING_ERROR_MESSAGE
    await andAlertFirst(
      async () => await this.logger?.fatal(message, ...optionalParams),
      optionalParams.length > 0
        ? `${errorMessage}\`\`\`${optionalParams.map(o => safeStringify(o, true)).join(', ')}\`\`\``
        : errorMessage,
    )
  }
  warn = (message?: any, ...optionalParams: any[]) => this.logger?.warn(message, ...optionalParams)
}

export class Log {
  static logger: Logger = new BlackHoleLogger()

  static init = (logger: Logger) => (Log.logger = logger)
  static get = (): Logger => Log.logger

  static debug = (message?: any, ...optionalParams: any[]) => Log.logger?.debug(message, ...optionalParams)
  static error = (message?: any, ...optionalParams: any[]) => Log.logger?.error(message, ...optionalParams)
  static info = (message?: any, ...optionalParams: any[]) => Log.logger?.info(message, ...optionalParams)
  static trace = (message?: any, ...optionalParams: any[]) => Log.logger?.trace(message, ...optionalParams)
  static fatal = async (message?: any, ...optionalParams: any[]) => await Log.logger?.fatal(message, ...optionalParams)
  static warn = (message?: any, ...optionalParams: any[]) => Log.logger?.warn(message, ...optionalParams)
}
