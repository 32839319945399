import {
  BzDateFns,
  CalculatePaths,
  formatMoney,
  formatUsc,
  paymentMethodDisplayName,
  toPlural,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import { FormattedEmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { LoadingSpinner } from '../LoadingSpinner'
import { JobsWidgetJob } from './JobsWidget.gql'

const getLineItem = <T,>(
  label: string,
  items: T[],
  renderItem: (item: T) => React.ReactNode,
  getLink: (item: T) => string,
): React.ReactNode[] => {
  if (!items.length) {
    return [label, FormattedEmDash]
  }
  const elems: React.ReactNode[] = []

  for (let i = 0; i < items.length; ++i) {
    const item = items[i]

    elems.push(items.length ? `${label} #${i + 1}` : label)
    elems.push(
      <Link className="block" to={getLink(item)}>
        {renderItem(item)}
      </Link>,
    )
  }

  return elems
}

type JobProps = JobsWidgetJob & {
  readonly?: boolean
}

const Job = React.memo<JobProps>(
  ({
    displayId,
    jobGuid,
    jobType,
    location,
    createdAt,
    workCompletedAt,
    jobInvoices,
    paymentLinks,
    estimates,
    appointments,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const sections = useMemo(() => {
      const sections: SectionedSection[] = [
        {
          verticalPaddingClassName: 'pb-2.5 pt-4',
          content: (
            <div className="flex flex-row justify-between">
              <Link to={CalculatePaths.jobDetails({ jobGuid })} bold>
                {jobType.name} #{displayId}
              </Link>
              {/* TODO: status */}
            </div>
          ),
        },
        {
          verticalPaddingClassName: 'py-3',
          content: (
            <LabeledItemGrid
              items={[
                'Location',
                <Link
                  to={CalculatePaths.locationDetails({
                    locationGuid: location.locationGuid,
                  })}
                >
                  {location.address.line1}
                </Link>,
                'Created Date',
                BzDateFns.formatFromISO(createdAt, 'MMM d, yyyy', tzId),
                'Completed Date',
                workCompletedAt
                  ? BzDateFns.formatFromISO(
                      workCompletedAt,
                      'MMM d, yyyy',
                      tzId,
                    )
                  : FormattedEmDash,
                ...getLineItem(
                  'Invoice',
                  jobInvoices,
                  ({ invoice: { displayId, totalUsc } }) => (
                    <>
                      #{displayId} ({formatUsc(totalUsc)})
                    </>
                  ),
                  ({ invoice: { invoiceGuid } }) =>
                    CalculatePaths.invoiceOverview({ invoiceGuid }),
                ),
                ...getLineItem(
                  'Payment',
                  paymentLinks,
                  ({ paymentRecord: { paymentMethod, amountUsd } }) => (
                    <>
                      {paymentMethodDisplayName(paymentMethod)} (
                      {formatMoney(amountUsd)})
                    </>
                  ),
                  ({ paymentRecord: { paymentRecordGuid } }) =>
                    CalculatePaths.paymentDetails({
                      paymentRecordGuid,
                    }),
                ),
                ...getLineItem(
                  'Estimate',
                  estimates,
                  ({ displayId, estimateOptionsAggregate }) => {
                    const numOptions =
                      estimateOptionsAggregate.aggregate?.count ?? 0
                    return (
                      <>
                        #{displayId} ({numOptions}{' '}
                        {toPlural(numOptions, 'option')})
                      </>
                    )
                  },
                  ({ estimateGuid }) =>
                    CalculatePaths.estimatesDetails({
                      estimateGuid,
                    }),
                ),
                ...getLineItem(
                  'Visit',
                  appointments,
                  ({ appointmentType, appointmentWindowStart }) => (
                    <>
                      {appointmentType} (
                      {BzDateFns.formatFromISO(
                        appointmentWindowStart,
                        'MMM d, yyyy',
                        tzId,
                      )}
                      )
                    </>
                  ),
                  ({ jobAppointmentGuid }) =>
                    CalculatePaths.appointmentDetails({
                      appointmentGuid: jobAppointmentGuid,
                    }),
                ),
              ]}
            />
          ),
        },
      ]

      return sections
    }, [
      appointments,
      createdAt,
      displayId,
      estimates,
      jobGuid,
      jobInvoices,
      jobType.name,
      location.address.line1,
      location.locationGuid,
      paymentLinks,
      tzId,
      workCompletedAt,
    ])

    return <SectionedCard dashed sections={sections} />
  },
)

export type JobsWidgetProps = {
  jobs: JobsWidgetJob[]
  readonly?: boolean
  fetching?: boolean
}

export const JobsWidget = React.memo<JobsWidgetProps>(
  ({ jobs, readonly, fetching }) => {
    if (fetching) {
      return <LoadingSpinner />
    }
    return (
      <div className="space-y-3">
        {jobs.map(job => (
          <Job key={job.jobGuid} readonly={readonly} {...job} />
        ))}
      </div>
    )
  },
)
