import {
  CalculatePaths,
  DynamicPricingType,
  EstimateV2Status,
  HtmlString,
  PermissionV2,
  PricebookTaxRateDto,
  R,
  getTotalUscForOption,
  isNullish,
} from '@breezy/shared'
import {
  faCamera,
  faEdit,
  faEllipsis,
  faEye,
  faReceipt,
  faSave,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'urql'
import { ActionBar } from '../../adam-components/ActionBar/ActionBar'
import {
  MobileActionBar,
  MobileActionBarButton,
} from '../../adam-components/ActionBar/MobileActionBar'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { ItemIconActionRow } from '../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import {
  OnsiteEmbeddedContext,
  useGoBack,
} from '../../adam-components/OnsitePage/onsitePageUtils'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import {
  getHistoryStackLocationFromLocation,
  getUrlFromHistoryStackLocation,
} from '../../components/BackButtonOverridesWrapper/BackButtonOverridesWrapper'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import FinancingSection from '../../components/FinancingSection/FinancingSection'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import { DiscountPickerDiscount } from '../../components/Pricebook/DiscountMultiPicker'
import { TaxRatePicker } from '../../components/Pricebook/TaxRatePicker'
import { AsyncPhotoData } from '../../components/Upload/AsyncPhotoUpload'
import { WysiwygEditor } from '../../components/WysiwygEditor/WysiwygEditor'
import useIsMobile from '../../hooks/useIsMobile'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import { useIsTechApp } from '../../providers/AppContextWrapper'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useMessage } from '../../utils/antd-utils'
import {
  StateSetter,
  useModalState,
  useQueryParamFlag,
  useQueryParamState,
  useStateWithSideEffect,
  useStrictContext,
} from '../../utils/react-utils'
import { useUnblockedNavigateOnSave } from '../../utils/routerUtils'
import { TaxAndDynamicSection } from '../Invoices/components/TaxAndDynamicSection'
import { EditOptionView } from './EditOptionView'
import { EditableEstimateOption } from './EditableEstimateOption'
import { EstimatePresentView } from './EstimatePresentView'
import { DELETE_ESTIMATE_QUERY } from './EstimatesFlow.gql'
import { EstimatesPageContainer } from './EstimatesPageContainer'
import { EstimateActionsModal } from './components/EstimateActionsModal'
import { EstimateMessageGenerator } from './components/EstimateMessageGenerator'
import { Gallery } from './components/Gallery'
import { PhotoUploadModal } from './components/PhotoUploadModal'
import {
  DEFAULT_ESTIMATE_OPTION_NAME,
  EstimateDataContext,
  EstimateEditContext,
  EstimateLocation,
  EstimatePhoto,
  EstimatePointOfContact,
  EstimatesContext,
  Option,
  OptionWithoutTotal,
  getDuplicatedOption,
  isEstimateCreatePath,
  isEstimateOverviewPath,
  isUploadedEstimatePhoto,
  useSaveEstimate,
} from './estimatesFlowUtils'
import { useGenerateEstimateMessage } from './hooks/useGenerateEstimateMessage'

const PREVIEW_MODE_QUERY_PARAM = 'preview'
const OPTION_QUERY_PARAM = 'option'
const PREVIEW_MODE_CAROUSEL_QUERY_PARAM = 'review'

export type ActionPanelProps = {
  status?: EstimateV2Status
  isLoading: boolean
  isPreviewMode?: boolean
  noOptions: boolean
  isDirty: boolean
  photosAreUploading?: boolean
  onPreviewClick: () => void
  onSaveDraft: () => void
  onSave: () => void
  onCancel: () => void
}

const ActionPanel = React.memo<ActionPanelProps>(
  ({
    status,
    isLoading,
    isPreviewMode,
    noOptions,
    isDirty,
    photosAreUploading,
    onPreviewClick,
    onSaveDraft,
    onSave,
    onCancel,
  }) => {
    const isDraft = isNullish(status) || status === 'DRAFT'

    const [actionsModalOpen, openActionsModal, closeActionsModal] =
      useModalState()

    return (
      <>
        <ActionBar>
          {/* I only want this in draft, but not for a new estimate (which doesn't have a status) */}
          {status === 'DRAFT' && (
            <Button
              size="large"
              className="min-w-[40px]"
              icon={<FontAwesomeIcon icon={faEllipsis} />}
              disabled={isLoading}
              onClick={openActionsModal}
            />
          )}
          {isPreviewMode ? (
            <Button
              className="flex-1"
              size="large"
              icon={<FontAwesomeIcon icon={faEdit} />}
              onClick={onCancel}
              disabled={
                isLoading || noOptions || (isDraft && photosAreUploading)
              }
            >
              Edit
            </Button>
          ) : isDraft ? (
            <Button
              className="flex-1"
              size="large"
              icon={<FontAwesomeIcon icon={faSave} />}
              onClick={onSaveDraft}
              disabled={
                isLoading || noOptions || (isDraft && photosAreUploading)
              }
            >
              {photosAreUploading ? 'Uploading Photos...' : 'Save Draft'}
            </Button>
          ) : (
            <Button
              className="flex-1"
              size="large"
              onClick={onCancel}
              disabled={photosAreUploading}
            >
              Cancel
            </Button>
          )}

          {isDraft && (
            <Button
              className="flex-1"
              size="large"
              icon={<FontAwesomeIcon icon={faEye} />}
              disabled={isPreviewMode || isLoading || noOptions}
              onClick={onPreviewClick}
            >
              Preview
            </Button>
          )}
          {isDraft ? (
            <RightArrowButton
              data-testid="create-estimate-button"
              className="flex-[2]"
              loading={photosAreUploading || isLoading}
              disabled={isLoading || noOptions || photosAreUploading}
              onClick={onSave}
            >
              {photosAreUploading ? 'Uploading Photos...' : 'Create Estimate'}
            </RightArrowButton>
          ) : (
            <Button
              className="flex-1"
              size="large"
              type="primary"
              loading={photosAreUploading || isLoading}
              disabled={
                isLoading || noOptions || photosAreUploading || !isDirty
              }
              onClick={onSave}
            >
              {photosAreUploading ? 'Uploading Photos...' : 'Save'}
            </Button>
          )}
        </ActionBar>
        {actionsModalOpen && (
          <EstimateActionsModal canDelete onCancel={closeActionsModal} />
        )}
      </>
    )
  },
)

const MobileActionPanel = React.memo<ActionPanelProps>(
  ({
    status,
    isLoading: externalIsLoading,
    isPreviewMode,
    noOptions,
    isDirty,
    photosAreUploading,
    onPreviewClick,
    onSaveDraft,
    onSave,
    onCancel,
  }) => {
    const isTechApp = useIsTechApp()
    const { estimateGuid, jobAppointmentGuid } =
      useStrictContext(EstimatesContext)
    const { options } = useStrictContext(EstimateDataContext)

    const { defaultOnDeleteTo } = useContext(OnsiteEmbeddedContext)

    const navigate = useNavigate()

    const isDraft = isNullish(status) || status === 'DRAFT'

    const [{ fetching: isDeleting }, deleteEstimate] = useMutation(
      DELETE_ESTIMATE_QUERY,
    )

    const [deleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm] =
      useModalState()

    const onDelete = useCallback(async () => {
      const res = await deleteEstimate({
        estimateGuid,
      })

      if (!res.error) {
        navigate(
          isTechApp && jobAppointmentGuid
            ? `/appointments/${jobAppointmentGuid}`
            : defaultOnDeleteTo ?? '/',
        )
      }
    }, [
      defaultOnDeleteTo,
      deleteEstimate,
      estimateGuid,
      navigate,
      isTechApp,
      jobAppointmentGuid,
    ])

    const isLoading = externalIsLoading || isDeleting

    const editingExistingMode = !isPreviewMode && !isDraft

    const primaryCta = isDraft ? (
      <RightArrowButton
        block
        disabled={isLoading || noOptions || !isDirty || photosAreUploading}
        onClick={onSave}
        data-testid="create-estimate-button"
      >
        {photosAreUploading ? 'Uploading Photos...' : 'Create Estimate'}
      </RightArrowButton>
    ) : (
      <Button
        block
        size="large"
        type="primary"
        disabled={isLoading || noOptions || !isDirty || photosAreUploading}
        onClick={onSave}
      >
        {photosAreUploading ? 'Uploading Photos...' : 'Save'}
      </Button>
    )

    if (isDraft && !isPreviewMode) {
      return (
        <MobileActionBar
          primaryCtaOnClick={onSave}
          primaryCtaText={
            photosAreUploading ? 'Uploading Photos...' : 'Create Estimate'
          }
          primaryCtaDisabled={isLoading || noOptions || photosAreUploading}
          primaryCtaTestId="create-estimate-button"
        >
          <MobileActionBarButton
            onClick={onPreviewClick}
            icon={faEye}
            disabled={isLoading || !options.length}
          >
            Preview
          </MobileActionBarButton>
          <MobileActionBarButton
            onClick={onSaveDraft}
            disabled={isLoading || (isDraft && !isDirty) || photosAreUploading}
            icon={faSave}
          >
            {photosAreUploading ? 'Uploading Photos...' : 'Save Draft'}
          </MobileActionBarButton>

          {!isNullish(status) && (
            <MobileActionBarButton
              danger
              loading={isDeleting}
              icon={faTrash}
              disabled={isLoading}
              onClick={openDeleteConfirm}
            >
              Delete
            </MobileActionBarButton>
          )}

          <OnsiteConfirmModal
            danger
            open={deleteConfirmOpen}
            header="Delete estimate?"
            onCancel={closeDeleteConfirm}
            onConfirm={onDelete}
            confirmText="Delete Estimate"
          >
            Are you sure you want to delete this estimate?
          </OnsiteConfirmModal>
        </MobileActionBar>
      )
    }

    return (
      <div className="border-0 border-t-8 border-solid border-t-bz-gray-300 px-4 py-5">
        <div className="flex flex-row space-x-2">
          {editingExistingMode && (
            <Button block size="large" onClick={onCancel}>
              Cancel
            </Button>
          )}
          {primaryCta}
        </div>

        {isPreviewMode && (
          <div className="mt-2 flex flex-row space-x-2">
            <Button
              block
              size="large"
              icon={<FontAwesomeIcon icon={faEdit} />}
              onClick={onCancel}
            >
              Edit
            </Button>
            <Button
              block
              size="large"
              icon={<FontAwesomeIcon icon={faEye} />}
              disabled
            >
              Preview
            </Button>
          </div>
        )}
      </div>
    )
  },
)

type MainViewProps = {
  isPreviewMode?: boolean
  onCreateOptionClick: () => void
  existingDisplayId?: number
  onPreviewClick: () => void
  savingSuccessful: boolean
  setSuccessfullySaved: StateSetter<boolean>
  setSuccessfullySavedAsDraft: StateSetter<boolean>
}

const MainView = React.memo<MainViewProps>(
  ({
    onCreateOptionClick,
    existingDisplayId,
    onPreviewClick,
    isPreviewMode,
    savingSuccessful,
    setSuccessfullySaved,
    setSuccessfullySavedAsDraft,
  }) => {
    const toastMessage = useMessage()

    const isMobile = useIsMobile()

    const {
      messageHtml,
      options,
      taxRate,
      dynamicPricingType,
      pointOfContact,
    } = useStrictContext(EstimateDataContext)
    const {
      setMessageHtml,
      setTaxRate,
      setDynamicPricingType,
      isDirty,
      clearDirtyFlag,
      status,
      originalAcceptedOptionTotalUsc,
      photoRecords,
      setPhotoRecords,
    } = useStrictContext(EstimateEditContext)

    const isDraft = isNullish(status) || status === 'DRAFT'
    const isAccepted = status === 'ACCEPTED'

    const [isEditingTaxRate, setIsEditingTaxRate] = useState(false)

    const onEditingTaxRateCancel = useCallback(
      () => setIsEditingTaxRate(false),
      [],
    )
    const onEditingTaxRateSubmit = useCallback(
      (taxRate: PricebookTaxRateDto) => {
        setTaxRate(taxRate)
        setIsEditingTaxRate(false)
      },
      [setTaxRate],
    )

    const onJobPhotosAdded = useCallback(
      (photoRecords: EstimatePhoto[]) =>
        setPhotoRecords(photos => [...photos, ...photoRecords]),
      [setPhotoRecords],
    )

    const onPhotoUploadChange = useCallback(
      (data: AsyncPhotoData) =>
        setPhotoRecords(photos => {
          const photoIndex = photos.findIndex(
            photo => photo.photoGuid === data.photoGuid,
          )

          if (data.failed) {
            toastMessage.error(`Could not upload photo ${data.filename}`)
          }

          if (photoIndex === -1) {
            return [
              ...photos,
              {
                ...data,
                fromJob: false,
              },
            ]
          }

          const photo = photos[photoIndex]

          if (data.failed) {
            return R.remove(photoIndex, 1, photos)
          }
          return R.update(
            photoIndex,
            {
              ...photo,
              ...data,
            },
            photos,
          )
        }),
      [setPhotoRecords, toastMessage],
    )

    const { estimateGuid, accountGuid, jobGuid, companyName } =
      useStrictContext(EstimatesContext)

    const [saveEstimate, isUpserting] = useSaveEstimate(existingDisplayId)

    const [showAcceptedChangeConfirm, setShowAcceptedChangeConfirm] =
      useState(false)

    const onSaveEstimate = useCallback(
      async (saveDraft?: boolean) => {
        const didSucceed = await saveEstimate({
          status: saveDraft ? 'DRAFT' : isDraft ? 'CREATED' : status,
          messageHtml,
          options,
          estimateGuid,
          taxRate,
          photoRecords,
          dynamicPricingType,
        })
        if (didSucceed) {
          clearDirtyFlag()
          if (saveDraft) {
            setSuccessfullySavedAsDraft(true)
            clearDirtyFlag()
          } else {
            setSuccessfullySaved(true)
          }
        }
      },
      [
        clearDirtyFlag,
        dynamicPricingType,
        estimateGuid,
        isDraft,
        messageHtml,
        options,
        photoRecords,
        saveEstimate,
        setSuccessfullySaved,
        setSuccessfullySavedAsDraft,
        status,
        taxRate,
      ],
    )

    const goBack = useGoBack()

    const onSave = useCallback(async () => {
      if (isAccepted) {
        const selectedOption = options.find(option => option.selected)
        if (selectedOption) {
          if (selectedOption.totalUsc !== originalAcceptedOptionTotalUsc) {
            setShowAcceptedChangeConfirm(true)
            return
          }
        }
      }
      onSaveEstimate()
    }, [isAccepted, onSaveEstimate, options, originalAcceptedOptionTotalUsc])

    const onSaveDraft = useCallback(() => {
      onSaveEstimate(true)
    }, [onSaveEstimate])

    const displayedOptions = useMemo(() => {
      return options.map((option, i) => ({
        ...option,
        originalIndex: i,
      }))
    }, [options])

    const [photoUploadModalOpen, openPhotoUploadModal, closePhotoUploadModal] =
      useModalState()

    const photosAreUploading = useMemo(
      () =>
        photoRecords.some(
          photo => isUploadedEstimatePhoto(photo) && !photo.recordWritten,
        ),
      [photoRecords],
    )
    const wisetackEnabled = useWisetackEnabled()

    const isLoading = isUpserting || savingSuccessful

    const { generateEstimateMessage, isGenerating } =
      useGenerateEstimateMessage({
        options,
        pointOfContactName: pointOfContact?.firstName,
        setMessageHtml,
        messageHtml,
      })

    return (
      <>
        {isPreviewMode ? (
          <EstimatePresentView
            carouselModeQueryParamKey={PREVIEW_MODE_CAROUSEL_QUERY_PARAM}
          />
        ) : (
          <>
            <OnsitePageSection title="Customer message">
              <WysiwygEditor
                value={messageHtml}
                onChange={value => setMessageHtml(value)}
                disabled={isGenerating}
                dataTestId="estimate-message-tiptap-editor"
              />
              <BehindFeatureFlag
                enabledFeatureFlag="estimate-description-generation"
                render={
                  <Authorized
                    to={
                      PermissionV2.CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED
                    }
                  >
                    <EstimateMessageGenerator
                      isGenerating={isGenerating}
                      onGenerateMessage={generateEstimateMessage}
                    />
                  </Authorized>
                }
                fallback={null}
              />
            </OnsitePageSection>
            <OnsitePageSection title="Gallery">
              {photoRecords.length > 0 && (
                <Gallery
                  photoRecords={photoRecords}
                  className="mb-4"
                  setPhotoRecords={setPhotoRecords}
                />
              )}
              <ItemIconActionRow
                icon={<FontAwesomeIcon icon={faCamera} />}
                onClick={openPhotoUploadModal}
              >
                Add photos
              </ItemIconActionRow>
            </OnsitePageSection>
            <OnsitePageSection title="Options" disabled={isLoading}>
              <div className="mb-4 divide-y-2 divide-dashed divide-bz-gray-500">
                {displayedOptions.map((option, i) => (
                  <div
                    className={classNames('border-0', {
                      'pt-4': i !== 0,
                      'pb-4': i !== displayedOptions.length - 1,
                    })}
                    key={option.optionGuid}
                  >
                    <EditableEstimateOption
                      index={option.originalIndex}
                      option={option}
                      estimateIsAccepted={status === 'ACCEPTED'}
                    />
                  </div>
                ))}
              </div>
              {status !== 'ACCEPTED' && (
                <ItemIconActionRow
                  onClick={onCreateOptionClick}
                  icon={<FontAwesomeIcon icon={faReceipt} />}
                >
                  Add an option
                </ItemIconActionRow>
              )}
            </OnsitePageSection>
            <TaxAndDynamicSection
              taxRate={taxRate}
              openTaxRateEdit={() => setIsEditingTaxRate(true)}
              dynamicPricingType={dynamicPricingType}
              setDynamicPricingType={setDynamicPricingType}
            />
            {wisetackEnabled && (
              <FinancingSection
                accountGuid={accountGuid}
                companyName={companyName}
                jobGuid={jobGuid}
              />
            )}
          </>
        )}
        {isMobile ? (
          <MobileActionPanel
            status={status}
            isLoading={isLoading}
            photosAreUploading={photosAreUploading}
            isPreviewMode={isPreviewMode}
            noOptions={displayedOptions.length === 0}
            isDirty={isDirty}
            onPreviewClick={onPreviewClick}
            onSaveDraft={onSaveDraft}
            onSave={onSave}
            onCancel={goBack}
          />
        ) : (
          <ActionPanel
            status={status}
            isLoading={isLoading}
            photosAreUploading={photosAreUploading}
            isPreviewMode={isPreviewMode}
            noOptions={displayedOptions.length === 0}
            isDirty={isDirty}
            onPreviewClick={onPreviewClick}
            onSaveDraft={onSaveDraft}
            onSave={onSave}
            onCancel={goBack}
          />
        )}
        {isEditingTaxRate && (
          <TaxRatePicker
            preSelectedTaxRateData={taxRate}
            onCancel={onEditingTaxRateCancel}
            onSubmit={onEditingTaxRateSubmit}
          />
        )}
        {showAcceptedChangeConfirm && (
          <OnsiteConfirmModal
            hideCancelButton
            header="Estimate price change"
            onCancel={() => setShowAcceptedChangeConfirm(false)}
            onConfirm={onSaveEstimate}
            confirmText="Got it"
          >
            A change has been made that affects the price of an option that has
            already been accepted by the customer. Be sure to communicate this
            change or re-send the estimate so that they are aware of the change.
          </OnsiteConfirmModal>
        )}

        {photoUploadModalOpen && (
          <PhotoUploadModal
            onClose={closePhotoUploadModal}
            onJobPhotosAdded={onJobPhotosAdded}
            onPhotoUploadChange={onPhotoUploadChange}
          />
        )}
      </>
    )
  },
)

const INDEX_QUERY_STATE_OPTIONS = {
  encode: (index: number) => `${index + 1}`,
  decode: (str: string) => {
    const index = parseInt(str)
    if (isNaN(index)) {
      return -1
    }
    return index - 1
  },
}

type EstimateEditViewProps = {
  isNew?: boolean
  status?: EstimateV2Status
  displayId?: number
  defaultMessageHtml?: HtmlString
  defaultOptions?: Option[]
  defaultTaxRate: PricebookTaxRateDto
  defaultDynamicPricingType?: DynamicPricingType
  defaultDiscounts: DiscountPickerDiscount[]
  defaultPhotoRecords?: EstimatePhoto[]
  pointOfContact?: EstimatePointOfContact
  location?: EstimateLocation
}

export const EstimateEditView = React.memo<EstimateEditViewProps>(
  ({
    isNew,
    status,
    displayId,
    defaultMessageHtml = '',
    defaultOptions = [],
    defaultTaxRate,
    defaultDynamicPricingType,
    defaultDiscounts,
    defaultPhotoRecords = [],
    pointOfContact,
    location,
  }) => {
    const beganAsDraft = useMemo(
      () => status === 'DRAFT',
      // We only want to save what it was on mount.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )
    const { estimateGuid } = useStrictContext(EstimatesContext)
    const [isDirty, setIsDirty] = useState(false)

    const setDirty = useCallback(() => setIsDirty(true), [])
    const clearDirtyFlag = useCallback(() => setIsDirty(false), [])

    const [messageHtml, setMessageHtml] = useStateWithSideEffect(
      setDirty,
      defaultMessageHtml,
    )

    const [taxRate, setTaxRate] = useStateWithSideEffect(
      setDirty,
      defaultTaxRate,
    )

    const [dynamicPricingType, setDynamicPricingType] = useStateWithSideEffect(
      setDirty,
      defaultDynamicPricingType,
    )

    const [optionsWithoutTotals, setOptionsWithoutTotals] =
      useStateWithSideEffect<OptionWithoutTotal[]>(setDirty, defaultOptions)

    const options = useMemo(
      () =>
        optionsWithoutTotals.map(option => ({
          ...option,
          totalUsc: getTotalUscForOption(option, taxRate.rate),
        })),
      [optionsWithoutTotals, taxRate.rate],
    )

    const [photoRecords, setPhotoRecords] = useStateWithSideEffect<
      EstimatePhoto[]
    >(setDirty, defaultPhotoRecords)

    const navigate = useNavigate()
    const goBack = useGoBack()

    const onSaveNavigate = useCallback(() => {
      if (isNew) {
        navigate(CalculatePaths.estimatesDetails({ estimateGuid }))
      } else if (beganAsDraft) {
        navigate(CalculatePaths.estimatesDetails({ estimateGuid }), {
          replace: true,
        })
      } else {
        goBack()
      }
    }, [isNew, beganAsDraft, navigate, estimateGuid, goBack])

    const onDraftSave = useCallback(() => {
      if (isNew) {
        navigate(CalculatePaths.estimatesEdit({ estimateGuid }))
      }
    }, [estimateGuid, isNew, navigate])

    const defaultValues = useMemo(
      () =>
        isNew
          ? undefined
          : {
              status,
              defaultMessageHtml,
              defaultOptions,
              defaultTaxRate,
              defaultDiscounts,
              defaultPhotoRecords,
              defaultDynamicPricingType,
            },
      [
        defaultDiscounts,
        defaultDynamicPricingType,
        defaultMessageHtml,
        defaultOptions,
        defaultPhotoRecords,
        defaultTaxRate,
        isNew,
        status,
      ],
    )
    const {
      savingSuccessful,
      setSuccessfullySaved,
      setSuccessfullySavedAsDraft,
    } = useUnblockedNavigateOnSave(onSaveNavigate, onDraftSave, defaultValues)

    const shouldBlock = useCallback<BlockerFunction>(
      ({ currentLocation, nextLocation }) => {
        if (savingSuccessful) {
          return false
        }
        // If it's not dirty, don't block
        if (!isDirty) {
          return false
        }

        // If we're navigating from one page to the same page (with certain query params excluded), then we don't block
        const currentUrl = getUrlFromHistoryStackLocation(
          getHistoryStackLocationFromLocation(currentLocation),
        )
        const nextUrl = getUrlFromHistoryStackLocation(
          getHistoryStackLocationFromLocation(nextLocation),
        )

        // Going to and from preview mode doesn't matter
        currentUrl.searchParams.delete(PREVIEW_MODE_QUERY_PARAM)
        nextUrl.searchParams.delete(PREVIEW_MODE_QUERY_PARAM)

        // Likewise, opening the carousel in preview mode doesn't matter
        currentUrl.searchParams.delete(PREVIEW_MODE_CAROUSEL_QUERY_PARAM)
        nextUrl.searchParams.delete(PREVIEW_MODE_CAROUSEL_QUERY_PARAM)

        // Going to and from the individual options doesn't matter. They have their own blocker
        currentUrl.searchParams.delete(OPTION_QUERY_PARAM)
        nextUrl.searchParams.delete(OPTION_QUERY_PARAM)

        if (currentUrl.href === nextUrl.href) {
          return false
        }
        return isDirty
      },
      [isDirty, savingSuccessful],
    )

    const blockMatcher = useCallback(() => {
      const url = new URL(window.location.href)
      const isOverviewPage = isEstimateOverviewPath(url.pathname)
      const isCreatePage = isEstimateCreatePath(url.pathname)
      const isCorrectPathName = !!(isOverviewPage || isCreatePage)
      return isCorrectPathName && url.search.includes('option')
    }, [])

    const blocker = useBlocker('EstimateEditView', shouldBlock, blockMatcher)

    useUnsavedChangesWarning(isDirty)

    const [selectedOptionIndex, setSelectedOptionIndex] = useQueryParamState(
      OPTION_QUERY_PARAM,
      -1,
      INDEX_QUERY_STATE_OPTIONS,
    )

    const hasOptionSelected = selectedOptionIndex !== -1

    const selectedOption: Option | undefined = options[selectedOptionIndex]

    const onCreateOptionClick = useCallback(() => {
      setSelectedOptionIndex(optionsWithoutTotals.length)
    }, [optionsWithoutTotals.length, setSelectedOptionIndex])

    const pageContainerRef = useRef<HTMLDivElement>(null)

    const [isPreviewMode, openPreviewMode, closePreviewMode] =
      useQueryParamFlag(PREVIEW_MODE_QUERY_PARAM)

    // Edge case: they go directly to a url that's in preview mode but there are no options (if they hit refresh on the
    // create page while in preview mode, the state will have no options). If we let them show preview mode it looks
    // strange because it has zero options (in the UI the preview button is disabled if there are no options yet).
    useEffect(() => {
      if (isPreviewMode && !options.length) {
        closePreviewMode()
      }
    }, [closePreviewMode, isPreviewMode, options.length])

    const onPreviewClick = useCallback(() => {
      if (isPreviewMode) {
        closePreviewMode()
      } else {
        openPreviewMode()

        // The timeout is necessary for mobile Safari ugh
        setTimeout(() => {
          pageContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100)
      }
    }, [closePreviewMode, isPreviewMode, openPreviewMode])

    const onSelectedOptionSave = useCallback(
      (option: OptionWithoutTotal) => {
        setOptionsWithoutTotals(prevOptions => {
          const newOptions = [...prevOptions]
          if (option.recommended) {
            for (const option of newOptions) {
              option.recommended = false
            }
          }
          newOptions[selectedOptionIndex] = {
            ...option,
            displayName: option.displayName,
          }
          return newOptions
        })
      },
      [selectedOptionIndex, setOptionsWithoutTotals],
    )

    const onSetRecommended = useCallback(
      (recommendedIndex: number, recommended?: boolean) => {
        setOptionsWithoutTotals(options =>
          options.map((option, i) => ({
            ...option,
            recommended: !!recommended && recommendedIndex === i,
          })),
        )
      },
      [setOptionsWithoutTotals],
    )

    const shiftOption = useCallback(
      (index: number, up: boolean) =>
        setOptionsWithoutTotals(
          R.move(up ? index - 1 : index, up ? index : index + 1),
        ),
      [setOptionsWithoutTotals],
    )

    const deleteOption = useCallback(
      (index: number) => setOptionsWithoutTotals(R.remove(index, 1)),
      [setOptionsWithoutTotals],
    )

    const [isDuplicating, startDuplicating, stopDuplicating] = useModalState()

    const duplicateOption = useCallback(
      (index: number) => {
        setSelectedOptionIndex(index)
        startDuplicating()
      },
      [setSelectedOptionIndex, startDuplicating],
    )

    const [pageTitle, content, statusBannerText, statusColorClassName] =
      useMemo(() => {
        if (isPreviewMode) {
          return [
            'Preview Estimate',
            <MainView
              isPreviewMode
              onCreateOptionClick={onCreateOptionClick}
              existingDisplayId={displayId}
              onPreviewClick={onPreviewClick}
              savingSuccessful={savingSuccessful}
              setSuccessfullySaved={setSuccessfullySaved}
              setSuccessfullySavedAsDraft={setSuccessfullySavedAsDraft}
            />,
            'Preview Mode',
            'text-[#871400] bg-[#FFD8BF]',
          ]
        }
        if (isDuplicating && hasOptionSelected) {
          return [
            DEFAULT_ESTIMATE_OPTION_NAME,
            <EditOptionView
              index={selectedOptionIndex + 1}
              editingOption={getDuplicatedOption({
                ...selectedOption,
                seq: selectedOptionIndex + 1,
                displayName: '',
              })}
              onSave={option => {
                stopDuplicating()
                setOptionsWithoutTotals(optionsWithoutTotals =>
                  R.insert(
                    selectedOptionIndex + 1,
                    option,
                    optionsWithoutTotals,
                  ),
                )
              }}
              onCancelSideEffect={stopDuplicating}
              defaultDiscounts={defaultDiscounts}
            />,
          ]
        }
        if (hasOptionSelected) {
          return [
            DEFAULT_ESTIMATE_OPTION_NAME,
            <EditOptionView
              index={selectedOptionIndex}
              editingOption={selectedOption}
              onSave={onSelectedOptionSave}
              defaultDiscounts={defaultDiscounts}
            />,
          ]
        }
        const content = (
          <MainView
            isPreviewMode={!!isPreviewMode}
            onCreateOptionClick={onCreateOptionClick}
            existingDisplayId={displayId}
            onPreviewClick={onPreviewClick}
            savingSuccessful={savingSuccessful}
            setSuccessfullySaved={setSuccessfullySaved}
            setSuccessfullySavedAsDraft={setSuccessfullySavedAsDraft}
          />
        )

        return [
          displayId ? `Estimate #${displayId}` : 'Estimate',
          content,
          isNullish(status) || status === 'DRAFT' ? 'Draft' : 'Editing',
        ]
      }, [
        isPreviewMode,
        isDuplicating,
        hasOptionSelected,
        onCreateOptionClick,
        displayId,
        onPreviewClick,
        savingSuccessful,
        setSuccessfullySaved,
        setSuccessfullySavedAsDraft,
        status,
        selectedOptionIndex,
        selectedOption,
        stopDuplicating,
        defaultDiscounts,
        setOptionsWithoutTotals,
        onSelectedOptionSave,
      ])

    const originalAcceptedOptionTotalUsc = useMemo(() => {
      if (!status || status !== 'ACCEPTED') {
        return undefined
      }
      const selectedOption = defaultOptions.find(option => option.selected)
      if (!selectedOption) {
        return undefined
      }

      return selectedOption.totalUsc
    }, [defaultOptions, status])

    return (
      <EstimateDataContext.Provider
        value={{
          messageHtml,
          taxRate,
          options,
          photoRecords,
          dynamicPricingType,
          pointOfContact,
          location,
        }}
      >
        <EstimateEditContext.Provider
          value={{
            setMessageHtml,
            setTaxRate,
            setDynamicPricingType,
            onSetRecommended,
            setSelectedOptionIndex,
            shiftOption,
            deleteOption,
            duplicateOption,
            clearDirtyFlag,
            isDirty,
            status,
            originalAcceptedOptionTotalUsc,
            photoRecords,
            setPhotoRecords,
          }}
        >
          <EstimatesPageContainer
            containerRef={pageContainerRef}
            statusText={statusBannerText}
            statusColorClassName={statusColorClassName}
            title={pageTitle}
            spaceForNav={!isPreviewMode}
          >
            {content}
          </EstimatesPageContainer>
          {blocker.state === 'blocked' && (
            <OnsiteConfirmModal
              danger
              header="Are you sure you want to exit?"
              onCancel={blocker.reset}
              onConfirm={blocker.proceed}
              confirmText="Yes, Exit"
            >
              You have unsaved changes. If you exit, they will be lost.
              <br />
              Are you sure you want to exit?
            </OnsiteConfirmModal>
          )}
        </EstimateEditContext.Provider>
      </EstimateDataContext.Provider>
    )
  },
)
