/// Icons

import { PermissionV2, SharedRoutes, TechnicianRoutes } from '@breezy/shared'
import { Navigate } from 'react-router-dom'
import { AdminImpersonateUser } from '../components/Admin/AdminImpersonateUser/AdminImpersonateUser'
import { StopImpersonatingLimbo } from '../components/Admin/AdminImpersonateUser/StopImpersonatingLimbo'
import { Page } from '../components/Page/Page'
import AccountCreationPage from '../pages/AccountCreationPage/AccountCreationPage'

import NotACompanyUserWarning from '../components/Permissions/NotACompanyUserWarning/NotACompanyUserWarning'
import AccountSearchPage from '../components/TechnicianApp/AccountSearchPage/AccountSearchPage'
import { HomePage } from '../components/TechnicianApp/HomePage/HomePage'
import AppointmentDetailViewLoader from '../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/AppointmentDetail'
import AppointmentsList from '../components/TechnicianApp/MyAppointmentsPage/AppointmentsList/AppointmentsList'
import { EstimateCreationPage } from '../pages/EstimatesFlow/EstimateCreationPage'
import { EstimateOverviewPage } from '../pages/EstimatesFlow/EstimateOverviewPage'
import { InvoiceCreationPage } from '../pages/Invoices/InvoiceCreationPage'
import { InvoiceOverviewPage } from '../pages/Invoices/InvoiceOverviewPage'
import { NotificationsPage } from '../pages/NotificationsPage/NotificationsPage'
import { OfficeRefundPage } from '../pages/RefundPage/OfficeRefundPage'
import TechnicianSchedulePage from '../pages/TechnicianSchedulePage/TechnicianSchedulePage'
import { Placeholder } from './Placeholder'
import { ReactRouteMap } from './ReactRoute'
import { SharedReactRoutes } from './SharedRoutes'

const noPermissionRequired = undefined

const RootRedirect = () => <Navigate to="/" replace />
export const TechnicianReactRoutes = {
  ...SharedReactRoutes,
  NOT_FOUND: {
    ...TechnicianRoutes.NOT_FOUND,
    element: (
      <Placeholder>The page you are looking for does not exist.</Placeholder>
    ),
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  UNAUTHORIZED: {
    ...TechnicianRoutes.UNAUTHORIZED,
    element: (
      <div className="flex min-h-[300px] w-full flex-col items-center justify-center">
        <Placeholder>
          You are not authorized to access this application. Contact your
          administrator for assistance.
        </Placeholder>
      </div>
    ),
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  NO_COMPANY: {
    ...TechnicianRoutes.NO_COMPANY,
    element: (
      <div className="w-full max-w-[700px]">
        <NotACompanyUserWarning redirectToRouteIfCompanyUser="/">
          <div>
            <h1 className="text-center">Select a Technician To Impersonate</h1>
            <div className="center-children-vh column">
              <AdminImpersonateUser />
            </div>
          </div>
        </NotACompanyUserWarning>
      </div>
    ),
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  HOME: {
    ...TechnicianRoutes.HOME,
    element: <Page requiresCompanyUser children={<HomePage />} />,
    permission: PermissionV2.USE_FIELD_EXPERIENCE,
    principalUserRequiresCompany: true,
    unauthedElement: <Navigate to="/unauthorized" replace />,
    header: null,
    whiteBackground: true,
  },
  STOP_IMPERSONATING: {
    ...TechnicianRoutes.STOP_IMPERSONATING,
    element: <StopImpersonatingLimbo />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: false,
  },
  APPOINTMENT_DETAILS: {
    ...TechnicianRoutes.APPOINTMENT_DETAILS,
    element: (
      <Page requiresCompanyUser children={<AppointmentDetailViewLoader />} />
    ),
    header: null,
    whiteBackground: true,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
  },
  ACCOUNT_CREATION: {
    ...SharedRoutes.ACCOUNT_CREATION,
    element: <AccountCreationPage />,
    permission: PermissionV2.OFFICE_ACCOUNTS_JOBS_MANAGE,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  ACCOUNT_SEARCH: {
    ...TechnicianRoutes.ACCOUNT_SEARCH,
    element: <AccountSearchPage />,
    unauthedElement: (
      <div className="flex h-full w-full items-center justify-center">
        <Placeholder>Unauthorized to view this page</Placeholder>
      </div>
    ),
    permission: PermissionV2.FIELD_ACCOUNTS_CONTACTS_VIEW_FULL,
    principalUserRequiresCompany: true,
  },
  SCHEDULE: {
    ...TechnicianRoutes.SCHEDULE,
    element: <TechnicianSchedulePage />,
    permission:
      PermissionV2.FIELD_SCHEDULE_APPOINTMENTS_ASSIGNED_MANAGE_CURRENT,
    unauthedElement: <RootRedirect />,
    principalUserRequiresCompany: true,
  },
  ESTIMATE_V2_CREATION: {
    ...TechnicianRoutes.ESTIMATE_V2_CREATION,
    element: <EstimateCreationPage />,
    permission: PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED,
    unauthedElement: <RootRedirect />,
    principalUserRequiresCompany: true,
  },
  ESTIMATE_V2_OVERVIEW: {
    ...TechnicianRoutes.ESTIMATE_V2_OVERVIEW,
    element: <EstimateOverviewPage />,
    permission: PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED,
    unauthedElement: <RootRedirect />,
    principalUserRequiresCompany: true,
  },
  NEW_INVOICE_V2: {
    ...SharedRoutes.NEW_INVOICE_V2,
    element: <InvoiceCreationPage />,
    permission: PermissionV2.FIELD_JOB_INFORMATION_MANAGE_ASSIGNED,
    principalUserRequiresCompany: true,
  },
  INVOICE_OVERVIEW: {
    ...SharedRoutes.INVOICE_OVERVIEW,
    element: <InvoiceOverviewPage />,
    permission: noPermissionRequired,
    principalUserRequiresCompany: true,
  },
  NOTIFICATIONS: {
    ...TechnicianRoutes.NOTIFICATIONS,
    element: <NotificationsPage />,
    unauthedElement: <Navigate to="/unauthorized" replace />,
    permission: PermissionV2.USE_FIELD_EXPERIENCE,
    principalUserRequiresCompany: true,
  },
  REFUND_PAYMENT: {
    ...TechnicianRoutes.REFUND_PAYMENT,
    element: <OfficeRefundPage />,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_MANAGE,
    unauthedElement: <Navigate to="/" replace />,
    principalUserRequiresCompany: true,
  },
  MY_APPOINTMENTS: {
    ...TechnicianRoutes.MY_APPOINTMENTS,
    element: (
      <div className="w-full max-w-[700px]">
        <Page requiresCompanyUser={true} disableLayout>
          <AppointmentsList />
        </Page>
      </div>
    ),
    unauthedElement: <Navigate to="/unauthorized" replace />,
    permission: PermissionV2.USE_FIELD_EXPERIENCE,
    principalUserRequiresCompany: true,
  },
} satisfies ReactRouteMap

export type TechnicianReactRoutesInfo = typeof TechnicianReactRoutes
