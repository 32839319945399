import { BzDateFns, IsoDateString } from '@breezy/shared'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { OnsiteModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import useIsMobile from '../../../hooks/useIsMobile'

type EstimateAcceptedWidgetProps = {
  footer?: React.ReactNode
  displayId: number
  acceptedAt: IsoDateString
  acceptedEmailSentTo?: string
}

export const EstimateAcceptedWidget = React.memo<EstimateAcceptedWidgetProps>(
  ({ footer, displayId, acceptedAt, acceptedEmailSentTo }) => {
    const isMobile = useIsMobile()
    const acceptedAtStr = useMemo(() => {
      // Show this in the local timezone. Unlike other parts of the app where we need to always talk in the same
      // timezone (the company timezone) is this a confirmation screen and it would be confusing if this showed a time
      // that didn't match what they did.
      const localDate = BzDateFns.parseISO(acceptedAt, BzDateFns.LOCAL_TZ)
      return BzDateFns.format(localDate, 'MMMM d, yyyy')
    }, [acceptedAt])

    const gridItems = useMemo(() => {
      const items: [label: string, value: string][] = [
        ['Estimate ID', `#${displayId}`],
        ['Signed Date', acceptedAtStr],
      ]
      if (acceptedEmailSentTo) {
        return [['Customer Email', acceptedEmailSentTo], ...items]
      }
      return items
    }, [acceptedAtStr, acceptedEmailSentTo, displayId])
    return (
      <OnsiteModal open>
        <div className={classNames('', isMobile ? 'pb-4' : 'pb-6')}>
          <div className="mx-auto my-4 flex h-[72px] w-[72px] items-center justify-center rounded-full border-8 border-solid border-daybreak-blue-100 bg-daybreak-blue-200">
            <FontAwesomeIcon
              icon={faCheck}
              className="text-4xl text-bz-primary"
            />
          </div>
          <div className="mb-2 text-center text-3xl font-semibold">
            Estimate Accepted
          </div>
          <div className="text-center text-base">
            A copy of the estimate has been sent to the email address below.
          </div>

          <div className="my-4 grid w-full grid-cols-[auto_1fr] rounded-lg border border-solid border-bz-border bg-bz-fill-quaternary px-3 py-1 text-base *:border-dashed *:border-bz-border *:py-2">
            {gridItems.map(([label, value], i) => (
              <React.Fragment key={label}>
                <div
                  className={classNames(
                    'whitespace-nowrap border-0 font-semibold',
                    {
                      'border-b': i < gridItems.length - 1,
                    },
                  )}
                >
                  {label}
                </div>
                <div
                  className={classNames('truncate border-0 text-right', {
                    'border-b': i < gridItems.length - 1,
                  })}
                >
                  {value}
                </div>
              </React.Fragment>
            ))}
          </div>
          {footer}
        </div>
      </OnsiteModal>
    )
  },
)
