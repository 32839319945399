import { BzDateFns, bzExpect, isNullish } from '@breezy/shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSubscription } from 'urql'
import { useIsTechClockedIn } from '../../hooks/technician/useIsTechClockedIn'
import { trpc } from '../../hooks/trpc'
import {
  useExpectedCompany,
  useExpectedPrincipal,
} from '../../providers/PrincipalUser'
import { UserTimeClockCurrentAssignment } from './UserTimeClockStatusCard'
import { STATUS_CARD_QUERY } from './UserTimeClockStatusCard.gql'

type TimeClockOptions = {
  realtime: boolean
}

export const useTimeClock = (options: TimeClockOptions) => {
  const company = useExpectedCompany()
  const user = useExpectedPrincipal()
  const startOfToday = BzDateFns.formatISO(
    BzDateFns.startOfDay(BzDateFns.now(company.timezone)),
    company.timezone,
  )

  const { isClockedIn, clockInTime } = useIsTechClockedIn()
  const [waitingOnClockState, setIsWaitingOnClockState] = useState<
    'clockIn' | 'clockOut' | undefined
  >(undefined)
  const [res, refetch] = useSubscription({
    query: STATUS_CARD_QUERY,
    variables: {
      companyGuid: company.companyGuid,
      userGuid: user.userGuid,
      startTime: !isNullish(clockInTime) ? clockInTime : startOfToday,
    },
  })

  const updateTimeClockStatus =
    trpc.timesheets['timesheets:update-time-clock-status'].useMutation()

  const clockedInTime = useMemo<Date | undefined>(() => {
    if (isNullish(clockInTime)) {
      return undefined
    }

    return BzDateFns.parseISO(bzExpect(clockInTime), company.timezone)
  }, [clockInTime, company.timezone])

  const startingElapsedDuration: number = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return 0
    }

    let elapsedMin: number = res.data.timesheetEntries.reduce<number>(
      (acc, entry) =>
        acc +
        (isNullish(entry.entryLengthInMinutes)
          ? 0
          : entry.entryLengthInMinutes),
      0,
    )

    const currTimesheetEntry =
      res.data.timesheetEntries[res.data.timesheetEntries.length - 1]

    if (isNullish(currTimesheetEntry.finalEndTime)) {
      const startTime = BzDateFns.parseISO(
        bzExpect(currTimesheetEntry.finalStartTime),
        company.timezone,
      )

      const now = BzDateFns.now(company.timezone)
      const elapsedMinForCurrTimesheetEntry = BzDateFns.differenceInMinutes(
        startTime,
        now,
      )
      elapsedMin += Math.abs(elapsedMinForCurrTimesheetEntry)
    }

    return elapsedMin
  }, [company.timezone, res.data])

  const [localElapsedDurationMin, setLocalElapsedDurationMin] =
    useState<number>(startingElapsedDuration)

  const currentAssignment = useMemo<
    UserTimeClockCurrentAssignment | undefined
  >(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return undefined
    }

    const { timesheetEntryLinkData, timesheetEntryActivity } =
      res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
    if (
      isNullish(timesheetEntryLinkData[0].jobGuid) ||
      isNullish(timesheetEntryLinkData[0].jobAppointmentGuid) ||
      isNullish(timesheetEntryLinkData[0].jobAppointmentAssignmentGuid)
    ) {
      return undefined
    }

    return {
      activity: bzExpect(timesheetEntryActivity?.activityName),
      jobGuid: bzExpect(timesheetEntryLinkData[0].jobGuid),
      jobAppointmentGuid: bzExpect(
        timesheetEntryLinkData[0].jobAppointmentGuid,
      ),
      jobAppointmentAssignmentGuid: bzExpect(
        timesheetEntryLinkData[0].jobAppointmentAssignmentGuid,
      ),
    }
  }, [res.data])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (waitingOnClockState) {
        setIsWaitingOnClockState(undefined)
      }
    }, 2000)

    return () => clearTimeout(timer)
  }, [waitingOnClockState, setIsWaitingOnClockState])

  useEffect(() => {
    setLocalElapsedDurationMin(startingElapsedDuration)
  }, [startingElapsedDuration])

  useEffect(() => {
    if (!isClockedIn) {
      setLocalElapsedDurationMin(0)
      return
    }

    if (options.realtime) {
      const intervalId = setInterval(() => {
        setLocalElapsedDurationMin(prev => prev + 1)
      }, 1000 * 60)

      return () => clearInterval(intervalId)
    }

    return () => {}
  }, [
    isClockedIn,
    options.realtime,
    waitingOnClockState,
    setIsWaitingOnClockState,
  ])

  const clockIn = useCallback(() => {
    setIsWaitingOnClockState(isClockedIn ? 'clockOut' : 'clockIn')
    updateTimeClockStatus.mutate({})
    refetch()
  }, [updateTimeClockStatus, refetch, setIsWaitingOnClockState, isClockedIn])

  const fetching = !isNullish(waitingOnClockState) || res.fetching

  return {
    currentAssignment,
    clockedInTime,
    localElapsedDurationMin,
    refetch,
    isClockedIn,
    fetching,
    clockIn,
  }
}
