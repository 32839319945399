import { NoteLinkData, noOp } from '@breezy/shared'
import { faComment } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Drawer } from 'antd'
import cn from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { gray7 } from '../../../themes/theme'
import { useMessage } from '../../../utils/antd-utils'
import { LoadingSpinner } from '../../LoadingSpinner'
import { NoteForm } from '../../Notes/NoteForm'

export type AddNoteDrawerProps = {
  noteLinkData?: NoteLinkData
  onCancel?: () => void
  onMutate?: () => void
}

const AddNoteDrawer = React.memo<AddNoteDrawerProps>(
  ({ noteLinkData, onCancel = noOp, onMutate = noOp }) => {
    const message = useMessage()
    const [isUploading, setIsUploading] = useState(false)
    useEffect(() => setIsUploading(false), [noteLinkData])

    const itemType =
      noteLinkData?.primaryNoteType === 'PHOTO' ? 'Description' : 'Note'

    const onSuccess = useCallback(() => {
      message.success(`Added ${itemType} Successfully`)
      onMutate()
    }, [itemType, onMutate, message])
    const onError = useCallback(() => {
      message.error('Failed to add note')
    }, [message])

    return (
      <Drawer
        zIndex={1004}
        title="Add Note"
        placement={'bottom'}
        closable={true}
        onClose={onCancel}
        styles={{ header: { display: 'none' } }}
        height={280}
        open={!!noteLinkData}
      >
        <Col className="px-2">
          <div className="row center-children-v mb-2">
            <FontAwesomeIcon
              icon={faComment}
              color={gray7}
              size="lg"
              style={{ marginBottom: 2 }}
            />
            <h3 className="m0 ml1" style={{ color: gray7 }}>
              Add {itemType}
            </h3>
          </div>

          {isUploading && (
            <div style={{ height: 180 }}>
              <LoadingSpinner />
            </div>
          )}
          <Col className={cn({ hidden: isUploading })}>
            {noteLinkData && (
              <NoteForm
                linkData={noteLinkData}
                onSuccess={onSuccess}
                onError={onError}
                onLoadingChange={setIsUploading}
                addText={`Add ${itemType}`}
              />
            )}
            <Button className="float-right mt-3" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
        </Col>
      </Drawer>
    )
  },
)

export default AddNoteDrawer
