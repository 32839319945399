import classNames from 'classnames'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntersectionObserverForElement } from '../../hooks/useIntersectionObserver'
import useIsMobile from '../../hooks/useIsMobile'

export type OnsiteEmbeddedContextType = {
  embedded?: boolean
  defaultOnDeleteTo?: string
}

export const OnsiteEmbeddedContext =
  React.createContext<OnsiteEmbeddedContextType>({})

export const useGoBack = () => {
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(-1)
  }, [navigate])
}

export const ONSITE_CONTENT_MAX_WIDTH = 834

export const useOnsitePageDimensions = () => {
  const isMobile = useIsMobile()
  const margin = isMobile ? 0 : 40

  return useMemo(
    () =>
      ({
        margin,
        maxWidth: ONSITE_CONTENT_MAX_WIDTH,
        innerWidth: ONSITE_CONTENT_MAX_WIDTH - margin * 2,
        style: {
          maxWidth: `${ONSITE_CONTENT_MAX_WIDTH}px`,
          padding: `0 ${margin}px`,
        },
      } as const),
    [margin],
  )
}

export const useCurrentOnsitePageDimensions = () => {
  const pageDimensions = useOnsitePageDimensions()

  const [viewWidth, setViewWidth] = useState(0)

  const contentWidth = useMemo(
    () =>
      Math.min(
        pageDimensions.innerWidth,
        viewWidth - pageDimensions.margin * 2,
      ),
    [pageDimensions.innerWidth, pageDimensions.margin, viewWidth],
  )

  const pageContentMargin = useMemo(
    () => Math.max(0, viewWidth - contentWidth) / 2,
    [contentWidth, viewWidth],
  )

  useEffect(() => {
    const setWidth = () => setViewWidth(window.innerWidth)
    window.addEventListener('resize', setWidth)
    setWidth()
    return () => window.removeEventListener('resize', setWidth)
  }, [])

  return {
    viewWidth,
    contentWidth,
    pageContentMargin,
  }
}

type UseHidingHeaderProps = {
  bodyRef: React.RefObject<HTMLDivElement>
  headerElement: HTMLDivElement | null
  upperHeader: React.ReactNode
  // We will show the hiding header when it's out of the body, but it can still have this number of pixels showing.
  bottomMargin?: number
}

export const useHidingHeader = ({
  bodyRef,
  headerElement,
  upperHeader,
  bottomMargin,
}: UseHidingHeaderProps) => {
  const [isHeaderOutOfView, setIsHeaderOutOfView] = useState(false)

  const onIntersection = useCallback((entry: IntersectionObserverEntry) => {
    // Addresses an edge case where, before it loads, it considers it out of bounds, so it starts as visible then fades
    // out on load.
    if (entry.boundingClientRect.height === 0) {
      return
    }
    setIsHeaderOutOfView(!entry.isIntersecting)
  }, [])

  useIntersectionObserverForElement(headerElement, onIntersection, {
    rootMargin: bottomMargin ? `-${bottomMargin}px 0px 0px 0px` : undefined,
    root: bodyRef.current,
  })

  // When the upper header is visible (we're scrolled such that the main header is hidden), we get undesirable behavior
  // when the header changes (such as when you navigate to a sub-screen). Let's say the next screen is short enough that
  // it doesn't scroll. In that case the header will change, but then fade out because `isHeaderOutOfView` becomes
  // false. So we need to straight up not render it at all if it changes. That's where this comes in. If it's different
  // from the last header, we don't render it at all. But in order to be able to do this, it needs to be in a "hidden"
  // state (or else they'll never see it) so we need to scroll to the top. This isn't event bad UX (if you're
  // navigating, you wouldn't expect to land in the middle of the next page anyway)
  const prevUpperHeader = useRef(upperHeader ?? '')
  useLayoutEffect(() => {
    prevUpperHeader.current = upperHeader ?? ''
    bodyRef.current?.scrollTo(0, 0)
  }, [bodyRef, upperHeader])

  return {
    isHeaderOutOfView,
    hidingUpperHeader:
      upperHeader === prevUpperHeader.current ? (
        <div
          className={classNames(
            'mx-4 flex-1 truncate whitespace-nowrap text-center text-xl font-semibold transition-opacity',
            isHeaderOutOfView ? 'opacity-100' : 'opacity-0',
          )}
        >
          {upperHeader}
        </div>
      ) : undefined,
  }
}
