import { gql } from '../../generated'

export const MAINTENANCE_PLANS_WIDGET_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription MaintenancePlansWidgetData(
    $where: MaintenancePlansQueryableBoolExp!
  ) {
    maintenancePlansQueryable(where: $where) {
      ...MaintenancePlanCollapsibleData
      activatedAt
      billingAnchor
      terminatesAt
      paymentAmountUsc
      paymentInterval
    }
  }
`)
