import { OfficeRoutes, PermissionV2, SharedRoutes } from '@breezy/shared'
import {
  faHouseUser,
  faScrewdriverWrench,
  faWrench,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ActionsModalAction,
  ActionsModalActionProps,
  ActionsModalContent,
} from '../../../../adam-components/OnsiteModal/ActionsModalContent'
import { OnsiteBasicModal } from '../../../../adam-components/OnsiteModal/OnsiteModal'
import { useIsAuthorized } from '../../../../hooks/permission/useIsAuthorized'
import { useCompanyMaintenancePlansEnabled } from '../../../../hooks/useCompanyMaintenancePlansEnabled'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { useModalState, useStrictContext } from '../../../../utils/react-utils'
import {
  VISIT_DETAILS_ACTIONS_DISABLE_GLOBAL_ACTIONS_MAP,
  VisitDetailsActions,
  VisitDetailsActionsProps,
} from './VisitDetailsActions'

export type ActionMenuItem = Omit<
  ActionsModalActionProps,
  'hasNext' | 'children' | 'icon'
> & {
  label: string
  icon: IconDefinition | NonNullable<React.ReactNode>
}

export type DisabledGlobalActionsMap = Partial<{
  createJob: boolean
  createAccount: boolean
  createMaintenancePlan: boolean
}>

type ActionMenuContextualData =
  | {
      type: 'Dummy'
      props?: never
    }
  | {
      type: 'Visit'
      props: VisitDetailsActionsProps
    } // | { type: 'ADD_NEW_ONES_LIKE_THIS', props: NewThingProps }

type ActionMenuContextType = {
  contextualActionData: ActionMenuContextualData | undefined
  setContextualActionData: (contextualData: ActionMenuContextualData) => void
  unsetContextualActionData: (contextualData: ActionMenuContextualData) => void
  openActionsMenu: () => void
  closeActionsMenu: () => void
}

export const ActionMenuContext = React.createContext<
  ActionMenuContextType | undefined
>(undefined)

export const useGlobalActionMenu = (
  contextualData: ActionMenuContextualData,
) => {
  const { setContextualActionData, unsetContextualActionData } =
    useStrictContext(ActionMenuContext)

  useEffect(() => {
    const obj = {
      type: contextualData.type,
      props: contextualData.props,
    } as ActionMenuContextualData
    setContextualActionData(obj)
    return () => {
      unsetContextualActionData(obj)
    }
  }, [
    contextualData.props,
    contextualData.type,
    setContextualActionData,
    unsetContextualActionData,
  ])
}

type GlobalActionMenuItemsProps = {
  title: string
  actions: ActionMenuItem[]
}

export const GlobalActionMenuItems = React.memo<GlobalActionMenuItemsProps>(
  ({ title, actions }) => {
    const { closeActionsMenu } = useStrictContext(ActionMenuContext)
    return (
      <div>
        <div className="mb-4 text-xl font-semibold">{title}</div>
        <ActionsModalContent>
          {actions.map(({ icon, onClick, label }) => (
            <ActionsModalAction
              key={label}
              icon={
                typeof icon === 'object' && 'iconName' in icon ? (
                  <FontAwesomeIcon icon={icon} />
                ) : (
                  icon
                )
              }
              onClick={() => {
                closeActionsMenu()
                onClick()
              }}
            >
              {label}
            </ActionsModalAction>
          ))}
        </ActionsModalContent>
      </div>
    )
  },
)

type GlobalActionsProps = {
  disabledGlobalActions?: DisabledGlobalActionsMap
}

const GlobalActions = React.memo<GlobalActionsProps>(
  ({ disabledGlobalActions }) => {
    const isAuthorized = useIsAuthorized()
    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
    const navigate = useNavigate()

    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags('globalMpw', 'tech-global-create')

    const actions = useMemo(() => {
      const actions: {
        icon: IconDefinition
        onClick: () => void
        label: string
      }[] = []
      if (
        isAuthorized(PermissionV2.FIELD_JOB_INFORMATION_CREATE) &&
        !disabledGlobalActions?.createJob
      ) {
        actions.push({
          icon: faWrench,
          onClick: () => navigate(OfficeRoutes.JOB_CREATION.path),
          label: 'Create Job',
        })
      }
      if (
        isAuthorized(PermissionV2.FIELD_ACCOUNTS_CONTACTS_MANAGE_FULL) &&
        !disabledGlobalActions?.createAccount
      ) {
        actions.push({
          icon: faHouseUser,
          onClick: () => navigate(SharedRoutes.ACCOUNT_CREATION.path),
          label: 'Create Account',
        })
      }
      if (
        isCompanyMaintenancePlansEnabled &&
        isAuthorized(PermissionV2.FIELD_MAINTENANCE_PLANS_MANAGE_FULL) &&
        !disabledGlobalActions?.createMaintenancePlan
      ) {
        actions.push({
          icon: faScrewdriverWrench,
          onClick: openMaintenancePlanWizard,
          label: 'Create Maintenance Plan',
        })
      }
      return actions
    }, [
      disabledGlobalActions?.createAccount,
      disabledGlobalActions?.createJob,
      disabledGlobalActions?.createMaintenancePlan,
      isAuthorized,
      isCompanyMaintenancePlansEnabled,
      navigate,
      openMaintenancePlanWizard,
    ])

    if (!actions.length) {
      return null
    }
    return (
      <>
        <GlobalActionMenuItems title="Global" actions={actions} />
        {maintenancePlanWizardOpen && (
          <MaintenancePlanWizard
            onRamp="tech-global-create"
            onClose={closeMaintenancePlanWizard}
          />
        )}
      </>
    )
  },
)

type ActionMenuProps = {
  actionsMenuOpen: boolean
  closeActionsMenu: () => void
  contextualActionData: ActionMenuContextualData | undefined
}

const ActionMenu = React.memo<ActionMenuProps>(
  ({ actionsMenuOpen, closeActionsMenu, contextualActionData }) => {
    const contextualContent = useMemo<React.ReactNode | undefined>(() => {
      if (contextualActionData?.type === 'Visit') {
        return <VisitDetailsActions {...contextualActionData.props} />
      }
    }, [contextualActionData])

    const disabledGlobalActions = useMemo(() => {
      if (contextualActionData?.type === 'Visit') {
        return VISIT_DETAILS_ACTIONS_DISABLE_GLOBAL_ACTIONS_MAP
      }
    }, [contextualActionData?.type])

    return (
      <>
        <OnsiteBasicModal
          open={actionsMenuOpen}
          onClose={closeActionsMenu}
          header="Actions"
          headerBordered
          testId="global-action-menu"
        >
          <div className="space-y-5">
            <GlobalActions disabledGlobalActions={disabledGlobalActions} />
            {contextualContent}
          </div>
        </OnsiteBasicModal>
      </>
    )
  },
)

export const ActionMenuProvider = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const [contextualActionData, setContextualActionData] = useState<
      ActionMenuContextualData | undefined
    >(undefined)

    const unsetContextualActionData = useCallback(
      (prevContextualActionData: ActionMenuContextualData) => {
        setTimeout(() => {
          setContextualActionData(current => {
            if (
              current?.type === prevContextualActionData.type &&
              current?.props === prevContextualActionData.props
            ) {
              return undefined
            }
            return current
          })
        }, 100)
      },
      [],
    )

    const deepEqualData = useMemo<ActionMenuContextualData | undefined>(
      () =>
        contextualActionData?.type
          ? ({
              props: contextualActionData.props,
              type: contextualActionData.type,
            } as ActionMenuContextualData)
          : undefined,
      [contextualActionData?.props, contextualActionData?.type],
    )

    const [actionsMenuOpen, openActionsMenu, closeActionsMenu] = useModalState()

    return (
      <ActionMenuContext.Provider
        value={{
          setContextualActionData,
          unsetContextualActionData,
          openActionsMenu,
          closeActionsMenu,
          contextualActionData: deepEqualData,
        }}
      >
        {children}
        <ActionMenu
          actionsMenuOpen={actionsMenuOpen}
          closeActionsMenu={closeActionsMenu}
          contextualActionData={contextualActionData}
        />
      </ActionMenuContext.Provider>
    )
  },
)
