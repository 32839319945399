import {
  AppointmentType,
  JobClass,
  richJobTypeDescriptor,
} from '@breezy/shared'
import React, { useLayoutEffect, useRef } from 'react'
import { JobClassAccentBadge } from '../../../JobClassAccentBadge'

type AppointmentDetailHeaderProps = {
  setHeaderElement: (headerElement: HTMLDivElement | null) => void
  appointmentType: AppointmentType
  jobClass: JobClass
  jobTypeName: string
  displayId: number
}

export const AppointmentDetailHeader = React.memo<AppointmentDetailHeaderProps>(
  ({ appointmentType, jobClass, jobTypeName, displayId, setHeaderElement }) => {
    const contentRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
      setHeaderElement(contentRef.current)
    }, [setHeaderElement])
    return (
      <div ref={contentRef}>
        <div className="pt-4 text-3xl font-semibold">{appointmentType}</div>
        <div className="mt-1.5 flex flex-row items-center font-semibold text-bz-text-tertiary">
          <JobClassAccentBadge jobClass={jobClass} className="mr-1.5" />
          <div>
            {richJobTypeDescriptor(jobTypeName)} Job #{displayId}
          </div>
        </div>
      </div>
    )
  },
)
