import { gql } from '../../generated'
import { EstimatesWidgetEstimateFragment } from '../../generated/user/graphql'

gql(/* GraphQL */ `
  fragment EstimatesWidgetEstimate on Estimates {
    displayId
    createdByUser {
      fullName
    }
    createdAt
    status
    options: estimateOptions(orderBy: { seq: ASC }) {
      seq
      displayName
      totalUsc
      cartItems {
        cartItem {
          name
          unitPriceUsc
          quantity
          isDiscountable
        }
      }
      discounts: estimateOptionDiscounts {
        discountAmountUsc
        discountRate
        type: discountType
      }
      isRecommended
      isSelected
    }
    estimateGuid
    job {
      account {
        accountGuid
        accountDisplayName
      }
      pointOfContact {
        fullName
        primaryPhoneNumber {
          phoneNumber
        }
      }
      location {
        locationGuid
        address {
          line1
          zipCode
        }
      }
    }
  }
`)

export type EstimatesWidgetEstimate = EstimatesWidgetEstimateFragment

export const ESTIMATES_WIDGET_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription EstimatesWidgetData($where: EstimatesBoolExp!) {
    estimates(where: $where) {
      ...EstimatesWidgetEstimate
    }
  }
`)
