import { BzDateFns, LocalDateString } from '@breezy/shared'
import { ConfigProvider, Skeleton, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { useWeatherForecast } from '../apis/weather'
import {
  useExpectedCompany,
  useExpectedCompanyTimeZoneId,
} from '../providers/PrincipalUser'

type WeatherWidgetProps = {
  date?: LocalDateString
  showCondition?: boolean
}

export const WeatherWidget = React.memo<WeatherWidgetProps>(
  ({ date, showCondition = true }) => {
    const { zipCode } = useExpectedCompany()
    const tzId = useExpectedCompanyTimeZoneId()

    const selectedLocalDate = useMemo(
      () => date ?? BzDateFns.getTodayLocalDate(tzId),
      [date, tzId],
    )

    const forecastDays = useWeatherForecast(selectedLocalDate, zipCode, 1)

    const forecastLink = `https://www.weatherapi.com/weather/q/${zipCode}`
    const day = forecastDays[selectedLocalDate]

    return day && zipCode ? (
      <div className="center-children-vh flex-1 text-base">
        <div className="my-auto">
          <a
            href={forecastLink}
            target="_blank"
            rel="noreferrer"
            className="no-underline"
          >
            <div className="flex w-full flex-row items-center">
              <div className="h-8 max-h-8 w-8 max-w-8">
                <Tooltip title={day.weatherCondition}>
                  <img src={day.weatherIconSrc} alt="weather" />
                </Tooltip>
              </div>
              {showCondition && (
                <div className="ml-1.5 mr-2.5 text-bz-text">
                  {day.weatherCondition}
                </div>
              )}
              <div className="mr-1 text-bz-text-secondary">
                {day.highTempF.toFixed(0)}°
              </div>
              <div className="text-bz-text-tertiary">
                {day.lowTempF.toFixed(0)}°
              </div>
            </div>
          </a>
        </div>
      </div>
    ) : (
      <div className="flex h-8 w-[150px] flex-row items-center py-2 leading-none">
        <ConfigProvider
          theme={{
            components: {
              Skeleton: {
                controlHeightSM: 16,
              },
            },
          }}
        >
          <Skeleton.Input active size="small" block />
        </ConfigProvider>
      </div>
    )
  },
)
