import { CalculatePaths, Guid } from '@breezy/shared'
import { faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { OnsitePageSimpleSectionItem } from '../../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'

type HasOtherPendingAssignmentWidgetProps = {
  appointmentGuid: Guid
  pointOfContactFullName: string
  addressLine1: string
}

export const HasOtherPendingAssignmentWidget =
  React.memo<HasOtherPendingAssignmentWidgetProps>(
    ({ appointmentGuid, pointOfContactFullName, addressLine1 }) => {
      const navigate = useNavigate()
      return (
        <OnsitePageSimpleSectionItem
          theme="warning"
          icon={<FontAwesomeIcon icon={faOctagonExclamation} />}
          smallIconMode
          footer={
            <Button
              type="primary"
              size="large"
              onClick={() =>
                navigate(CalculatePaths.appointmentDetails({ appointmentGuid }))
              }
            >
              Go to Current Visit
            </Button>
          }
        >
          <div>
            You cannot begin this visit{' '}
            <span className="font-normal">
              until your current visit ({pointOfContactFullName} -{' '}
              {addressLine1}) is completed or canceled.
            </span>
          </div>
        </OnsitePageSimpleSectionItem>
      )
    },
  )
