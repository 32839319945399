import { toAssignedAppointmentViewModel } from '@breezy/backend/src/application-types'
import {
  AssignedApptViewModel,
  AssignmentWithTechnicianNameDTO,
  CalculatePaths,
  DUMMY_PREQUAL_RECORD_GUID,
  Guid,
  OfficeRoutes,
  R,
  appointmentChecklistInstanceChecklistSchema,
  bzExpect,
  guidSchema,
  isChecklistCompleted,
  noOp,
} from '@breezy/shared'
import { Tabs } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useSubscription } from 'urql'
import { OnsitePageCollapsibleSection } from '../../../../adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { OnsitePageContainer } from '../../../../adam-components/OnsitePage/OnsitePageContainer'
import { OnsitePageSection } from '../../../../adam-components/OnsitePage/OnsitePageSection'
import {
  useCurrentOnsitePageDimensions,
  useHidingHeader,
} from '../../../../adam-components/OnsitePage/onsitePageUtils'
import {
  AppointmentDetailByGuidSubscription,
  FetchAppointmentViewModelLiveQuerySubscription,
} from '../../../../generated/user/graphql'
import { useFetchAppointmentBlocksAndAppointmentsLiveQuery } from '../../../../hooks/fetch/useFetchAppointmentBlocksAndAppointmentsLiveQuery'
import { useCanManageJob } from '../../../../hooks/permission/useCanManageJob'
import { useTechnicianScheduleAppointmentPermissions } from '../../../../hooks/permission/useTechnicianScheduleAppointmentPermissions'
import { trpc } from '../../../../hooks/trpc'
import { useCompanyMaintenancePlansEnabled } from '../../../../hooks/useCompanyMaintenancePlansEnabled'
import useIsMobile from '../../../../hooks/useIsMobile'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { useWisetackEnabled } from '../../../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedPrincipal,
  useExpectedUserGuid,
} from '../../../../providers/PrincipalUser'
import { useQueryParamStateWithOptions } from '../../../../utils/react-utils'
import { WisetackFinancingCollapsibleContent } from '../../../collapsibles/WisetackFinancingCollapsible/WisetackFinancingCollapsible'
import { ContactsWidget } from '../../../ContactsWidget/ContactsWidget'
import { useContactsWidgetData } from '../../../ContactsWidget/useContactsWidgetData'
import { EquipmentWidget } from '../../../EquipmentWidget/EquipmentWidget'
import { useEquipmentWidgetData } from '../../../EquipmentWidget/useEquipmentWidgetData'
import { EstimatesWidget } from '../../../EstimatesWidget/EstimatesWidget'
import { EstimatesWidgetEstimate } from '../../../EstimatesWidget/EstimatesWidget.gql'
import { useEstimatesWidgetData } from '../../../EstimatesWidget/useEstimatesWidgetData'
import { useFinancingWizard } from '../../../Financing/hooks/useFinancingWizard'
import { InvoicesWidget } from '../../../InvoicesWidget/InvoicesWidget'
import { InvoicesWidgetInvoice } from '../../../InvoicesWidget/InvoicesWidget.gql'
import { useInvoicesWidgetData } from '../../../InvoicesWidget/useInvoicesWidgetData'
import JobAttachments from '../../../JobAttachments/JobAttachments'
import { JobClassAccentBadge } from '../../../JobClassAccentBadge'
import { JobsWidget } from '../../../JobsWidget/JobsWidget'
import { JobsWidgetJob } from '../../../JobsWidget/JobsWidget.gql'
import {
  FullScreenLoadingSpinner,
  LoadingSpinner,
} from '../../../LoadingSpinner'
import { MaintenancePlansWidget } from '../../../MaintenancePlansWidget/MaintenancePlansWidget'
import { useMaintenancePlansWidgetData } from '../../../MaintenancePlansWidget/useMaintenancePlansWidgetData'
import LinkedNotesList from '../../../Notes/LinkedNotesList/LinkedNotesList'
import { UrqlMultiSubscriptionLoader } from '../../../UrqlSubscriptionLoader/UrqlSubscriptionLoader'
import { useGlobalActionMenu } from '../../BottomNavBar/ActionMenu/ActionMenuProvider'
import { VisitDetailsActionsProps } from '../../BottomNavBar/ActionMenu/VisitDetailsActions'
import { useTechExpDrawers } from '../../TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import { AccountDetails } from './AccountDetails'
import {
  APPOINTMENT_DETAIL_ACCOUNT_SUBSCRIPTION,
  APPOINTMENT_DETAIL_BY_GUID_SUBSCRIPTION,
  APPOINTMENT_DETAIL_BY_REF_NUM_QUERY,
  AppointmentDetails,
  AppointmentDetailsAssignment,
  VISIT_ACCOUNT_TAB_INVOICES_DATA_SUBSCRIPTION,
  VISIT_ACCOUNT_TAB_JOBS_DATA_SUBSCRIPTION,
} from './AppointmentDetail.gql'
import './AppointmentDetail.less'
import { AppointmentDetailHeader } from './AppointmentDetailHeader'
import {
  AppointmentDetailsChecklistInstance,
  getAssignment,
} from './AppointmentDetailUtils'
import { AssignmentStatusSteps } from './AssignmentStatusSteps/AssignmentStatusSteps'
import { ChecklistsButton } from './ChecklistsButton'
import { HasOtherPendingAssignmentWidget } from './HasOtherPendingAssignmentWidget'
import { HistoricalAppointment } from './HistoricalAppointmentsCard/HistoricalAppointmentsCard'
import LocationPhotos from './LocationPhotos/LocationPhotos'
import { VisitDetails } from './VisitDetails'

const TABS_HEIGHT = 50

const TABS = ['Visit', 'Job', 'Account'] as const

type Tab = (typeof TABS)[number]

type AppointmentDetailProps = {
  appointmentDetails: AppointmentDetails
  apptLegacy: AssignedApptViewModel
  version: number
  onMutate?: () => void
  setHeaderElement: (headerElement: HTMLDivElement | null) => void
}

export const AppointmentDetail = React.memo<AppointmentDetailProps>(
  ({
    appointmentDetails,
    apptLegacy,
    version,
    onMutate = noOp,
    setHeaderElement,
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const navigate = useNavigate()
    const wisetackEnabled = useWisetackEnabled()
    const userGuid = useExpectedUserGuid()
    const drawers = useTechExpDrawers()
    const isMobile = useIsMobile()

    const {
      job,
      appointmentType,
      appointmentGuid: jobAppointmentGuid,
      checklists,
      cancellationStatus,
    } = appointmentDetails
    const { jobGuid, jobType, pointOfContact, location, account, assignments } =
      job
    const { accountGuid, accountDisplayName } = account
    const { locationGuid, address, maintenancePlans } = location

    const createChecklistInstancesMutation =
      trpc.appointmentChecklist[
        'appointment-checklists:generate-for-appointment'
      ].useMutation()

    useEffect(() => {
      createChecklistInstancesMutation.mutate({
        jobTypeGuid: appointmentDetails.job.jobType.jobTypeGuid,
        appointmentType,
        jobAppointmentGuid,
      })
      // We only want to do this on mount. This will create any instances if they don't exist
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checklistInstances = useMemo<AppointmentDetailsChecklistInstance[]>(
      () =>
        checklists.map(({ instanceGuid, checklistGuid, checklist }) => {
          const instance =
            appointmentChecklistInstanceChecklistSchema.parse(checklist)
          return {
            ...instance,
            isCompleted: isChecklistCompleted(instance.items),
            instanceGuid,
            checklistGuid,
          }
        }),
      [checklists],
    )

    const numIncompleteChecklists = useMemo(
      () => R.count(instance => !instance.isCompleted, checklistInstances),
      [checklistInstances],
    )

    const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()

    const { canManageTeamSchedule } =
      useTechnicianScheduleAppointmentPermissions()
    const { canManage: canManageJob } = useCanManageJob(jobGuid)

    const [accountQuery] = useSubscription({
      query: APPOINTMENT_DETAIL_ACCOUNT_SUBSCRIPTION,
      variables: {
        accountGuid,
      },
    })

    const accountLegacy = accountQuery.data?.accountsByPk

    const loanRecordsForJobQuery = trpc.financing[
      'loan-records:for-job'
    ].useQuery({ jobGuid: apptLegacy.jobGuid })

    // If there isn't a valid account guid, we pass in a dummy guid to satisfy the trpc runtime type validation
    // which returns an empty array for the prequal records
    const prequalRecordsForAccountQuery = trpc.financing[
      'prequal-records:for-account'
    ].useQuery({
      accountGuid: apptLegacy.accountGuid ?? DUMMY_PREQUAL_RECORD_GUID,
    })

    const refetchWisetack = useCallback(() => {
      loanRecordsForJobQuery.refetch()
      prequalRecordsForAccountQuery.refetch()
    }, [loanRecordsForJobQuery, prequalRecordsForAccountQuery])

    const fetchHistoricalAppointments = trpc.appointments[
      'historical-appointments-for-location:by-job-appointment-guid'
    ].useQuery({ jobAppointmentGuid: apptLegacy.appointment.guid })

    const historicalAppointments = useMemo(
      () => fetchHistoricalAppointments.data ?? [],
      [fetchHistoricalAppointments.data],
    )

    const fetchChecklistInfo = trpc.appointmentChecklist[
      'appointment-checklists:get-for-appointment'
    ].useQuery({
      jobAppointmentGuid: apptLegacy.appointment.guid,
      jobTypeGuid: apptLegacy.jobType.jobTypeGuid,
      appointmentType: apptLegacy.appointment.appointmentType,
    })

    const [myAssignment, otherAssignments] = useMemo(() => {
      let myAssignment: AppointmentDetailsAssignment | undefined = undefined
      const otherAssignments: AppointmentDetailsAssignment[] = []

      for (const assignment of assignments) {
        if (assignment.appointmentGuid !== jobAppointmentGuid) {
          continue
        }
        if (assignment.technician.userGuid === userGuid) {
          myAssignment = assignment
        } else {
          otherAssignments.push(assignment)
        }
      }
      return [myAssignment, otherAssignments] as const
    }, [assignments, jobAppointmentGuid, userGuid])

    const canManageAppointment = useMemo(() => {
      return !!myAssignment || canManageTeamSchedule
    }, [myAssignment, canManageTeamSchedule])

    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags('mpw', 'appt-detail')

    const onMaintenancePlanWizardClose = useCallback(() => {
      closeMaintenancePlanWizard()
      onMutate()
    }, [closeMaintenancePlanWizard, onMutate])

    const onInvoiceAdd = useCallback(
      () =>
        navigate(
          CalculatePaths.newInvoiceV2({
            accountGuid,
            jobGuid,
            fields: {
              jobAppointmentGuid,
            },
          }),
        ),
      [accountGuid, jobAppointmentGuid, jobGuid, navigate],
    )

    const onEstimateAdd = useCallback(
      () =>
        navigate(
          CalculatePaths.newEstimate({
            jobGuid,
            jobAppointmentGuid,
          }),
        ),
      [jobAppointmentGuid, jobGuid, navigate],
    )

    const equipmentDrawerLocation = useMemo(
      () => ({ ...location, companyGuid }),
      [companyGuid, location],
    )

    const onEquipmentAdd = useCallback(() => {
      drawers.beginUpsertInstalledEquipment(
        equipmentDrawerLocation,
        undefined,
        onMutate,
      )
    }, [drawers, equipmentDrawerLocation, onMutate])

    const onJobAdd = useCallback(
      () => navigate(OfficeRoutes.JOB_CREATION.path),
      [navigate],
    )

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      jobGuid,
      onCancel: refetchWisetack,
    })

    const otherActiveAssignment = useMemo(
      () => myAssignment?.technician.assignments[0],
      [myAssignment?.technician.assignments],
    )

    const [activeTab, setActiveTab] = useQueryParamStateWithOptions(
      'tab',
      TABS[0],
      TABS,
      {
        replace: true,
      },
    )

    const invoicesWidgetData = useInvoicesWidgetData({ jobGuid })
    const estimatesWidgetData = useEstimatesWidgetData({ jobGuid })
    const equipmentWidgetData = useEquipmentWidgetData({ locationGuid })
    const contactsWidgetData = useContactsWidgetData({ accountGuid })
    const maintenancePlansWidgetData = useMaintenancePlansWidgetData({
      locationGuid,
    })

    const [{ fetching: accountTabJobsFetching, data: accountTabJobsData }] =
      useSubscription({
        query: VISIT_ACCOUNT_TAB_JOBS_DATA_SUBSCRIPTION,
        variables: {
          locationGuid,
        },
      })

    const [
      { fetching: accountTabInvoicesFetching, data: accountTabInvoicesData },
    ] = useSubscription({
      query: VISIT_ACCOUNT_TAB_INVOICES_DATA_SUBSCRIPTION,
      variables: {
        locationGuid,
      },
    })

    const accountTabFetching =
      accountTabJobsFetching || accountTabInvoicesFetching

    const { accountTabJobs, accountTabInvoices, accountTabEstimates } =
      useMemo<{
        accountTabJobs: JobsWidgetJob[]
        accountTabInvoices: InvoicesWidgetInvoice[]
        accountTabEstimates: EstimatesWidgetEstimate[]
      }>(() => {
        if (!accountTabJobsData || !accountTabInvoicesData) {
          return {
            accountTabJobs: [],
            accountTabInvoices: [],
            accountTabEstimates: [],
          }
        }

        const accountTabInvoices: InvoicesWidgetInvoice[] = []

        const invoicesAndPaymentsToJobGuid: Record<
          string,
          {
            jobInvoices: JobsWidgetJob['jobInvoices']
            paymentLinks: JobsWidgetJob['paymentLinks']
          }
        > = {}

        for (const { invoice } of accountTabInvoicesData.locationInvoices) {
          accountTabInvoices.push(invoice)
          if (!invoice.jobLink) {
            continue
          }
          const { jobGuid } = invoice.jobLink
          const lists = invoicesAndPaymentsToJobGuid[jobGuid] ?? {
            jobInvoices: [],
            paymentLinks: [],
          }
          lists.jobInvoices.push({ invoice })
          for (const paymentRecord of invoice.paymentRecords) {
            lists.paymentLinks.push({ paymentRecord })
          }
          invoicesAndPaymentsToJobGuid[jobGuid] = lists
        }

        const accountTabJobs: JobsWidgetJob[] = []
        const accountTabEstimates: EstimatesWidgetEstimate[] = []

        for (const job of accountTabJobsData.jobs) {
          accountTabJobs.push({
            ...job,
            ...(invoicesAndPaymentsToJobGuid[job.jobGuid] ?? {
              jobInvoices: [],
              paymentLinks: [],
            }),
          })

          accountTabEstimates.push(
            ...job.estimates.map(estimate => ({
              ...estimate,
              job: {
                ...job,
                pointOfContact,
                account: {
                  accountGuid,
                  accountDisplayName,
                },
              },
            })),
          )
        }

        return {
          accountTabJobs,
          accountTabInvoices,
          accountTabEstimates,
        }
      }, [
        accountDisplayName,
        accountGuid,
        accountTabInvoicesData,
        accountTabJobsData,
        pointOfContact,
      ])

    const invoicesSection = useMemo(() => {
      const invoices = invoicesWidgetData.invoices
      return (
        <OnsitePageCollapsibleSection
          title="Invoices"
          count={invoices.length}
          onAdd={canManageJob ? onInvoiceAdd : undefined}
        >
          <InvoicesWidget invoices={invoices} readonly={!canManageJob} />
        </OnsitePageCollapsibleSection>
      )
    }, [canManageJob, invoicesWidgetData.invoices, onInvoiceAdd])
    const accountInvoicesSection = useMemo(() => {
      const invoices = accountTabInvoices

      return (
        <OnsitePageCollapsibleSection
          title="Invoices"
          count={invoices.length}
          onAdd={canManageJob ? onInvoiceAdd : undefined}
        >
          <InvoicesWidget
            fetching={accountTabFetching}
            invoices={invoices}
            readonly={!canManageJob}
          />
        </OnsitePageCollapsibleSection>
      )
    }, [accountTabFetching, accountTabInvoices, canManageJob, onInvoiceAdd])

    const estimatesSection = useMemo(() => {
      const estimates = estimatesWidgetData.estimates
      return (
        <OnsitePageCollapsibleSection
          title="Estimates"
          count={estimates.length}
          onAdd={canManageJob ? onEstimateAdd : undefined}
          testId="estimates-collapsible"
        >
          <EstimatesWidget
            includePresentButton
            estimates={estimates}
            readonly={!canManageJob}
          />
        </OnsitePageCollapsibleSection>
      )
    }, [canManageJob, estimatesWidgetData.estimates, onEstimateAdd])

    const accountEstimatesSection = useMemo(() => {
      const estimates = accountTabEstimates

      return (
        <OnsitePageCollapsibleSection
          title="Estimates"
          count={estimates.length}
          onAdd={canManageJob ? onEstimateAdd : undefined}
          testId="estimates-collapsible"
        >
          <EstimatesWidget
            includePresentButton
            fetching={accountTabFetching}
            estimates={estimates}
            readonly={!canManageJob}
          />
        </OnsitePageCollapsibleSection>
      )
    }, [accountTabEstimates, accountTabFetching, canManageJob, onEstimateAdd])

    const equipmentSection = useMemo(
      () => (
        <OnsitePageCollapsibleSection
          title="Equipment"
          count={equipmentWidgetData.equipment.length}
          onAdd={canManageJob ? onEquipmentAdd : undefined}
        >
          <EquipmentWidget
            {...equipmentWidgetData}
            location={equipmentDrawerLocation}
            readonly={!canManageJob}
          />
        </OnsitePageCollapsibleSection>
      ),
      [
        canManageJob,
        equipmentDrawerLocation,
        equipmentWidgetData,
        onEquipmentAdd,
      ],
    )

    const wisetackSection = useMemo(
      () =>
        wisetackEnabled && (
          <OnsitePageCollapsibleSection
            title="Wisetack Financing"
            count={
              (loanRecordsForJobQuery.data?.length ?? 0) +
              (prequalRecordsForAccountQuery.data?.length ?? 0)
            }
            onAdd={canManageJob ? showFinancingWizard : undefined}
          >
            <WisetackFinancingCollapsibleContent
              loanRecords={loanRecordsForJobQuery.data ?? []}
              prequalRecords={prequalRecordsForAccountQuery.data ?? []}
            />
          </OnsitePageCollapsibleSection>
        ),
      [
        canManageJob,
        loanRecordsForJobQuery.data,
        prequalRecordsForAccountQuery.data,
        showFinancingWizard,
        wisetackEnabled,
      ],
    )

    const notesSection = useMemo(
      () => (
        <OnsitePageCollapsibleSection title="Internal Notes">
          <LinkedNotesList
            noteLinks={{ jobGuid }}
            linkData={{
              primaryNoteType: 'JOB_APPOINTMENT',
              jobGuid: apptLegacy.jobGuid,
              jobAppointmentGuid: apptLegacy.appointment.guid,
            }}
            // Explanation - Hide Photo Notes
            displayCondition={n => !n.photoGuid}
            version={version}
            editable={canManageJob}
          />
        </OnsitePageCollapsibleSection>
      ),
      [
        apptLegacy.appointment.guid,
        apptLegacy.jobGuid,
        canManageJob,
        jobGuid,
        version,
      ],
    )
    const accountNotesSection = useMemo(
      () => (
        <OnsitePageCollapsibleSection title="Internal Notes" defaultCollapsed>
          <LinkedNotesList
            noteLinks={{ accountGuid }}
            linkData={{
              primaryNoteType: 'ACCOUNT',
              accountGuid,
            }}
            // Explanation - Hide Photo Notes
            displayCondition={n => !n.photoGuid}
            version={version}
            editable={canManageJob}
          />
        </OnsitePageCollapsibleSection>
      ),
      [accountGuid, canManageJob, version],
    )

    const photosSection = useMemo(
      () => (
        <OnsitePageCollapsibleSection title="Photos">
          <LocationPhotos
            onMutate={onMutate}
            locationGuid={apptLegacy.locationGuid}
            apptVersion={version}
          />
        </OnsitePageCollapsibleSection>
      ),
      [apptLegacy.locationGuid, onMutate, version],
    )

    const attachmentsSection = useMemo(
      () => (
        <OnsitePageCollapsibleSection title="Files">
          <JobAttachments
            jobGuid={apptLegacy.jobGuid}
            disableUpload={!canManageJob}
            editable={canManageJob}
          />
        </OnsitePageCollapsibleSection>
      ),
      [apptLegacy.jobGuid, canManageJob],
    )

    const maintenancePlansSection = useMemo(() => {
      if (!isCompanyMaintenancePlansEnabled) {
        return null
      }
      return (
        <OnsitePageCollapsibleSection
          title="Maintenance Plans"
          count={maintenancePlansWidgetData.maintenancePlans.length}
          onAdd={
            canManageJob &&
            maintenancePlansWidgetData.maintenancePlans.length === 0
              ? () => {
                  openMaintenancePlanWizard()
                }
              : undefined
          }
        >
          <MaintenancePlansWidget
            {...maintenancePlansWidgetData}
            readonly={!canManageJob}
          />
        </OnsitePageCollapsibleSection>
      )
    }, [
      canManageJob,
      isCompanyMaintenancePlansEnabled,
      maintenancePlansWidgetData,
      openMaintenancePlanWizard,
    ])

    const visitTab = useMemo(
      () => (
        <>
          <OnsitePageCollapsibleSection title="Details">
            <VisitDetails
              appointmentDetails={appointmentDetails}
              maintenancePlansEnabled={isCompanyMaintenancePlansEnabled}
            />
          </OnsitePageCollapsibleSection>
          {canManageAppointment && checklistInstances.length > 0 && (
            <OnsitePageCollapsibleSection
              title="Action Items"
              count={numIncompleteChecklists}
            >
              <ChecklistsButton
                numChecklists={checklistInstances.length}
                instances={fetchChecklistInfo.data?.instances || []}
                refetch={fetchChecklistInfo.refetch}
                loading={fetchChecklistInfo.isLoading}
                jobTypeGuid={apptLegacy.jobType.jobTypeGuid}
                appointmentType={apptLegacy.appointment.appointmentType}
                jobAppointmentGuid={apptLegacy.appointment.guid}
              />
            </OnsitePageCollapsibleSection>
          )}
          {maintenancePlansSection}
          {equipmentSection}
          {invoicesSection}
          {estimatesSection}
          {wisetackSection}
          {notesSection}
          {photosSection}
          {attachmentsSection}
        </>
      ),
      [
        appointmentDetails,
        apptLegacy.appointment.appointmentType,
        apptLegacy.appointment.guid,
        apptLegacy.jobType.jobTypeGuid,
        attachmentsSection,
        canManageAppointment,
        checklistInstances.length,
        equipmentSection,
        estimatesSection,
        fetchChecklistInfo.data?.instances,
        fetchChecklistInfo.isLoading,
        fetchChecklistInfo.refetch,
        invoicesSection,
        isCompanyMaintenancePlansEnabled,
        maintenancePlansSection,
        notesSection,
        numIncompleteChecklists,
        photosSection,
        wisetackSection,
      ],
    )

    const jobTab = useMemo(
      () => (
        <>
          <OnsitePageCollapsibleSection title="Details">
            {/* TODO: "team" should be across visits */}
            <VisitDetails
              appointmentDetails={appointmentDetails}
              maintenancePlansEnabled={isCompanyMaintenancePlansEnabled}
              jobTab
            />
          </OnsitePageCollapsibleSection>
          <OnsitePageCollapsibleSection
            title="Past Appointments"
            count={historicalAppointments.length}
          >
            {historicalAppointments.length === 0 ? (
              'There are no historical appointments at this location'
            ) : (
              <div className="flex flex-col gap-4">
                {historicalAppointments.map((appt, apptIndex) => {
                  return (
                    <div className="" key={appt.appointmentReferenceNumber}>
                      <HistoricalAppointment
                        appt={appt}
                        defaultOpen={apptIndex < 3}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </OnsitePageCollapsibleSection>
          {equipmentSection}
          {invoicesSection}
          {estimatesSection}
          {wisetackSection}
          {notesSection}
          {photosSection}
          {attachmentsSection}
        </>
      ),
      [
        appointmentDetails,
        attachmentsSection,
        equipmentSection,
        estimatesSection,
        historicalAppointments,
        invoicesSection,
        isCompanyMaintenancePlansEnabled,
        notesSection,
        photosSection,
        wisetackSection,
      ],
    )

    const accountTab = useMemo(
      () => (
        <>
          <OnsitePageCollapsibleSection title="Details">
            <AccountDetails
              accountGuid={accountGuid}
              maintenancePlansEnabled={isCompanyMaintenancePlansEnabled}
              maintenancePlan={maintenancePlans[0]}
            />
          </OnsitePageCollapsibleSection>
          <OnsitePageCollapsibleSection
            defaultCollapsed
            title="Contacts"
            count={contactsWidgetData.contacts.length}
            // TODO: https://getbreezyapp.atlassian.net/browse/BZ-4413
            // onAdd={canManageJob ? onContactAdd : undefined}
          >
            <ContactsWidget {...contactsWidgetData} readonly={!canManageJob} />
          </OnsitePageCollapsibleSection>
          {/* TODO: payment methods */}
          {accountNotesSection}
          {/* TODO: Account photos (our current photos widget is only per-location) */}
          {/* TODO: Files (our current files widget is just for the job) */}
          <div
            className="sticky z-[1002] bg-bz-fill-secondary py-1 text-center text-sm font-semibold text-bz-text-secondary"
            style={{ top: `${TABS_HEIGHT}px` }}
          >
            Only showing items for {address.line1}
          </div>
          <OnsitePageCollapsibleSection
            title="Jobs"
            count={accountTabJobs.length}
            onAdd={canManageJob ? onJobAdd : undefined}
          >
            <JobsWidget
              jobs={accountTabJobs}
              fetching={accountTabFetching}
              readonly={!canManageJob}
            />
          </OnsitePageCollapsibleSection>
          {maintenancePlansSection}
          {/* TODO: payments */}
          {accountInvoicesSection}
          {accountEstimatesSection}
          {equipmentSection}
        </>
      ),
      [
        accountEstimatesSection,
        accountGuid,
        accountInvoicesSection,
        accountNotesSection,
        accountTabFetching,
        accountTabJobs,
        address.line1,
        canManageJob,
        contactsWidgetData,
        equipmentSection,
        isCompanyMaintenancePlansEnabled,
        maintenancePlans,
        maintenancePlansSection,
        onJobAdd,
      ],
    )

    const { pageContentMargin } = useCurrentOnsitePageDimensions()

    if (accountQuery.fetching) {
      return <LoadingSpinner />
    }

    if (accountQuery.error || !accountLegacy) {
      const errorMsg = `Failed to load account for appointment. Account Guid: ${apptLegacy.accountGuid}`
      console.error(errorMsg, accountQuery.error)
      return <div>{errorMsg}</div>
    }

    return (
      <>
        <OnsitePageSection
          hideBottomBorder
          contentSpacingClassName="space-y-4"
          testId="appointment-details-header-section"
        >
          <AppointmentDetailHeader
            setHeaderElement={setHeaderElement}
            appointmentType={appointmentType}
            jobClass={jobType.jobClass}
            jobTypeName={jobType.name}
            displayId={job.displayId}
          />
          {otherActiveAssignment && (
            <HasOtherPendingAssignmentWidget
              appointmentGuid={otherActiveAssignment.appointmentGuid}
              pointOfContactFullName={
                otherActiveAssignment.job.pointOfContact.fullName
              }
              addressLine1={otherActiveAssignment.job.location.address.line1}
            />
          )}
          {((canManageAppointment && (myAssignment ?? otherAssignments[0])) ||
            cancellationStatus?.canceled) && (
            <AssignmentStatusSteps
              assignment={myAssignment ?? otherAssignments[0]}
              appointmentCancellationStatus={cancellationStatus}
              jobGuid={apptLegacy.jobGuid}
              pointOfContactFullName={pointOfContact.fullName}
              pointOfContactFirstName={pointOfContact.firstName}
              pointOfContactPhoneNumber={
                pointOfContact.primaryPhoneNumber?.phoneNumber
              }
              pointOfContactEmailAddress={
                pointOfContact.primaryEmailAddress?.emailAddress
              }
              apptGuid={apptLegacy.appointment.guid}
              onMutate={onMutate}
              checklistInstances={checklistInstances}
            />
          )}
        </OnsitePageSection>

        <div className="sticky top-[-1px] z-[1002] border-0 border-b-8 border-solid border-bz-gray-300 bg-white">
          <Tabs
            size="large"
            tabBarStyle={{
              margin: isMobile ? '0 16px' : '0 24px',
            }}
            activeKey={activeTab}
            onChange={tab => setActiveTab(tab as Tab)}
            items={TABS.map(tab => ({ key: tab, label: tab }))}
          />
          <div
            className="h-[1px] w-screen bg-bz-border"
            style={{
              marginLeft: `-${pageContentMargin}px`,
            }}
          />
        </div>
        <div
          className={classNames({
            hidden: activeTab !== 'Visit',
          })}
        >
          {visitTab}
        </div>
        <div
          className={classNames({
            hidden: activeTab !== 'Job',
          })}
        >
          {jobTab}
        </div>
        <div
          className={classNames({
            hidden: activeTab !== 'Account',
          })}
        >
          {accountTab}
        </div>
        {maintenancePlanWizardOpen && (
          <MaintenancePlanWizard
            onRamp="appt-detail"
            accountGuid={apptLegacy.accountGuid}
            locationGuid={apptLegacy.locationGuid}
            jobGuid={apptLegacy.jobGuid}
            onClose={onMaintenancePlanWizardClose}
          />
        )}
        {financingWizard}
      </>
    )
  },
)

type AppointmentDetailViewAndActionsProps = AppointmentDetailProps & {
  myAssignment: AssignmentWithTechnicianNameDTO | undefined
}

const AppointmentDetailViewAndActions =
  React.memo<AppointmentDetailViewAndActionsProps>(
    ({
      appointmentDetails,
      apptLegacy,
      myAssignment,
      onMutate,
      version,
      setHeaderElement,
    }) => {
      const { job, appointmentGuid } = appointmentDetails

      const { jobGuid, location, account } = job

      const { accountGuid } = account

      const actionMenuProps = useMemo<VisitDetailsActionsProps>(
        () => ({
          version,
          onMutate,
          apptAssignmentGuid: myAssignment?.assignmentGuid,
          jobAppointmentGuid: appointmentGuid,
          accountGuid,
          jobGuid,
          location,
        }),
        [
          accountGuid,
          appointmentGuid,
          jobGuid,
          location,
          myAssignment?.assignmentGuid,
          onMutate,
          version,
        ],
      )
      useGlobalActionMenu({
        type: 'Visit',
        props: actionMenuProps,
      })
      return (
        <AppointmentDetail
          appointmentDetails={appointmentDetails}
          apptLegacy={apptLegacy}
          onMutate={onMutate}
          version={version}
          setHeaderElement={setHeaderElement}
        />
      )
    },
  )

const NOT_FOUND = (
  <div className="flex h-full flex-col items-center justify-center">
    <div className="text-2xl font-semibold">Appointment Not Found</div>
    <div className="text-gray-500">
      The appointment you are looking for could not be found.
    </div>
  </div>
)

type AppointmentDetailByGuidViewLoaderProps = {
  jobAppointmentGuid: Guid
}

const AppointmentDetailByGuidViewLoader =
  React.memo<AppointmentDetailByGuidViewLoaderProps>(
    ({ jobAppointmentGuid }) => {
      const [version, setVersion] = useState(0)

      const userGuid = useExpectedPrincipal().userGuid

      const { jobAppointmentSubscription } =
        useFetchAppointmentBlocksAndAppointmentsLiveQuery({
          jobAppointmentsWhere: {
            jobAppointmentGuid: {
              _eq: jobAppointmentGuid,
            },
          },
        })

      const requery = useCallback(async () => {
        setVersion(version + 1)
      }, [version])

      const [appointmentDetailsByGuidSubscription] = useSubscription({
        query: APPOINTMENT_DETAIL_BY_GUID_SUBSCRIPTION,
        variables: {
          jobAppointmentGuid,
        },
      })

      const bodyRef = useRef<HTMLDivElement>(null)
      const [headerElement, setHeaderElement] = useState<HTMLDivElement | null>(
        null,
      )

      const upperHeader = useMemo(() => {
        const data =
          appointmentDetailsByGuidSubscription.data?.jobAppointmentsByPk

        return data ? (
          <div className="flex w-full flex-row items-center justify-center">
            <JobClassAccentBadge
              jobClass={data.job.jobType.jobClass}
              className="mr-1.5"
            />
            <div className="min-w-0 truncate">{data.appointmentType}</div>
          </div>
        ) : null
      }, [appointmentDetailsByGuidSubscription.data?.jobAppointmentsByPk])

      const { hidingUpperHeader } = useHidingHeader({
        bodyRef,
        headerElement,
        upperHeader,
      })

      const subscriptions = useMemo<
        [
          typeof jobAppointmentSubscription,
          typeof appointmentDetailsByGuidSubscription,
        ]
      >(
        () => [
          jobAppointmentSubscription,
          appointmentDetailsByGuidSubscription,
        ],
        [appointmentDetailsByGuidSubscription, jobAppointmentSubscription],
      )

      const render = useCallback(
        ([appts, data]: [
          FetchAppointmentViewModelLiveQuerySubscription,
          AppointmentDetailByGuidSubscription,
        ]) => {
          const apptVms = appts.jobAppointments.map(
            toAssignedAppointmentViewModel,
          )
          const appt = apptVms[0]

          if (!appt) {
            return NOT_FOUND
          }

          const myAssignment = getAssignment(userGuid, appt)

          const appointmentDetails = data.jobAppointmentsByPk

          if (!appointmentDetails) {
            return NOT_FOUND
          }
          return (
            <AppointmentDetailViewAndActions
              appointmentDetails={appointmentDetails}
              apptLegacy={appt}
              onMutate={requery}
              version={version}
              myAssignment={myAssignment}
              setHeaderElement={setHeaderElement}
            />
          )
        },
        [requery, userGuid, version],
      )

      return (
        <OnsitePageContainer
          containerRef={bodyRef}
          title={hidingUpperHeader}
          className="tech-app-visit-detail-page"
        >
          <UrqlMultiSubscriptionLoader
            subscriptions={subscriptions}
            loadingComponent={FullScreenLoadingSpinner}
            render={render}
          />
        </OnsitePageContainer>
      )
    },
  )

const AppointmentDetailViewLoader = React.memo(() => {
  const appointmentGuidOrAppointmentReferenceNumber = bzExpect(
    useParams().appointmentGuidOrAppointmentReferenceNumber,
    'appointmentGuid or appointmentReferenceNumber must be passed in URL',
  )

  const isAppointmentGuid = guidSchema.safeParse(
    appointmentGuidOrAppointmentReferenceNumber,
  ).success

  const [appointmentDetailsByRefNumQuery] = useQuery({
    query: APPOINTMENT_DETAIL_BY_REF_NUM_QUERY,
    variables: {
      refNum: appointmentGuidOrAppointmentReferenceNumber,
    },
    pause: isAppointmentGuid,
  })

  if (!isAppointmentGuid) {
    if (appointmentDetailsByRefNumQuery.fetching) {
      return FullScreenLoadingSpinner
    }
    if (!appointmentDetailsByRefNumQuery.data?.jobAppointments.length) {
      return (
        <OnsitePageContainer>
          <div className="mt-10 px-6">{NOT_FOUND}</div>
        </OnsitePageContainer>
      )
    }
  }

  return (
    <AppointmentDetailByGuidViewLoader
      jobAppointmentGuid={
        isAppointmentGuid
          ? appointmentGuidOrAppointmentReferenceNumber
          : bzExpect(
              appointmentDetailsByRefNumQuery.data?.jobAppointments[0]
                .jobAppointmentGuid,
            )
      }
    />
  )
})

export default AppointmentDetailViewLoader
