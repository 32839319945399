import { useEffect } from 'react'

export type OnIntersection = (entry: IntersectionObserverEntry) => void

export const useIntersectionObserverForElement = (
  elem: Element | undefined | null,
  onIntersection: OnIntersection,
  options?: IntersectionObserverInit,
) => {
  // Doing it this way so passing an object in to `options` doesn't retrigger `useEffect`
  const { root, rootMargin, threshold } = options || {}
  // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        // Note this should only happen once since we only observe one thing
        for (const entry of entries) {
          onIntersection(entry)
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    )

    if (elem) {
      intersectionObserver.observe(elem)
    }

    return () => {
      if (elem) {
        // The eslint rule doesn't understand refs apparently
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intersectionObserver.unobserve(elem)
      }
    }
  }, [elem, onIntersection, root, rootMargin, threshold])
}

export const useIntersectionObserver = (
  ref: React.MutableRefObject<Element | null>,
  onIntersection: OnIntersection,
  options?: IntersectionObserverInit,
) => {
  // Doing it this way so passing an object in to `options` doesn't retrigger `useEffect`
  const { root, rootMargin, threshold } = options || {}
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        // Note this should only happen once since we only observe one thing
        for (const entry of entries) {
          onIntersection(entry)
        }
      },
      {
        root,
        rootMargin,
        threshold,
      },
    )

    if (ref.current) {
      intersectionObserver.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        // The eslint rule doesn't understand refs apparently
        // eslint-disable-next-line react-hooks/exhaustive-deps
        intersectionObserver.unobserve(ref.current)
      }
    }
  }, [onIntersection, options, ref, root, rootMargin, threshold])
}
