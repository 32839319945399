import { gql } from '../../generated'
import { ContactsWidgetContactFragment } from '../../generated/user/graphql'

gql(/* GraphQL */ `
  fragment ContactsWidgetContact on AccountContacts {
    contactGuid
    primary
    contact {
      fullName
      primaryEmailAddress {
        emailAddress
      }
      primaryPhoneNumber {
        phoneNumber
      }
      notificationPreferenceType
    }
  }
`)

export type ContactsWidgetContact = ContactsWidgetContactFragment

export const CONTACTS_WIDGET_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription ContactsWidgetData($where: AccountContactsBoolExp!) {
    accountContacts(where: $where) {
      ...ContactsWidgetContact
    }
  }
`)
