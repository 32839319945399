import { BzDateFns, LocalDateString } from '@breezy/shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getConfig } from '../config'
import { useExpectedCompanyTimeZoneId } from '../providers/PrincipalUser'

interface ForecastResponseDay {
  date: LocalDateString
  day: {
    maxtemp_f: number
    mintemp_f: number
    condition: {
      text: string
      icon: string
    }
  }
}

interface ForecastResponse {
  forecast: {
    forecastday: Array<ForecastResponseDay>
  }
}

type WeatherForecastDay = {
  date: LocalDateString
  highTempF: number
  lowTempF: number
  weatherIconSrc: string
  weatherCondition: string
}

const apiKey = getConfig().weatherApiKey

const responseToWeatherForecastDays = (
  data: ForecastResponse,
): Record<string, WeatherForecastDay> => {
  return data.forecast.forecastday.reduce((acc, day: ForecastResponseDay) => {
    acc[day.date] = {
      date: day.date,
      highTempF: day.day.maxtemp_f,
      lowTempF: day.day.mintemp_f,
      weatherCondition: day.day.condition.text,
      weatherIconSrc: `https:${day.day.condition.icon}`,
    }
    return acc
  }, {} as Record<string, WeatherForecastDay>)
}

export const queryWeatherFutureDay = async (
  zipCode: string,
  localDateString: LocalDateString,
): Promise<Record<string, WeatherForecastDay>> => {
  try {
    const response = await fetch(
      `https://api.weatherapi.com/v1/future.json?q=${zipCode}&dt=${localDateString}&key=${apiKey}`,
    )
    const data = (await response.json()) as ForecastResponse
    return responseToWeatherForecastDays(data)
  } catch (error) {
    console.error('Failed to fetch weather forecast:', error)
    throw new Error('Failed to fetch weather forecast')
  }
}

export const queryWeatherForecast = async (
  zipCode: string,
  numDays: number,
): Promise<Record<string, WeatherForecastDay>> => {
  try {
    const response = await fetch(
      `https://api.weatherapi.com/v1/forecast.json?q=${zipCode}&days=${numDays}&key=${apiKey}`,
    )
    const data = (await response.json()) as ForecastResponse
    return responseToWeatherForecastDays(data)
  } catch (error) {
    console.error('Failed to fetch weather forecast:', error)
    throw new Error('Failed to fetch weather forecast')
  }
}

export const getWeatherForecastDayLocalStorageCached = async (
  today: LocalDateString,
  zipCode: string,
  localDateString: LocalDateString,
): Promise<WeatherForecastDay | undefined> => {
  const weatherStorageKey = `weatherDay_${zipCode}_${localDateString}-${today}`
  const storedForecast = localStorage.getItem(weatherStorageKey)
  if (storedForecast) {
    return JSON.parse(storedForecast)
  }
  const daysDiffFromToday = BzDateFns.daysBetweenLocalDates(
    today,
    localDateString,
  )
  const isPastDay = daysDiffFromToday < 0
  const isFutureWeatherDay = daysDiffFromToday >= 14 && daysDiffFromToday <= 300
  const isPastMaxRange = daysDiffFromToday > 300

  if (isFutureWeatherDay) {
    return queryWeatherFutureDay(zipCode, localDateString).then(r => {
      localStorage.setItem(
        weatherStorageKey,
        JSON.stringify(r[localDateString]),
      )
      return r[localDateString]
    })
  }

  if (!isFutureWeatherDay) {
    return queryWeatherForecast(zipCode, 14).then(r => {
      Object.entries(r).forEach(([date, forecastDay]) => {
        localStorage.setItem(
          `weatherDay_${zipCode}_${date}-${today}`,
          JSON.stringify(forecastDay),
        )
      })
      return r[localDateString]
    })
  }

  if (isPastMaxRange) {
    console.error(
      `Requested Future Weather Day past Max Range - ${localDateString}`,
    )
    return undefined
  }

  if (isPastDay) {
    console.error(`Requested Past Weather Day - ${localDateString}`)
    return undefined
  }

  return undefined
}

export const useWeatherForecast = (
  localDate: LocalDateString,
  zipCode: string | undefined,
  numDays = 14,
) => {
  const tzId = useExpectedCompanyTimeZoneId()

  const today = BzDateFns.getTodayLocalDate(tzId)

  const [forecast, setForecast] = useState<Record<string, WeatherForecastDay>>(
    {},
  )

  const setForecastDay = useCallback(
    (date: LocalDateString, forecastDay: WeatherForecastDay) => {
      setForecast(prev => ({ ...prev, [date]: forecastDay }))
    },
    [setForecast],
  )

  const queryLocalDates = useMemo(() => {
    const dates = Array.from({ length: numDays }, (_, i) =>
      BzDateFns.localDateTransform(localDate, d => BzDateFns.addDays(d, i)),
    )
    return dates
  }, [numDays, localDate])

  useEffect(() => {
    if (zipCode) {
      queryLocalDates.forEach(localDate => {
        getWeatherForecastDayLocalStorageCached(today, zipCode, localDate)
          .then(d => {
            if (d) setForecastDay(localDate, d)
          })
          .catch(e => {
            console.error(e)
          })
      })
    }
  }, [queryLocalDates, localDate, zipCode, setForecastDay, today])

  return forecast
}
