import { useMemo } from 'react'
import { useSubscription } from 'urql'
import { InstalledEquipmentBoolExp } from '../../generated/user/graphql'
import { postErrorAlertWithDD } from '../../utils/GraphqlAlerts'
import {
  EQUIPMENT_WIDGET_DATA_SUBSCRIPTION,
  EquipmentWidgetEquipment,
} from './EquipmentWidget.gql'

type EquipmentWidgetGuids = {
  accountGuid?: never
  locationGuid?: never
}

export type EquipmentWidgetGuidsProp =
  | (Omit<EquipmentWidgetGuids, 'accountGuid'> & { accountGuid: string })
  | (Omit<EquipmentWidgetGuids, 'locationGuid'> & { locationGuid: string })

type UseEquipmentWidgetDataRes = {
  fetching: boolean
  equipment: EquipmentWidgetEquipment[]
}

export const useEquipmentWidgetData = ({
  accountGuid,
  locationGuid,
}: EquipmentWidgetGuidsProp): UseEquipmentWidgetDataRes => {
  const where = useMemo<InstalledEquipmentBoolExp>(() => {
    if (accountGuid) {
      return {
        location: { accountLocations: { accountGuid: { _eq: accountGuid } } },
      }
    } else if (locationGuid) {
      return { locationGuid: { _eq: locationGuid } }
    } else {
      const msg = 'No valid guid supplied to useEquipmentWidgetData'
      console.error(msg)
      postErrorAlertWithDD(msg)
      throw new Error(msg)
    }
  }, [accountGuid, locationGuid])
  const [{ fetching, data }] = useSubscription({
    query: EQUIPMENT_WIDGET_DATA_SUBSCRIPTION,
    variables: {
      where,
    },
  })

  return useMemo(
    () => ({
      equipment: data?.equipment ?? [],
      fetching,
    }),
    [data?.equipment, fetching],
  )
}
