import { isNullish, WithRequiredKeys } from '@breezy/shared'
import {
  faAdd,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIsTouchScreen } from '../../hooks/useIsMobile'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import './OnsitePageCollapsibleSection.less'
import { OnsitePageSection, OnsitePageSectionProps } from './OnsitePageSection'

export type OnsitePageCollapsibleSectionProps = WithRequiredKeys<
  Omit<OnsitePageSectionProps, 'disableContentSpacing'>,
  'title'
> & {
  count?: number
  onAdd?: () => void
  defaultCollapsed?: boolean
  noContentMargin?: boolean
}

export const OnsitePageCollapsibleSection =
  React.memo<OnsitePageCollapsibleSectionProps>(
    ({
      title,
      count,
      onAdd,
      children,
      defaultCollapsed,
      noContentMargin,
      ...rest
    }) => {
      const isTouchScreen = useIsTouchScreen()
      const [collapsed, setCollapsed] = useState(defaultCollapsed ?? false)

      useEffect(() => {
        if (defaultCollapsed) {
          return
        }
        if (!isNullish(count)) {
          setCollapsed(count === 0)
        }
      }, [count, defaultCollapsed])

      useEffect(() => {
        if (!isNullish(defaultCollapsed)) {
          setCollapsed(defaultCollapsed)
        }
      }, [defaultCollapsed])

      const contentRef = useRef<HTMLDivElement>(null)

      const [contentHeight, setContentHeight] = useState(0)

      const onResize = useCallback<OnResize>(({ height }) => {
        setContentHeight(height)
      }, [])

      useResizeObserver(contentRef, onResize)

      return (
        <OnsitePageSection contentSpacingClassName="" {...rest}>
          <div className="flex flex-row items-center space-x-3 text-xl font-semibold">
            <div className="min-w-0 flex-1">
              <span>{title}</span>
              {isNullish(count) ? null : (
                <span className="ml-2 text-bz-text-tertiary">({count})</span>
              )}
            </div>
            {onAdd && (
              <Button
                onClick={onAdd}
                shape="circle"
                icon={<FontAwesomeIcon icon={faAdd} />}
              />
            )}
            <Button
              onClick={() => setCollapsed(!collapsed)}
              shape="circle"
              disabled={isNullish(count) ? false : count === 0}
              className={classNames({
                'onsite-page-collapsible-section-touch-screen-disable-button-hover-state':
                  isTouchScreen,
              })}
              icon={
                <FontAwesomeIcon
                  icon={collapsed ? faChevronRight : faChevronDown}
                />
              }
            />
          </div>
          <div
            className="overflow-hidden transition-all"
            style={{
              maxHeight: collapsed
                ? 0
                : `${contentHeight + (noContentMargin ? 0 : 12)}px`,
            }}
          >
            <div
              ref={contentRef}
              className={classNames({
                'pt-3': !noContentMargin,
              })}
            >
              {children}
            </div>
          </div>
        </OnsitePageSection>
      )
    },
  )
