import {
  IsoDateString,
  MaintenancePlanCollapsibleViewModel,
  MaintenancePlanPaymentInterval,
} from '@breezy/shared'
import { useMemo } from 'react'
import { useSubscription } from 'urql'
import { MaintenancePlansQueryableBoolExp } from '../../generated/user/graphql'
import { useMaintenancePlanCollapsibleData } from '../../pages/AccountDetailsPage/accountDetailsUtils'
import { postErrorAlertWithDD } from '../../utils/GraphqlAlerts'
import { MAINTENANCE_PLANS_WIDGET_DATA_SUBSCRIPTION } from './MaintenancePlansWidget.gql'

export type MaintenancePlansWidgetPlan = MaintenancePlanCollapsibleViewModel & {
  activatedAt?: IsoDateString
  billingAnchor?: string
  billingStartAt?: IsoDateString
  terminatesAt?: IsoDateString
  paymentAmountUsc?: number
  paymentInterval?: MaintenancePlanPaymentInterval
}

type MaintenancePlansWidgetGuids = {
  accountGuid?: never
  locationGuid?: never
}

export type MaintenancePlansWidgetGuidsProp =
  | (Omit<MaintenancePlansWidgetGuids, 'accountGuid'> & { accountGuid: string })
  | (Omit<MaintenancePlansWidgetGuids, 'locationGuid'> & {
      locationGuid: string
    })

type UseMaintenancePlansWidgetDataRes = {
  fetching: boolean
  maintenancePlans: MaintenancePlansWidgetPlan[]
}

export const useMaintenancePlansWidgetData = ({
  accountGuid,
  locationGuid,
}: MaintenancePlansWidgetGuidsProp): UseMaintenancePlansWidgetDataRes => {
  const where = useMemo<MaintenancePlansQueryableBoolExp>(() => {
    if (accountGuid) {
      return { accountGuid: { _eq: accountGuid } }
    } else if (locationGuid) {
      return { locationGuid: { _eq: locationGuid } }
    } else {
      const msg = 'No valid guid supplied to useMaintenancePlansWidgetData'
      console.error(msg)
      postErrorAlertWithDD(msg)
      throw new Error(msg)
    }
  }, [accountGuid, locationGuid])
  const [{ fetching, data }] = useSubscription({
    query: MAINTENANCE_PLANS_WIDGET_DATA_SUBSCRIPTION,
    variables: {
      where,
    },
  })

  const maintenancePlans = useMaintenancePlanCollapsibleData(
    data?.maintenancePlansQueryable ?? [],
  )

  return useMemo(
    () => ({
      maintenancePlans,
      fetching,
    }),
    [fetching, maintenancePlans],
  )
}
