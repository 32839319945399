import { gql } from '../../../generated'
import { TechAppHomePageDataSubscription } from '../../../generated/user/graphql'

export const TECH_APP_HOME_PAGE_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription TechAppHomePageData(
    $userGuid: uuid!
    $tomorrow: timestamptz!
    $today: timestamptz!
  ) {
    assignments: jobAppointmentAssignments(
      where: {
        technicianUserGuid: { _eq: $userGuid }
        assignmentStart: { _lte: $tomorrow }
        assignmentEnd: { _gte: $today }
      }
      orderBy: { assignmentStart: ASC }
    ) {
      assignmentGuid: jobAppointmentAssignmentGuid
      appointmentGuid: jobAppointmentGuid
      assignmentStart
      assignmentEnd
      job {
        displayId
        jobType {
          jobClass
          name
        }
        location {
          address {
            line1
            line2
            city
            zipCode
            stateAbbreviation
          }
          maintenancePlans(limit: 1) {
            maintenancePlanDefinition {
              flare {
                primaryColorHex
              }
            }
          }
        }
        pointOfContact {
          fullName
          primaryPhoneNumber {
            phoneNumber
          }
        }
      }
      jobAppointment {
        appointmentType
        cancellationStatus {
          canceled
          updatedAt
        }
      }
      assignmentStatus {
        jobAppointmentAssignmentStatusType
        updatedAt
      }
    }
  }
`)

export type TechAppHomeAssignment =
  TechAppHomePageDataSubscription['assignments'][0]
