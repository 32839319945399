import { Guid, noOp } from '@breezy/shared'
import {
  faAirConditioner,
  faBarcodeRead,
  faCommentLines,
  faFileContract,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faImage,
  faQrcode,
  faReceipt,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useMemo } from 'react'
import { useCanManageJob } from '../../../../hooks/permission/useCanManageJob'
import useAppNavigation from '../../../../hooks/useAppNav'
import { useEndOfVisitReviewSettings } from '../../../../hooks/useEndOfVisitReviewSettings'
import { useFeatureFlag } from '../../../../hooks/useFeatureFlags'
import { useMaintenancePlanWizardFlags } from '../../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { useWisetackEnabled } from '../../../../providers/CompanyFinancialConfigWrapper'
import { useModalState } from '../../../../utils/react-utils'
import { PrefillEquipmentFormData } from '../../../EquipmentUpsertForm/EquipmentUpsertForm'
import { useFinancingWizard } from '../../../Financing/hooks/useFinancingWizard'
import { LabelScanner } from '../../../LabelScanner/LabelScanner'
import { ReviewQRCodeModal } from '../../MyAppointmentsPage/PresentReviewQRCodeModal/ReviewQRCodeModal'
import {
  DrawerLocation,
  useTechExpDrawers,
} from '../../TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import {
  ActionMenuItem,
  DisabledGlobalActionsMap,
  GlobalActionMenuItems,
} from './ActionMenuProvider'

export const VISIT_DETAILS_ACTIONS_DISABLE_GLOBAL_ACTIONS_MAP: DisabledGlobalActionsMap =
  {
    createMaintenancePlan: true,
  }

export type VisitDetailsActionsProps = {
  version: number
  onMutate?: () => void
  apptAssignmentGuid?: string | undefined
  jobGuid: Guid
  accountGuid: Guid
  jobAppointmentGuid: Guid
  location: DrawerLocation
}

export const VisitDetailsActions = React.memo<VisitDetailsActionsProps>(
  ({
    apptAssignmentGuid,
    onMutate = noOp,
    location,
    jobGuid,
    accountGuid,
    jobAppointmentGuid,
  }) => {
    const isWisetackEnabled = useWisetackEnabled()
    const appNav = useAppNavigation()
    const drawers = useTechExpDrawers()
    const { canManage: canManageJob } = useCanManageJob(jobGuid)
    const labelScannerEnabled = useFeatureFlag('label-scanner')
    const endOfVisitReviewEnabled = useFeatureFlag('end-of-visit-review')
    const [
      reviewQRCodeModalOpen,
      openReviewQRCodeModal,
      closeReviewQRCodeModal,
    ] = useModalState()

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      jobGuid,
      onCancel: onMutate,
    })
    const {
      endOfVisitReviewLinkType: defaultReviewLinkType,
      isEndOfVisitReviewLinkEnabled,
      reviewIdConfig,
    } = useEndOfVisitReviewSettings()

    const [, openMaintenancePlanWizard] = useMaintenancePlanWizardFlags(
      'mpw',
      'appt-action-menu',
    )

    const guidLinks = useMemo(
      () => ({
        locationGuid: location.locationGuid,
        accountGuid,
        jobGuid,
        jobAppointmentGuid,
        apptAssignmentGuid,
      }),
      [
        location.locationGuid,
        accountGuid,
        jobGuid,
        jobAppointmentGuid,
        apptAssignmentGuid,
      ],
    )

    const onAddNoteFinished = onMutate
    const onPhotoUploadFinished = onMutate
    const onUpsertInstalledEquipmentFinished = onMutate
    const onUpsertInstalledHvacSystemFinished = onMutate

    const [labelScannerOpen, openLabelScanner, closeLabelScanner] =
      useModalState()

    const actions = useMemo(() => {
      const items: ActionMenuItem[] = []

      if (labelScannerEnabled) {
        items.push({
          label: 'Scan Equipment Label',
          icon: <FontAwesomeIcon icon={faBarcodeRead} />,
          onClick: openLabelScanner,
          ddActionName: 'bz-appt-actions-scan-equipment-label',
        })
      }

      if (
        endOfVisitReviewEnabled &&
        defaultReviewLinkType &&
        isEndOfVisitReviewLinkEnabled
      ) {
        items.push({
          label: 'Present Review QR Code',
          onClick: openReviewQRCodeModal,
          ddActionName: 'bz-appt-actions-present-review-qr-code',
          icon: <FontAwesomeIcon icon={faQrcode} />,
        })
      }

      if (canManageJob) {
        items.push({
          label: 'Create Estimate',
          onClick: () => {
            appNav.navigateToCreateNewEstimate({
              jobGuid,
              jobAppointmentGuid,
            })
          },
          icon: <FontAwesomeIcon icon={faReceipt} />,
        })

        items.push({
          label: 'Create Invoice',
          onClick: () => {
            appNav.navigateToCreateNewInvoiceV2(accountGuid, jobGuid, {
              jobAppointmentGuid,
            })
          },
          icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
        })

        if (isWisetackEnabled) {
          items.push({
            label: 'Send Financing Link',
            onClick: showFinancingWizard,
            icon: <FontAwesomeIcon icon={faHandHoldingUsd} />,
          })
        }

        items.push({
          label: 'Add Photos',
          onClick: () => {
            drawers.beginUploadPhoto(guidLinks, onPhotoUploadFinished)
          },
          icon: <FontAwesomeIcon icon={faImage} />,
        })

        items.push({
          label: 'Add Note',
          onClick: () => {
            drawers.beginAddNote(
              { ...guidLinks, primaryNoteType: 'JOB_APPOINTMENT' },
              onAddNoteFinished,
            )
          },
          icon: <FontAwesomeIcon icon={faCommentLines} />,
        })

        items.push({
          label: 'Add Equipment',
          onClick: () => {
            drawers.beginUpsertInstalledEquipment(
              location,
              undefined,
              onUpsertInstalledEquipmentFinished,
            )
          },
          icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
        })

        items.push({
          label: 'Add HVAC System',
          onClick: () => {
            drawers.beginUpsertHvacSystem(
              location,
              undefined,
              onUpsertInstalledHvacSystemFinished,
            )
          },
          icon: <FontAwesomeIcon icon={faAirConditioner} />,
        })
      }

      items.splice(
        items.findIndex(item => item.label === 'Add Photos'),
        0,
        {
          label: 'Create Maintenance Plan',
          onClick: openMaintenancePlanWizard,
          icon: <FontAwesomeIcon icon={faFileContract} />,
        },
      )

      return items
    }, [
      labelScannerEnabled,
      endOfVisitReviewEnabled,
      defaultReviewLinkType,
      isEndOfVisitReviewLinkEnabled,
      canManageJob,
      openMaintenancePlanWizard,
      openLabelScanner,
      openReviewQRCodeModal,
      isWisetackEnabled,
      appNav,
      jobGuid,
      jobAppointmentGuid,
      accountGuid,
      showFinancingWizard,
      drawers,
      guidLinks,
      onPhotoUploadFinished,
      onAddNoteFinished,
      location,
      onUpsertInstalledEquipmentFinished,
      onUpsertInstalledHvacSystemFinished,
    ])

    const onLabelScannerAddEquipment = useCallback(
      (summary: PrefillEquipmentFormData) => {
        drawers.beginUpsertInstalledEquipment(
          location,
          summary,
          onUpsertInstalledEquipmentFinished,
        )
      },
      [drawers, location, onUpsertInstalledEquipmentFinished],
    )

    return (
      <>
        <GlobalActionMenuItems title="Visit" actions={actions} />
        {financingWizard}
        {labelScannerOpen && (
          <LabelScanner
            onClose={closeLabelScanner}
            links={guidLinks}
            onAddEquipment={onLabelScannerAddEquipment}
          />
        )}
        {reviewQRCodeModalOpen && (
          <ReviewQRCodeModal
            defaultReviewType={defaultReviewLinkType}
            reviewIdConfig={reviewIdConfig}
            onClose={closeReviewQRCodeModal}
          />
        )}
      </>
    )
  },
)
