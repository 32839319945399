import { useMemo } from 'react'
import { useSubscription } from 'urql'
import { AccountContactsBoolExp } from '../../generated/user/graphql'
import {
  CONTACTS_WIDGET_DATA_SUBSCRIPTION,
  ContactsWidgetContact,
} from './ContactsWidget.gql'

type ContactsWidgetGuids = {
  accountGuid: string
}

// Doing it this way to match the other widget components, since we may at some point want to have different ways to
// pull contacts instead of just by account.
export type ContactsWidgetGuidsProp = ContactsWidgetGuids

type UseContactsWidgetDataRes = {
  fetching: boolean
  contacts: ContactsWidgetContact[]
}

export const useContactsWidgetData = ({
  accountGuid,
}: ContactsWidgetGuidsProp): UseContactsWidgetDataRes => {
  const where = useMemo<AccountContactsBoolExp>(
    () => ({ accountGuid: { _eq: accountGuid } }),
    [accountGuid],
  )
  const [{ fetching, data }] = useSubscription({
    query: CONTACTS_WIDGET_DATA_SUBSCRIPTION,
    variables: {
      where,
    },
  })

  return useMemo(
    () => ({
      contacts: data?.accountContacts ?? [],
      fetching,
    }),
    [data?.accountContacts, fetching],
  )
}
