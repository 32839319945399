import { gql } from '../../../../generated'
import {
  AppointmentDetailsFragment,
  VisitAccountDetailsAccountFragment,
} from '../../../../generated/user/graphql'

// See `targets/web-app/src/pages/AccountDetailsPage/AccountDetailsPage.gql.ts` for the `MaintenancePlanCollapsibleData`
// fragment
export const APPOINTMENT_DETAIL_ACCOUNT_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription AppointmentDetailAccount($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountGuid
      maintenancePlans {
        ...MaintenancePlanCollapsibleData
      }
      accountContacts {
        accountContactGuid
        contact {
          lastName
          firstName
          primaryEmailAddress {
            emailAddress
          }
          primaryPhoneNumber {
            phoneNumber
          }
        }
        primary
      }
    }
  }
`)

gql(/* GraphQL */ `
  fragment AppointmentDetails on JobAppointments {
    job {
      jobGuid
      displayId
      jobType {
        name
        jobClass
        jobTypeGuid
      }
      location {
        locationGuid
        address {
          city
          line1
          line2
          stateAbbreviation
          zipCode
          addressGuid
        }
        maintenancePlans(limit: 1) {
          maintenancePlanDefinition {
            marketingInfo {
              name
            }
            flare {
              primaryColorHex
            }
          }
          maintenancePlanGuid
        }
        displayName
      }
      pointOfContact {
        fullName
        firstName
        primaryPhoneNumber {
          phoneNumber
        }
        primaryEmailAddress {
          emailAddress
        }
      }
      equipment: equipmentTypeJobLinks {
        estimatedEquipmentAge
        equipmentType
        equipmentTypeLinkGuid
      }
      tags {
        tag {
          name
        }
      }
      isMembershipOpportunity
      isMembershipRenewalOpportunity
      isHotLead
      isOpportunity
      isMembershipSold
      isJobConverted: isConverted
      jobLifecycleStatus {
        isWorkComplete
        name
        color
      }
      summary
      account {
        accountDisplayName
        accountType
        accountGuid
      }
      assignments: jobAppointmentAssignments {
        appointmentGuid: jobAppointmentGuid
        technician {
          firstName
          lastName
          userGuid
          assignments: jobAppointmentAssignments(
            where: {
              jobAppointmentGuid: { _neq: $jobAppointmentGuid }
              assignmentStatus: {
                jobAppointmentAssignmentStatusType: {
                  _in: [EN_ROUTE, IN_PROGRESS]
                }
              }
            }
            limit: 1
          ) {
            appointmentGuid: jobAppointmentGuid
            job {
              pointOfContact {
                fullName
              }
              location {
                address {
                  line1
                }
              }
            }
          }
        }
        assignmentStatus {
          status: jobAppointmentAssignmentStatusType
          updatedAt
        }
        assignmentStart
        assignmentEnd
        assignmentGuid: jobAppointmentAssignmentGuid
      }
    }
    appointmentType
    appointmentGuid: jobAppointmentGuid
    appointmentWindowEnd
    appointmentWindowStart
    description
    cancellationStatus {
      canceled
      updatedAt
    }
    checklists: appointmentChecklistInstances {
      instanceGuid: appointmentChecklistInstanceGuid
      checklistGuid: appointmentChecklistGuid
      checklist
    }
  }
`)

export type AppointmentDetails = AppointmentDetailsFragment
export type AppointmentDetailsAssignment =
  AppointmentDetailsFragment['job']['assignments'][number]

export const APPOINTMENT_DETAIL_BY_GUID_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription AppointmentDetailByGuid($jobAppointmentGuid: uuid!) {
    jobAppointmentsByPk(jobAppointmentGuid: $jobAppointmentGuid) {
      ...AppointmentDetails
    }
  }
`)

export const APPOINTMENT_DETAIL_BY_REF_NUM_QUERY = gql(/* GraphQL */ `
  query AppointmentDetailByRefNum($refNum: String!) {
    jobAppointments(
      where: { appointmentReferenceNumber: { _eq: $refNum } }
      limit: 1
    ) {
      jobAppointmentGuid
    }
  }
`)

export const CURRENT_ASSIGNMENT_TIMESHEET_ENTRIES_QUERY = gql(/* GraphQL */ `
  subscription UserAssignmentTimeClockStatusCardCurrentAssignmentTimesheetEntriesQuery(
    $userGuid: uuid!
    $jobAppointmentAssignmentGuid: uuid!
  ) {
    timesheetEntries(
      where: {
        userGuid: { _eq: $userGuid }
        timesheetEntryLinkData: {
          jobAppointmentAssignmentGuid: { _eq: $jobAppointmentAssignmentGuid }
        }
      }
      orderBy: { finalStartTime: ASC, finalEndTime: ASC_NULLS_LAST }
    ) {
      finalStartTime
      finalEndTime
      entryLengthInMinutes
      timesheetEntryActivity {
        activityName
        isPayable
      }
      timesheetEntryLinkData {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
      }
    }
  }
`)

gql(/* GraphQL */ `
  fragment VisitAccountDetailsAccount on Accounts {
    accountDisplayName
    accountType
    accountLeadSource {
      companyLeadSource {
        canonicalLeadSourceName
        canonicalLeadSourceNameOverride
      }
    }
    tags {
      tag {
        name
      }
    }
    lastJobDate
    createdAt
    jobsAggregate {
      aggregate {
        count
      }
      nodes {
        jobInvoices(where: { invoice: { status: { _eq: "PAID" } } }) {
          invoice {
            totalUsc
          }
        }
      }
    }
  }
`)

export type VisitAccountDetailsAccount = VisitAccountDetailsAccountFragment

export const VISIT_ACCOUNT_DETAILS_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription VisitAccountDetailsData($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      ...VisitAccountDetailsAccount
    }
  }
`)

export const VISIT_ACCOUNT_TAB_JOBS_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription VisitAccountTabJobsData($locationGuid: uuid!) {
    jobs(where: { locationGuid: { _eq: $locationGuid } }) {
      jobGuid
      jobType {
        name
      }
      displayId
      jobLifecycleStatus {
        name
        color
      }
      location {
        address {
          line1
          zipCode
        }
        locationGuid
      }
      createdAt
      workCompletedAt
      estimates {
        estimateGuid
        estimateOptionsAggregate {
          aggregate {
            count
          }
        }
        displayId
        createdByUser {
          fullName
        }
        createdAt
        status
        options: estimateOptions(orderBy: { seq: ASC }) {
          seq
          displayName
          totalUsc
          cartItems {
            cartItem {
              name
              unitPriceUsc
              quantity
              isDiscountable
            }
          }
          discounts: estimateOptionDiscounts {
            discountAmountUsc
            discountRate
            type: discountType
          }
          isRecommended
          isSelected
        }
      }
      appointments {
        appointmentType
        appointmentWindowStart
        jobAppointmentGuid
      }
    }
  }
`)

export const VISIT_ACCOUNT_TAB_INVOICES_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription VisitAccountTabInvoicesData($locationGuid: uuid!) {
    locationInvoices(where: { locationGuid: { _eq: $locationGuid } }) {
      invoice {
        jobLink {
          jobGuid
        }
        invoiceGuid
        displayId
        status
        totalUsc
        issuedAt
        createdByUser {
          fullName
        }
        cartItems: invoiceCartItems(orderBy: { seq: ASC }) {
          cartItem {
            name
            unitPriceUsc
            quantity
            isDiscountable
          }
        }
        discounts: invoiceDiscounts {
          discountRate
          type: discountType
          discountAmountUsc
        }
        paymentRecords {
          amountUsd
          paymentMethod
          paymentRecordGuid
        }
      }
    }
  }
`)
