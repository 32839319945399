import {
  BzDateFns,
  CalculatePaths,
  dates,
  formatEquipmentType,
  getDisplayNameForAccountType,
  JobClass,
  phoneUtils,
  richJobTypeDescriptor,
} from '@breezy/shared'
import {
  faAirConditioner,
  faClock,
  faEye,
  faHistory,
  faHouseUser,
  faLocationDot,
  faMap,
  faOctagonExclamation,
  faPhone,
  faScrewdriverWrench,
  faUser,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DetailChip } from '../../../../adam-components/ListPage/DetailChip'
import {
  ItemIconActionRow,
  OnsitePageSimpleSectionItem,
  OnsitePageSimpleSectionItemTheme,
} from '../../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import {
  SectionedContent,
  SectionedSection,
} from '../../../../adam-components/SectionedCard/SectionedContent'
import { FormattedEmDash } from '../../../../elements/EmDash/EmDash'
import { Link } from '../../../../elements/Link/Link'
import { useFeatureFlag } from '../../../../hooks/useFeatureFlags'
import { useMaintenancePlanWizardFlags } from '../../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { AppointmentStatusTag } from '../../../../pages/ScheduleV2Page/AppointmentStatusTag'
import { getInferredAppointmentStatusFromAssignments } from '../../../../pages/ScheduleV2Page/scheduleUtils'
import { useExpectedCompanyTimeZoneId } from '../../../../providers/PrincipalUser'
import tailwindConfig from '../../../../tailwind.config'
import { useModalState } from '../../../../utils/react-utils'
import { getAvatarShortStringForPerson } from '../../../../utils/TechnicianResource'
import { useChips } from '../../../JobOutcomesModal/ChipsPanel'
import { useOpenMap } from '../../../LocationInfoLink'
import { Tag } from '../../../Tags'
import {
  AppointmentDetails,
  AppointmentDetailsAssignment,
} from './AppointmentDetail.gql'
import { ServiceHistoryModal } from './ServiceHistory/ServiceHistory'

type BasicSectionProps = React.PropsWithChildren<{
  title: React.ReactNode
}>

const BasicSection = React.memo<BasicSectionProps>(({ title, children }) => (
  <div className="text-base">
    <div className="font-semibold">{title}</div>
    <div className="mt-2">{children}</div>
  </div>
))

type AssignmentProps = {
  canceled?: boolean
  assignment: AppointmentDetailsAssignment
}

const Assignment = React.memo<AssignmentProps>(({ canceled, assignment }) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { assignmentStart, assignmentEnd } = assignment
  const statusTag = useMemo(() => {
    const status = getInferredAppointmentStatusFromAssignments(
      [assignment],
      canceled,
    )

    return <AppointmentStatusTag status={status} />
  }, [assignment, canceled])

  const range = useMemo(() => {
    return dates.calculateDateTimeWindow(assignmentStart, assignmentEnd, tzId, {
      includeDate: false,
      alwaysShowMinutes: true,
    })
  }, [assignmentEnd, assignmentStart, tzId])

  const avatarShortString = useMemo(
    () => getAvatarShortStringForPerson(assignment.technician),
    [assignment.technician],
  )

  return (
    <ItemIconActionRow
      smallIconMode
      icon={<div className="font-semibold">{avatarShortString}</div>}
      subContent={`${range} (Assigned)`}
    >
      <div className="flex flex-row items-center">
        <div className="mr-2 font-semibold">
          {assignment.technician.firstName} {assignment.technician.lastName}
        </div>
        {statusTag}
      </div>
    </ItemIconActionRow>
  )
})

type VisitDetailsProps = {
  appointmentDetails: AppointmentDetails
  maintenancePlansEnabled: boolean
  jobTab?: boolean
}

export const VisitDetails = React.memo<VisitDetailsProps>(
  ({ appointmentDetails, maintenancePlansEnabled, jobTab }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const navigate = useNavigate()

    const serviceHistoryEnabled = useFeatureFlag('appointment-service-history')

    const {
      job,
      appointmentWindowStart,
      appointmentWindowEnd,
      description,
      appointmentType,
      appointmentGuid,
    } = appointmentDetails
    const {
      jobGuid,
      displayId,
      location,
      pointOfContact,
      jobType,
      equipment,
      tags,
      summary,
      account,
      jobLifecycleStatus,
      assignments,
    } = job

    const { accountGuid, accountDisplayName, accountType } = account
    const phoneNumber = pointOfContact.primaryPhoneNumber?.phoneNumber
    const { address, maintenancePlans } = location

    const maintenancePlan = maintenancePlans[0]

    const maintenancePlanColor =
      maintenancePlan?.maintenancePlanDefinition?.flare?.primaryColorHex ??
      tailwindConfig.theme.extend.colors.bz.primary

    const filteredAssignments = useMemo(() => {
      if (jobTab) {
        return assignments
      }
      return assignments.filter(
        assignment => assignment.appointmentGuid === appointmentGuid,
      )
    }, [appointmentGuid, assignments, jobTab])

    const openMap = useOpenMap(address)

    const equipmentTitle = useMemo(() => {
      switch (jobType.jobClass) {
        case JobClass.SALES:
          return 'Equipment Interested In'
        case JobClass.INSTALL:
          return 'Equipment to Install'
        case JobClass.MAINTENANCE:
          return 'Equipment to Maintain'
        case JobClass.SERVICE:
          return 'Equipment to Repair'
        default:
          return 'Equipment'
      }
    }, [jobType.jobClass])

    const [dateStr, arrivalWindow] = useMemo(() => {
      const dateStr = BzDateFns.formatFromISO(
        appointmentWindowStart,
        'EEEE, MMMM d',
        tzId,
      )
      const range = dates.calculateDateTimeWindow(
        appointmentWindowStart,
        appointmentWindowEnd,
        tzId,
        {
          includeDate: false,
          alwaysShowMinutes: true,
        },
      )
      return [dateStr, range]
    }, [appointmentWindowEnd, appointmentWindowStart, tzId])

    const [, openMaintenancePlanWizard] = useMaintenancePlanWizardFlags(
      'mpw',
      'account-details-not-a-member-callout',
    )

    const [
      serviceHistoryModalOpen,
      openServiceHistoryModal,
      closeServiceHistoryModal,
    ] = useModalState()

    const sections: SectionedSection[] = [
      {
        content: (
          <div className="space-y-4">
            {jobTab ? (
              <ItemIconActionRow
                squareIcon
                smallIconMode
                icon={<FontAwesomeIcon icon={faWrench} />}
                subContent={`${richJobTypeDescriptor(
                  jobType.name,
                )} Job #${displayId}`}
                rightContent={
                  <Button
                    type="link"
                    size="large"
                    onClick={() => {
                      navigate(CalculatePaths.jobDetails({ jobGuid }))
                    }}
                    icon={<FontAwesomeIcon icon={faEye} />}
                  >
                    View
                  </Button>
                }
              >
                {appointmentType} Visit
              </ItemIconActionRow>
            ) : (
              <ItemIconActionRow
                squareIcon
                smallIconMode
                icon={<FontAwesomeIcon icon={faClock} />}
                subContent={`${arrivalWindow} (Arrival)`}
              >
                {dateStr}
              </ItemIconActionRow>
            )}
            <ItemIconActionRow
              squareIcon
              smallIconMode
              icon={<FontAwesomeIcon icon={faLocationDot} />}
              subContent={`${address.city}, ${address.stateAbbreviation} ${address.zipCode}`}
              rightContent={
                <Button
                  type="link"
                  size="large"
                  onClick={openMap}
                  icon={<FontAwesomeIcon icon={faMap} />}
                >
                  Route
                </Button>
              }
            >
              {address.line1}
              {address.line2 ? `, ${address.line2}` : ''}
            </ItemIconActionRow>
            <ItemIconActionRow
              squareIcon
              smallIconMode
              icon={<FontAwesomeIcon icon={faUser} />}
              subContent={
                phoneNumber
                  ? phoneUtils.tryFormat(phoneNumber)
                  : 'No phone number'
              }
              rightContent={
                phoneNumber && (
                  <Button
                    type="link"
                    size="large"
                    href={`tel:${phoneNumber.replace(/[^0-9]/g, '')}`}
                    icon={<FontAwesomeIcon icon={faPhone} />}
                  >
                    Call
                  </Button>
                )
              }
            >
              {pointOfContact.fullName}
            </ItemIconActionRow>
            <ItemIconActionRow
              squareIcon
              smallIconMode
              icon={<FontAwesomeIcon icon={faHouseUser} />}
              subContent={getDisplayNameForAccountType(accountType)}
              rightContent={
                <Button
                  type="link"
                  size="large"
                  onClick={() => {
                    navigate(CalculatePaths.accountDetails({ accountGuid }))
                  }}
                  icon={<FontAwesomeIcon icon={faEye} />}
                >
                  View
                </Button>
              }
            >
              {accountDisplayName}
            </ItemIconActionRow>
          </div>
        ),
        verticalPaddingClassName: 'pb-6',
      },
    ]

    if (serviceHistoryEnabled) {
      const icon = <FontAwesomeIcon icon={faHistory} />
      const header = 'Service History'
      const actions = (
        <Link
          data-dd-action-name="bz-service-history-visit-details"
          onClick={openServiceHistoryModal}
        >
          View Service History
        </Link>
      )

      sections.push({
        content: (
          <OnsitePageSimpleSectionItem
            smallIconMode
            icon={icon}
            subContent={
              <div className="mt-1">
                <div className="mt-1">{actions}</div>
              </div>
            }
          >
            {header}
          </OnsitePageSimpleSectionItem>
        ),
        verticalPaddingClassName: 'py-6',
      })
    }

    if (maintenancePlansEnabled) {
      let icon = <FontAwesomeIcon icon={faOctagonExclamation} />
      let header = 'Not a Maintenance Plan Member'
      let body = 'This is a membership opportunity.'
      let actions = <Link onClick={openMaintenancePlanWizard}>Sell a Plan</Link>
      let accentColor: string | undefined =
        tailwindConfig.theme.extend.colors.bz.fill
      let theme: OnsitePageSimpleSectionItemTheme = 'muted'

      if (maintenancePlan) {
        icon = (
          <FontAwesomeIcon
            icon={faScrewdriverWrench}
            style={{
              color:
                maintenancePlan.maintenancePlanDefinition?.flare
                  ?.primaryColorHex,
            }}
          />
        )
        header = `${
          maintenancePlan.maintenancePlanDefinition?.marketingInfo?.name ??
          'Plan'
        } Member`
        body = ''
        actions = (
          <Link
            to={CalculatePaths.maintenancePlanDetails({
              maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
            })}
          >
            View Plan
          </Link>
        )
        accentColor =
          maintenancePlan.maintenancePlanDefinition?.flare?.primaryColorHex
        theme = 'default'
      }

      sections.push({
        content: (
          <OnsitePageSimpleSectionItem
            smallIconMode
            icon={icon}
            theme={theme}
            accentColor={accentColor}
            subContent={
              <div className="mt-1">
                <div>{body}</div>
                <div className="mt-1">{actions}</div>
              </div>
            }
          >
            {header}
          </OnsitePageSimpleSectionItem>
        ),
        verticalPaddingClassName: 'py-6',
      })
    }

    const jobChips = useChips({
      job,
      isJobConverted: !!job.isJobConverted,
      isMembershipSold: !!job.isMembershipSold,
      isJobWorkComplete: job.jobLifecycleStatus.isWorkComplete,
    })

    sections.push({
      content: (
        <div className="space-y-6">
          {jobTab && (
            <BasicSection title="Job Pipeline Status">
              <div className="flex flex-row items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-full"
                  style={{ backgroundColor: jobLifecycleStatus.color }}
                />
                <div>{jobLifecycleStatus.name}</div>
              </div>
            </BasicSection>
          )}
          <BasicSection title="Job Summary">
            {summary || FormattedEmDash}
          </BasicSection>
          {!jobTab && (
            <BasicSection title="Visit Description">
              {description || FormattedEmDash}
            </BasicSection>
          )}
          <BasicSection title={equipmentTitle}>
            {equipment.length
              ? equipment.map(
                  ({
                    equipmentType,
                    estimatedEquipmentAge,
                    equipmentTypeLinkGuid,
                  }) => (
                    <ItemIconActionRow
                      key={equipmentTypeLinkGuid}
                      squareIcon
                      smallIconMode
                      icon={<FontAwesomeIcon icon={faAirConditioner} />}
                      subContent={`(${estimatedEquipmentAge ?? 'Unknown age'})`}
                    >
                      {formatEquipmentType(equipmentType)}
                    </ItemIconActionRow>
                  ),
                )
              : FormattedEmDash}
          </BasicSection>
          <BasicSection title="Team">
            <div className="space-y-3">
              {filteredAssignments.map(assignment => (
                <Assignment
                  key={assignment.assignmentGuid}
                  assignment={assignment}
                  canceled={appointmentDetails.cancellationStatus?.canceled}
                />
              ))}
            </div>
          </BasicSection>
          <BasicSection title="Job Tags & Indicators">
            <div className="space-y-2">
              {((maintenancePlansEnabled && maintenancePlan) ||
                tags.length > 0) && (
                <div className="flex flex-row flex-wrap gap-2">
                  {maintenancePlansEnabled && maintenancePlan && (
                    <DetailChip
                      accentColor={maintenancePlanColor}
                      icon={
                        <FontAwesomeIcon
                          icon={faScrewdriverWrench}
                          style={{ color: maintenancePlanColor }}
                        />
                      }
                    >
                      {maintenancePlan.maintenancePlanDefinition?.marketingInfo
                        ?.name ?? 'Maintenance Plan'}
                    </DetailChip>
                  )}
                  {tags.map(({ tag }) => (
                    <Tag key={tag.name} tag={tag} tagStyleVersion="v2" />
                  ))}
                </div>
              )}
              {jobChips.length ? (
                <div className="flex flex-row flex-wrap gap-y-2">
                  {jobChips.map((chip, i) => (
                    <React.Fragment key={i}>{chip}</React.Fragment>
                  ))}
                </div>
              ) : null}
            </div>
          </BasicSection>
        </div>
      ),
      verticalPaddingClassName: 'pt-6',
    })

    return (
      <>
        <SectionedContent dashed sections={sections} />
        {serviceHistoryModalOpen && (
          <ServiceHistoryModal
            appointmentGuid={appointmentDetails.appointmentGuid}
            locationGuid={location.locationGuid}
            jobGuid={job.jobGuid}
            onClose={closeServiceHistoryModal}
          />
        )}
      </>
    )
  },
)
