import {
  BzDateFns,
  bzExpect,
  Guid,
  isNullish,
  IsoDateString,
  R,
} from '@breezy/shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSubscription } from 'urql'
import { trpc } from '../../../../hooks/trpc'
import {
  useExpectedCompanyTimeZoneId,
  useExpectedUserGuid,
} from '../../../../providers/PrincipalUser'
import { CURRENT_ASSIGNMENT_TIMESHEET_ENTRIES_QUERY } from './AppointmentDetail.gql'

export const useClockIn = (jobAppointmentAssignmentGuid?: Guid) => {
  const userGuid = useExpectedUserGuid()
  const tzId = useExpectedCompanyTimeZoneId()

  const [res] = useSubscription({
    query: CURRENT_ASSIGNMENT_TIMESHEET_ENTRIES_QUERY,
    variables: {
      userGuid,
      jobAppointmentAssignmentGuid: jobAppointmentAssignmentGuid ?? '',
    },
    pause: !jobAppointmentAssignmentGuid,
  })

  const updatePauseResumeStatus =
    trpc.assignments['assignments:update-pause-resume-status'].useMutation()

  const dataIsPaused = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return false
    }

    return !['DRIVE-TIME', 'ON-SITE'].includes(
      res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
        .timesheetEntryActivity?.activityName ?? '',
    )
  }, [res.data])

  const [localIsPaused, setLocalIsPaused] = useState<boolean>()

  useEffect(() => {
    setLocalIsPaused(undefined)
  }, [dataIsPaused])

  const isPaused = localIsPaused ?? dataIsPaused

  const startTime = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return undefined
    }

    return BzDateFns.format(
      BzDateFns.parseISO(
        bzExpect(res.data?.timesheetEntries[0].finalStartTime),
        tzId,
      ),
      'h:mm a',
    )
  }, [res.data, tzId])

  const endTime = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return null
    }

    const { timesheetEntries } = res.data

    if (
      isNullish(
        res.data?.timesheetEntries[timesheetEntries.length - 1].finalEndTime,
      )
    ) {
      return null
    }

    return BzDateFns.format(
      BzDateFns.parseISO(
        res.data.timesheetEntries[timesheetEntries.length - 1]
          .finalEndTime as IsoDateString,
        tzId,
      ),
      'h:mm a',
    )
  }, [res.data, tzId])

  const startingElapsedTime: number = useMemo(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return 0
    }

    let time = res.data.timesheetEntries
      .filter(entry => entry.timesheetEntryActivity?.isPayable ?? false)
      .reduce<number>(
        (acc, entry) => acc + (entry.entryLengthInMinutes ?? 0),
        0,
      )

    if (isNullish(endTime) && !isPaused) {
      const now = BzDateFns.now(tzId)
      const lastEntryStartTime = BzDateFns.parseISO(
        bzExpect(
          res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
            .finalStartTime,
        ),
        tzId,
      )
      time += Math.abs(BzDateFns.differenceInMinutes(now, lastEntryStartTime))
    }

    return time
  }, [endTime, isPaused, res.data, tzId])

  const [localElapsedTime, setLocalElapsedTime] = useState(0)

  useEffect(() => {
    setLocalElapsedTime(startingElapsedTime)
  }, [startingElapsedTime])

  useEffect(() => {
    if (isPaused) {
      return
    }

    const intervalId = setInterval(() => {
      setLocalElapsedTime(prev => prev + 1)
    }, 1000 * 60)

    return () => clearInterval(intervalId)
  }, [isPaused])

  const clockInMutation =
    trpc.timesheets['timesheets:update-time-clock-status'].useMutation()

  const toggleClock = useCallback(
    () => clockInMutation.mutate({}),
    [clockInMutation],
  )

  const elapsed = useMemo(
    () =>
      `${
        Math.floor(localElapsedTime / 60) > 0
          ? `${Math.floor(localElapsedTime / 60)} h `
          : ''
      }${localElapsedTime % 60} min`,
    [localElapsedTime],
  )

  const togglePaused = useCallback(() => {
    if (isNullish(res.data) || res.data.timesheetEntries.length === 0) {
      return
    }
    setLocalIsPaused(R.not)

    const assignment =
      res.data.timesheetEntries[res.data.timesheetEntries.length - 1]
        .timesheetEntryLinkData[0]

    updatePauseResumeStatus.mutate({
      jobGuid: bzExpect(assignment.jobGuid),
      jobAppointmentGuid: bzExpect(assignment.jobAppointmentGuid),
      jobAppointmentAssignmentGuid: bzExpect(
        assignment.jobAppointmentAssignmentGuid,
      ),
    })
  }, [res.data, updatePauseResumeStatus])

  return {
    clockIn: toggleClock,
    clockOut: toggleClock,
    startTime,
    endTime,
    isPaused,
    isRunning: startTime && !isPaused,
    elapsed,
    togglePaused,
  }
}
