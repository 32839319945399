import {
  convertQueryToReviewTypeIdMap,
  ReviewIdConfig,
  ReviewType,
  ReviewTypeMetadata,
} from '@breezy/shared'
import { useMemo, useState } from 'react'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../../adam-components/OnsiteModal/OnsiteModal'
import { QRCode } from '../../../../adam-components/QrCode/QrCode'
import BzSelect from '../../../../elements/BzSelect/BzSelect'

type ReviewQRCodeModalProps = {
  defaultReviewType: ReviewType | undefined
  reviewIdConfig: ReviewIdConfig | undefined
  onClose: () => void
}

export const ReviewQRCodeModal: React.FC<ReviewQRCodeModalProps> = ({
  defaultReviewType = 'GOOGLE',
  reviewIdConfig,
  onClose,
}) => {
  const [selectedReviewType, setSelectedReviewType] = useState<
    ReviewType | undefined
  >(defaultReviewType)

  const reviewConfigs = useMemo(() => {
    const endOfVisitReminderTypeIdMap =
      convertQueryToReviewTypeIdMap(reviewIdConfig)
    const enabledReviewTypes = Object.entries(endOfVisitReminderTypeIdMap)
      .filter(([, id]) => !!id)
      .map(([type]) => type as ReviewType)

    return enabledReviewTypes.map(type => {
      const metadata = ReviewTypeMetadata[type]
      const reviewUrl = metadata.reviewLinkFn(
        endOfVisitReminderTypeIdMap[type]!,
      )

      return {
        type,
        url: reviewUrl,
        ...metadata,
      }
    })
  }, [reviewIdConfig])

  const reviewSiteOptions = useMemo(() => {
    return reviewConfigs.map(config => ({
      value: config.type,
      label: (
        <div className="flex w-full flex-row items-center gap-2 p-4">
          <img
            src={ReviewTypeMetadata[config.type].logoUrl}
            alt={config.displayName}
            className="h-4 w-4"
          />
          <span className="text-base">{config.displayName}</span>
        </div>
      ),
    }))
  }, [reviewConfigs])

  const selectedConfig = useMemo(() => {
    if (!selectedReviewType) return undefined
    return reviewConfigs.find(config => config.type === selectedReviewType)
  }, [reviewConfigs, selectedReviewType])

  if (reviewConfigs.length === 0) {
    return (
      <div className="text-center">
        No review link configuration found or enabled.
      </div>
    )
  }

  return (
    <OnsiteModal open={true} onClose={onClose} size="large">
      <OnsiteModalContent onClose={onClose}>
        <div className="flex flex-col items-center gap-4">
          {selectedConfig && (
            <>
              <QRCode
                url={selectedConfig.url}
                size={250}
                fullsizeEnlargeMultiplier={1.5}
                title={`${selectedConfig.displayName} Review QR Code`}
                showUrlInLightbox
                ddActionName="bz-review-qr-code-expanded"
              />
              <div className="text-center text-sm text-bz-gray-700">
                Scan this QR code to leave a review on{' '}
                {selectedConfig.fullReviewLinkDisplayName}
              </div>
            </>
          )}
          <BzSelect
            className="mb-6 w-full"
            title="Review Site"
            size="large"
            sheetSize="half"
            onChange={(value: ReviewType) => setSelectedReviewType(value)}
            value={selectedReviewType}
            popupMatchSelectWidth={false}
            options={reviewSiteOptions.map(option => ({
              value: option.value,
              label: ReviewTypeMetadata[option.value].displayName,
              customNode: option.label,
            }))}
          />
        </div>
      </OnsiteModalContent>
    </OnsiteModal>
  )
}
