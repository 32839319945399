import {
  BzDateFns,
  calculateInvoiceTotals,
  CalculatePaths,
  CommonDiscountUsc,
  formatUsc,
  toPlural,
  uscMultiply,
} from '@breezy/shared'
import { faEdit, faEye, faPhone } from '@fortawesome/pro-regular-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import { EmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import StatusTag from '../../elements/StatusTag/StatusTag'
import useIsMobile from '../../hooks/useIsMobile'
import { Carousel } from '../../pages/EstimatesFlow/components/Carousel'
import {
  DEFAULT_ESTIMATE_OPTION_NAME,
  getEstimateV2StatusDisplayInfo,
} from '../../pages/EstimatesFlow/estimatesFlowUtils'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { LoadingSpinner } from '../LoadingSpinner'
import { EstimatesWidgetEstimate } from './EstimatesWidget.gql'

const MAX_LINE_ITEMS = 4

const CAROUSEL_ITEM_WIDTH = 290

type CarouselHeaderProps = {
  dots: React.ReactNode
  numOptions: number
}

const CarouselHeader = React.memo<CarouselHeaderProps>(
  ({ dots, numOptions }) => {
    const isMobile = useIsMobile()
    return (
      <div
        className={classNames(
          'mx-auto mb-2 flex flex-1 flex-row items-center',
          isMobile ? 'px-4' : 'px-6',
        )}
      >
        <div className="mr-4 flex-1 font-semibold">Options ({numOptions})</div>
        {dots}
      </div>
    )
  },
)

type EstimateOptionProps = EstimatesWidgetEstimate['options'][0]

const EstimateOption = React.memo<EstimateOptionProps>(
  ({
    isSelected,
    isRecommended,
    seq,
    displayName,
    cartItems,
    totalUsc,
    discounts,
  }) => {
    let borderColorClassName = 'border-bz-border'
    if (isSelected) {
      borderColorClassName = 'border-bz-green-700'
    }

    const showRecTag = isRecommended && !isSelected

    const discountAmountUsc = useMemo(() => {
      // NOTE: these are not the ACTUAL totals. We're just trying to get the discount amount, so tax information is
      // spoofed and those numbers (and all the other totals) are unreliable.
      const invoiceTotals = calculateInvoiceTotals(
        cartItems.map(({ cartItem }) => ({ ...cartItem, isTaxable: false })),
        0,
        // The discriminated union isn't reflected by the graphql query
        discounts as CommonDiscountUsc[],
        [],
        undefined,
      )

      return invoiceTotals.discountAmountUsc
    }, [cartItems, discounts])
    return (
      <div
        className={classNames(
          'flex h-full flex-col rounded-lg border border-solid p-3 text-bz-text-secondary',
          borderColorClassName,
          {
            'bg-bz-green-100': isSelected,
          },
        )}
      >
        <div className="flex-1">
          <div
            className={classNames(
              'grid w-full min-w-0 max-w-full grid-cols-[1fr_auto] gap-x-1 gap-y-0.5',
            )}
          >
            <div
              className={classNames('font-semibold', {
                'col-span-2': !showRecTag,
              })}
            >
              #{seq + 1}: {displayName || DEFAULT_ESTIMATE_OPTION_NAME}
              {isSelected && (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="ml-2 text-bz-green-700"
                />
              )}
            </div>
            {showRecTag && (
              <div className="text-right">
                <StatusTag color="blue" text="Rec." />
              </div>
            )}
            {cartItems
              .slice(0, MAX_LINE_ITEMS)
              .map(({ cartItem: { name, quantity, unitPriceUsc } }, i) => (
                <React.Fragment key={`${name}_${i}`}>
                  <div className="truncate">{name}</div>
                  <div className="text-right">
                    {formatUsc(uscMultiply(quantity, unitPriceUsc))}
                  </div>
                </React.Fragment>
              ))}
            {cartItems.length > MAX_LINE_ITEMS && (
              <>
                <div>
                  {cartItems.length - MAX_LINE_ITEMS} more{' '}
                  {toPlural(cartItems.length - MAX_LINE_ITEMS, 'item')}
                  ...
                </div>
                <div className="text-right">...</div>
              </>
            )}
            {discountAmountUsc > 0 && (
              <>
                <div className="text-bz-green-800">Discount</div>
                <div className="text-right text-bz-green-800">
                  -{formatUsc(discountAmountUsc)}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-0.5 grid w-full min-w-0 max-w-full grid-cols-[1fr_auto] gap-x-1">
          <div className="font-semibold">Total w/ Tax</div>
          <div className="font-semibold">{formatUsc(totalUsc)}</div>
        </div>
      </div>
    )
  },
)

type BaseEstimateWidgetProps = {
  readonly?: boolean
  includeContextMetadata?: boolean
  includeCallButton?: boolean
  includePresentButton?: boolean
}

type EstimateProps = EstimatesWidgetEstimate & BaseEstimateWidgetProps

const Estimate = React.memo<EstimateProps>(
  ({
    includeContextMetadata,
    includeCallButton,
    includePresentButton,
    status,
    options,
    estimateGuid,
    displayId,
    createdAt,
    createdByUser,
    readonly,
    job,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const navigate = useNavigate()
    const isMobile = useIsMobile()

    const { account, pointOfContact, location } = job
    const { accountGuid, accountDisplayName } = account
    const { address, locationGuid } = location

    const renderCarouselHeader = useCallback(
      (dots: React.ReactNode) => {
        return <CarouselHeader dots={dots} numOptions={options.length} />
      },
      [options.length],
    )

    const statusInfo = getEstimateV2StatusDisplayInfo(status)

    const margin = isMobile ? 16 : 24

    const actionsSection = useMemo<SectionedSection | undefined>(() => {
      if (readonly) {
        return undefined
      }
      const items: React.ReactNode[] = []

      const phoneNumber = pointOfContact.primaryPhoneNumber?.phoneNumber

      if (includeCallButton && phoneNumber) {
        items.push(
          <Button
            size="large"
            icon={<FontAwesomeIcon icon={faPhone} />}
            href={`tel:${phoneNumber.replace(/[^0-9]/g, '')}`}
          >
            Call
          </Button>,
        )
      }

      if (
        includePresentButton &&
        ['CREATED', 'SENT', 'REVIEWED'].includes(status)
      ) {
        items.push(
          <Button
            key="present"
            size="large"
            icon={<FontAwesomeIcon icon={faEye} />}
            onClick={() => {
              navigate(
                CalculatePaths.estimatesDetails({
                  estimateGuid,
                  present: true,
                }),
              )
            }}
          >
            Present
          </Button>,
        )
      }

      if (!['VOIDED', 'CLOSED', 'EXPIRED'].includes(status)) {
        items.push(
          <Button
            key="edit"
            size="large"
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() =>
              navigate(CalculatePaths.estimatesEdit({ estimateGuid }))
            }
          >
            {status === 'ACCEPTED' ? 'Revise' : 'Edit'}
          </Button>,
        )
      }

      if (items.length) {
        return {
          verticalPaddingClassName: 'pt-3 pb-4',
          content: (
            <div className="flex flex-row items-center space-x-2">{items}</div>
          ),
        }
      }
    }, [
      estimateGuid,
      includeCallButton,
      includePresentButton,
      navigate,
      pointOfContact.primaryPhoneNumber?.phoneNumber,
      readonly,
      status,
    ])

    const sections = useMemo(() => {
      let items: React.ReactNode[] = [
        'Created Date',
        createdAt ? (
          BzDateFns.format(BzDateFns.parseISO(createdAt, tzId), 'MMM d, yyyy')
        ) : (
          <span className="text-bz-text-tertiary">
            <EmDash />
          </span>
        ),
        'Prepared By',
        createdByUser.fullName,
      ]

      if (includeContextMetadata) {
        items = [
          'Account',
          <Link to={CalculatePaths.accountDetails({ accountGuid })}>
            {accountDisplayName}
          </Link>,
          'Location',
          <Link to={CalculatePaths.locationDetails({ locationGuid })}>
            {address.line1} ({address.zipCode})
          </Link>,
          'Contact',
          pointOfContact.fullName,
          ...items,
        ]
      }

      const sections: SectionedSection[] = [
        {
          verticalPaddingClassName: 'pb-2.5 pt-4',
          content: (
            <div className="flex flex-row justify-between">
              <Link to={CalculatePaths.estimatesDetails({ estimateGuid })} bold>
                #{displayId}
              </Link>
              <StatusTag
                color={statusInfo.statusTagColor}
                text={statusInfo.label}
              />
            </div>
          ),
        },
        {
          verticalPaddingClassName: 'py-3',
          content: (
            <div>
              <LabeledItemGrid items={items} />
              {options.length > 1 ? (
                <div
                  style={{
                    margin: `10px -${margin}px 0 -${margin}px`,
                  }}
                >
                  <Carousel
                    renderHeaderWithDots={renderCarouselHeader}
                    margin={margin}
                    mistyMargin={margin}
                  >
                    {options.map(option => (
                      <div
                        key={option.seq}
                        className="h-full"
                        style={{ width: `${CAROUSEL_ITEM_WIDTH}px` }}
                      >
                        <EstimateOption {...option} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="mt-2">
                  <EstimateOption {...options[0]} />
                </div>
              )}
            </div>
          ),
        },
      ]

      if (actionsSection) {
        sections.push(actionsSection)
      }

      return sections
    }, [
      accountDisplayName,
      accountGuid,
      actionsSection,
      address.line1,
      address.zipCode,
      createdAt,
      createdByUser.fullName,
      displayId,
      estimateGuid,
      includeContextMetadata,
      locationGuid,
      margin,
      options,
      pointOfContact.fullName,
      renderCarouselHeader,
      statusInfo.label,
      statusInfo.statusTagColor,
      tzId,
    ])

    return <SectionedCard key={estimateGuid} dashed sections={sections} />
  },
)

export type EstimatesWidgetProps = BaseEstimateWidgetProps & {
  estimates: EstimatesWidgetEstimate[]
  fetching?: boolean
}

export const EstimatesWidget = React.memo<EstimatesWidgetProps>(
  ({ estimates, fetching, ...passthrough }) => {
    if (fetching) {
      return <LoadingSpinner />
    }
    return (
      <div className="space-y-3">
        {estimates.map(estimate => (
          <Estimate
            key={estimate.estimateGuid}
            {...passthrough}
            {...estimate}
          />
        ))}
      </div>
    )
  },
)
