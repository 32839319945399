import { BzDateFns } from '@breezy/shared'
import { useMemo } from 'react'
import { useSubscription } from 'urql'
import { EstimatesBoolExp } from '../../generated/user/graphql'
import { postErrorAlertWithDD } from '../../utils/GraphqlAlerts'
import {
  ESTIMATES_WIDGET_DATA_SUBSCRIPTION,
  EstimatesWidgetEstimate,
} from './EstimatesWidget.gql'

type EstimateWidgetGuids = {
  jobGuid?: never
  accountGuid?: never
  locationGuid?: never
  openForUserGuid?: never
}

export type EstimateWidgetGuidsProp =
  | (Omit<EstimateWidgetGuids, 'jobGuid'> & { jobGuid: string })
  | (Omit<EstimateWidgetGuids, 'accountGuid'> & { accountGuid: string })
  | (Omit<EstimateWidgetGuids, 'locationGuid'> & { locationGuid: string })
  | (Omit<EstimateWidgetGuids, 'openForUserGuid'> & { openForUserGuid: string })

type UseEstimatesWidgetDataRes = {
  fetching: boolean
  estimates: EstimatesWidgetEstimate[]
}

export const useEstimatesWidgetData = ({
  jobGuid,
  accountGuid,
  locationGuid,
  openForUserGuid,
}: EstimateWidgetGuidsProp): UseEstimatesWidgetDataRes => {
  const where = useMemo<EstimatesBoolExp>(() => {
    if (jobGuid) {
      return { jobGuid: { _eq: jobGuid } }
    } else if (accountGuid) {
      return { job: { accountGuid: { _eq: accountGuid } } }
    } else if (locationGuid) {
      return { job: { locationGuid: { _eq: locationGuid } } }
    } else if (openForUserGuid) {
      return {
        createdBy: { _eq: openForUserGuid },
        createdAt: {
          _gte: BzDateFns.nowUtcTransform(date => BzDateFns.subDays(date, 90)),
        },
        status: { _in: ['CREATED', 'SENT', 'REVIEWED'] },
      }
    } else {
      const msg = 'No valid guid supplied to useEstimatesWidgetData'
      console.error(msg)
      postErrorAlertWithDD(msg)
      throw new Error(msg)
    }
  }, [accountGuid, jobGuid, locationGuid, openForUserGuid])
  const [{ fetching, data }] = useSubscription({
    query: ESTIMATES_WIDGET_DATA_SUBSCRIPTION,
    variables: {
      where,
    },
  })

  return useMemo(
    () => ({
      estimates: data?.estimates ?? [],
      fetching,
    }),
    [data?.estimates, fetching],
  )
}
