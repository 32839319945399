import {
  AssignmentStatus,
  BzDateFns,
  Guid,
  IsoDateString,
  JobGuid,
  R,
  bzExpect,
  isChecklistCompleted,
  isNullish,
  noOp,
} from '@breezy/shared'
import {
  faArrowRight,
  faChevronDown,
  faCircle,
  faCircleDashed,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCircleCheck,
  faPlay,
  faSquareX,
  faStop,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Progress } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'urql'
import { OnsiteMenuItem } from '../../../../../adam-components/OnsiteMenuItem'
import { OnsiteBasicModal } from '../../../../../adam-components/OnsiteModal/OnsiteModal'
import {
  BaseConfirmationModalProps,
  ConfirmationModal,
  useConfirmationModal,
} from '../../../../../adam-components/OnsiteModal/useConfirmationModal'
import {
  SideEffectConfirmModal,
  useSideEffectConfirmModal,
} from '../../../../../adam-components/OnsiteModal/useSideEffectConfirmModal'
import { OnsitePageSection } from '../../../../../adam-components/OnsitePage/OnsitePageSection'
import { SectionedCard } from '../../../../../adam-components/SectionedCard/SectionedCard'
import {
  SectionedContent,
  SectionedSection,
} from '../../../../../adam-components/SectionedCard/SectionedContent'
import { useReactHookFormSubmit } from '../../../../../elements/Forms/useReactHookFormSubmit'
import { Link } from '../../../../../elements/Link/Link'
import { JobAppointmentAssignmentStatusTypesEnum } from '../../../../../generated/user/graphql'
import { useIsEndOfAppointmentWorkflowEnabledForCompany } from '../../../../../hooks/fetch/useIsEndOfAppointmentWorkflowEnabledForCompany'
import { trpc } from '../../../../../hooks/trpc'
import useIsMobile from '../../../../../hooks/useIsMobile'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedUserGuid,
} from '../../../../../providers/PrincipalUser'
import tailwindConfig from '../../../../../tailwind.config'
import { useMessage } from '../../../../../utils/antd-utils'
import { useModalState } from '../../../../../utils/react-utils'
import { AppointmentChecklistInstanceForm } from '../../../../AppointmentChecklists/AppointmentChecklistInstanceForm'
import { BehindFeatureFlag } from '../../../../BehindFeatureFlag'
import { CancelAppointmentModal } from '../../../../CancelAppointmentModal/CancelAppointmentModal'
import { LoadingSpinner } from '../../../../LoadingSpinner'
import {
  AppointmentDetails,
  AppointmentDetailsAssignment,
} from '../AppointmentDetail.gql'
import { AppointmentDetailsChecklistInstance } from '../AppointmentDetailUtils'
import { UPDATE_END_OF_APPOINTMENT_NEXT_STEPS } from '../EndOfAppointmentNextStepsForm/EndOfAppointmentNextSteps.gql'
import {
  EndOfAppointmentNextStepsFormV1Inner,
  EndOfAppointmentNextStepsFormV2Inner,
  EndOfAppointmentNextStepsV1Data,
  EndOfAppointmentNextStepsV2Data,
} from '../EndOfAppointmentNextStepsForm/EndOfAppointmentNextStepsForm'
import { useClockIn } from '../useClockIn'

const TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX = new RegExp(
  /Technician already has an active assignment: ([0-9a-f-]+)/i,
)

const STATUSES = [
  {
    label: 'To Do',
    status: 'TO_DO',
  },
  {
    label: 'Driving',
    status: 'EN_ROUTE',
  },
  {
    label: 'Ongoing',
    status: 'IN_PROGRESS',
  },
  {
    label: 'Done',
    status: 'COMPLETED',
  },
] satisfies {
  label: string
  status: AssignmentStatus
}[]

const STATUS_TO_INDEX = {
  ...STATUSES.reduce(
    (acc, { status }, i) => ({
      ...acc,
      [status]: i,
    }),
    {} as Record<AssignmentStatus, number>,
  ),
  CANCELED: -1,
}

type ProgressStepsProps = {
  assignmentStatus: JobAppointmentAssignmentStatusTypesEnum
  assignmentStatusUpdatedAt?: IsoDateString
  appointmentCancellationStatus?: AppointmentDetails['cancellationStatus']
  withLabels?: boolean
}

export const ProgressSteps = React.memo<ProgressStepsProps>(
  ({
    assignmentStatus,
    assignmentStatusUpdatedAt,
    appointmentCancellationStatus,
    withLabels,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const currentStatusIndex = useMemo(
      () => STATUS_TO_INDEX[assignmentStatus],
      [assignmentStatus],
    )

    if (assignmentStatus === 'COMPLETED') {
      const statusChangeDate = BzDateFns.parseISO(
        // I know that `assignmentStatusUpdatedAt` is defined because that's the only way `assignmentStatus ===
        // "COMPLETED" could be true.
        assignmentStatusUpdatedAt!,
        tzId,
      )
      return (
        <div className="overflow-hidden">
          <Progress
            percent={100}
            strokeColor={tailwindConfig.theme.extend.colors['bz-green']['700']}
            showInfo={false}
            className="mb-0"
          />
          {withLabels && (
            <div className="text-center">
              Completed on{' '}
              {BzDateFns.format(statusChangeDate, 'MMM d @ h:mm a')}
            </div>
          )}
        </div>
      )
    } else if (
      appointmentCancellationStatus?.canceled ||
      assignmentStatus === 'CANCELED'
    ) {
      // It shouldn't be possible to have an assignment that's canceled. We have that in the data model kind of, but
      // we deprecated the concept and have no way to set it that way. Just to be safe, I consider it here. Note that
      // we do the "!" because we know that if `assignmentStatus === 'CANCELED'` then `assignment.assignmentStatus` is
      // defined.
      const cancelDate = BzDateFns.parseISO(
        appointmentCancellationStatus?.updatedAt ?? assignmentStatusUpdatedAt!,
        tzId,
      )
      return (
        <div className="overflow-hidden">
          <Progress
            percent={100}
            status="exception"
            showInfo={false}
            className="mb-0"
          />
          {withLabels && (
            <div className="text-center">
              Canceled on {BzDateFns.format(cancelDate, 'MMM d @ h:mm a')}
            </div>
          )}
        </div>
      )
    } else {
      return (
        <div className="flex flex-row items-center space-x-2 overflow-hidden *:min-w-0 *:flex-1 *:basis-0">
          {STATUSES.map(({ label, status }, i) => ({
            label,
            state:
              i < currentStatusIndex
                ? 'DONE'
                : i > currentStatusIndex || status === 'TO_DO'
                ? 'NOT_STARTED'
                : 'IN_PROGRESS',
          })).map(({ label, state }) => {
            let strokeColor = ''
            switch (state) {
              case 'DONE':
                strokeColor = tailwindConfig.theme.extend.colors.bz.primary
                break
              case 'IN_PROGRESS':
                strokeColor =
                  tailwindConfig.theme.extend.colors.bz['primary-bg-hover']
                break
              case 'NOT_STARTED':
                strokeColor =
                  tailwindConfig.theme.extend.colors.bz['fill-secondary']
                break
            }
            return (
              <div key={label}>
                <Progress
                  percent={100}
                  status={state === 'IN_PROGRESS' ? 'active' : 'success'}
                  showInfo={false}
                  strokeColor={strokeColor}
                  className="mb-1"
                />
                {withLabels && (
                  <div
                    className={classNames('text-center text-sm', {
                      'text-bz-text-tertiary': state !== 'DONE',
                    })}
                  >
                    {label}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  },
)

type CompleteFormProps = BaseConfirmationModalProps & {
  jobAppointmentGuid: Guid
  checklistInstances: AppointmentDetailsChecklistInstance[]
  endOfAppointmentEnabled: boolean
  pointOfContactFirstName: string
  pointOfContactPhoneNumber?: string
  pointOfContactEmailAddress?: string
}

const CompleteForm = React.memo<CompleteFormProps>(
  ({
    onConfirm,
    jobAppointmentGuid,
    checklistInstances,
    endOfAppointmentEnabled,
    onCancel,
    pointOfContactFirstName,
    pointOfContactPhoneNumber,
    pointOfContactEmailAddress,
    ...rest
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const userGuid = useExpectedUserGuid()
    const isMobile = useIsMobile()

    const hasChecklists = checklistInstances.length > 0

    const hasBothScreens = endOfAppointmentEnabled && hasChecklists

    const [onSecondScreen, setOnSecondScreen] = useState(false)

    const [checklistDataMap, setChecklistDataMap] = useState<
      Record<string, AppointmentDetailsChecklistInstance['items']>
    >(() => {
      const instanceMap = R.indexBy(
        instance => instance.instanceGuid,
        checklistInstances,
      )
      return R.mapObjIndexed(R.prop('items'), instanceMap)
    })

    const allChecklistsCompleted = useMemo(
      () => R.all(isChecklistCompleted, R.values(checklistDataMap)),
      [checklistDataMap],
    )

    const [endOfApptSubmitElement, triggerEndOfApptSubmit] =
      useReactHookFormSubmit()

    const [{ fetching: endOfApptLoading }, updateEndOfAppt] = useMutation(
      UPDATE_END_OF_APPOINTMENT_NEXT_STEPS,
    )

    const upsertChecklistInstancesMutation =
      trpc.appointmentChecklist[
        'appointment-checklists:upsert-instances'
      ].useMutation()

    const updateEndOfApptMutation =
      trpc.appointmentChecklist[
        'appointment-checklists:end-of-visit-next-steps:upsert'
      ].useMutation()

    const upsertChecklistInstances = useCallback(
      () =>
        upsertChecklistInstancesMutation.mutateAsync(
          checklistInstances.map(({ name, instanceGuid, checklistGuid }) => ({
            jobAppointmentGuid,
            appointmentChecklistInstanceGuid: instanceGuid,
            appointmentChecklistGuid: checklistGuid,
            checklist: {
              name,
              items: checklistDataMap[instanceGuid],
            },
            lastEditorUserGuid: userGuid,
          })),
        ),
      [
        checklistDataMap,
        checklistInstances,
        jobAppointmentGuid,
        upsertChecklistInstancesMutation,
        userGuid,
      ],
    )

    const onEndOfApptSubmitV1 = useCallback(
      async (data: EndOfAppointmentNextStepsV1Data) => {
        const promises: Promise<unknown>[] = []
        if (hasChecklists) {
          promises.push(upsertChecklistInstances())
        }
        promises.push(
          updateEndOfAppt({
            companyGuid,
            jobAppointmentGuid,
            endOfAppointmentNextSteps: {
              version: 'v1',
              data,
            },
          }),
        )

        await Promise.all(promises)
        onConfirm()
      },
      [
        companyGuid,
        hasChecklists,
        jobAppointmentGuid,
        onConfirm,
        updateEndOfAppt,
        upsertChecklistInstances,
      ],
    )

    const onEndOfApptSubmitV2 = useCallback(
      async (data: EndOfAppointmentNextStepsV2Data) => {
        const promises: Promise<unknown>[] = []
        if (hasChecklists) {
          promises.push(upsertChecklistInstances())
        }
        promises.push(
          updateEndOfApptMutation.mutateAsync({
            jobAppointmentGuid,
            endOfAppointmentNextSteps: {
              version: 'v2',
              data,
            },
          }),
        )

        await Promise.all(promises)
        onConfirm()
      },
      [
        hasChecklists,
        jobAppointmentGuid,
        onConfirm,
        updateEndOfApptMutation,
        upsertChecklistInstances,
      ],
    )

    const onChecklistOnlySubmit = useCallback(() => {
      upsertChecklistInstances()
      onConfirm()
    }, [onConfirm, upsertChecklistInstances])

    const endOfAppointmentForm = useMemo(
      () =>
        endOfAppointmentEnabled ? (
          <div className={classNames(isMobile ? 'px-4' : 'px-6')}>
            <BehindFeatureFlag
              enabledFeatureFlag="end-of-visit-review"
              render={
                <EndOfAppointmentNextStepsFormV2Inner
                  submitElement={endOfApptSubmitElement}
                  onSubmit={onEndOfApptSubmitV2}
                  customerFirstName={pointOfContactFirstName}
                  customerPhoneNumber={pointOfContactPhoneNumber}
                  customerEmailAddress={pointOfContactEmailAddress}
                />
              }
              fallback={
                <EndOfAppointmentNextStepsFormV1Inner
                  submitElement={endOfApptSubmitElement}
                  onSubmit={onEndOfApptSubmitV1}
                />
              }
            />
          </div>
        ) : undefined,
      [
        endOfAppointmentEnabled,
        endOfApptSubmitElement,
        isMobile,
        onEndOfApptSubmitV1,
        onEndOfApptSubmitV2,
        pointOfContactEmailAddress,
        pointOfContactFirstName,
        pointOfContactPhoneNumber,
      ],
    )

    const checklistsForm = useMemo(
      () =>
        hasChecklists
          ? checklistInstances.map(({ name, instanceGuid }) => (
              <OnsitePageSection key={instanceGuid} title={name}>
                <AppointmentChecklistInstanceForm
                  items={checklistDataMap[instanceGuid]}
                  setItems={items =>
                    setChecklistDataMap(
                      R.mergeDeepLeft({
                        [instanceGuid]: items,
                      }),
                    )
                  }
                />
              </OnsitePageSection>
            ))
          : undefined,
      [checklistDataMap, checklistInstances, hasChecklists],
    )

    const onBack = useMemo(() => {
      if (hasBothScreens && onSecondScreen) {
        return () => setOnSecondScreen(false)
      }
    }, [hasBothScreens, onSecondScreen])

    const [confirmText, ourOnConfirm, confirmDisabled] = useMemo<
      [string, () => void, boolean]
    >(() => {
      const confirmDisabled = hasChecklists && !allChecklistsCompleted
      // If they have both screens and they aren't on the second one, that means they are on the checklist screen and
      // need to go next.
      if (hasBothScreens && !onSecondScreen) {
        return ['Next', () => setOnSecondScreen(true), confirmDisabled]
      }
      // At this point, either they don't have two screens or they're on the second screen. So if they only have one
      // screen and they have checklists, that means the one screen they have is the checklists screen. Otherwise it's
      // the end of appointment screen. The label and disabled state are the same in both, so just use the end of
      // appointment one unless it meets that checklists (and only checklists) condition.
      let onConfirm = triggerEndOfApptSubmit
      if (hasChecklists && !hasBothScreens) {
        onConfirm = onChecklistOnlySubmit
      }
      return ['Complete', onConfirm, confirmDisabled]
    }, [
      allChecklistsCompleted,
      hasBothScreens,
      hasChecklists,
      onChecklistOnlySubmit,
      onSecondScreen,
      triggerEndOfApptSubmit,
    ])

    const isLoading =
      endOfApptLoading || upsertChecklistInstancesMutation.isLoading

    const screen = useMemo(() => {
      if (isLoading) {
        return <LoadingSpinner />
      } else if (hasBothScreens) {
        return onSecondScreen ? endOfAppointmentForm : checklistsForm
      } else if (hasChecklists) {
        return checklistsForm
      } else {
        return endOfAppointmentForm
      }
    }, [
      checklistsForm,
      endOfAppointmentForm,
      hasBothScreens,
      hasChecklists,
      isLoading,
      onSecondScreen,
    ])

    const modalHeader = useMemo(() => {
      if (isLoading) {
        return 'Visit checklist'
      } else if (hasBothScreens) {
        return onSecondScreen ? 'Next Steps' : 'Visit checklist'
      } else if (hasChecklists) {
        return 'Visit checklist'
      } else {
        return 'Next Steps'
      }
    }, [hasBothScreens, hasChecklists, isLoading, onSecondScreen])

    return (
      <ConfirmationModal
        {...rest}
        size="full"
        onConfirm={ourOnConfirm}
        onCancel={onCancel}
        onBack={onBack}
        header={modalHeader}
        confirmText={confirmText}
        confirmDisabled={confirmDisabled}
        loading={isLoading}
      >
        <div className={classNames(isMobile ? 'mx-[-16px]' : 'mx-[-24px]')}>
          {screen}
        </div>
      </ConfirmationModal>
    )
  },
)

type AssignmentStatusStepsProps = {
  assignment?: AppointmentDetailsAssignment
  appointmentCancellationStatus?: AppointmentDetails['cancellationStatus']
  apptGuid: string
  jobGuid: JobGuid
  pointOfContactFullName: string
  pointOfContactFirstName: string
  pointOfContactPhoneNumber?: string
  pointOfContactEmailAddress?: string
  onMutate?: () => void
  checklistInstances: AppointmentDetailsChecklistInstance[]
}

export const AssignmentStatusSteps = React.memo<AssignmentStatusStepsProps>(
  ({
    assignment,
    appointmentCancellationStatus,
    apptGuid,
    jobGuid,
    pointOfContactFullName,
    pointOfContactFirstName,
    pointOfContactPhoneNumber,
    pointOfContactEmailAddress,
    onMutate = noOp,
    checklistInstances,
  }) => {
    const message = useMessage()
    const isEndOfAppointmentWorkflowEnabledForCompany =
      useIsEndOfAppointmentWorkflowEnabledForCompany()
    const tzId = useExpectedCompanyTimeZoneId()
    const navigate = useNavigate()

    const [localAssignmentStatus, setLocalAssignmentStatus] =
      useState<AssignmentStatus>()

    const dataAssignmentStatus = useMemo(
      () =>
        appointmentCancellationStatus?.canceled
          ? 'CANCELED'
          : assignment?.assignmentStatus?.status ?? 'TO_DO',
      [
        appointmentCancellationStatus?.canceled,
        assignment?.assignmentStatus?.status,
      ],
    )

    const assignmentStatus = localAssignmentStatus ?? dataAssignmentStatus

    useEffect(() => {
      setLocalAssignmentStatus(undefined)
    }, [dataAssignmentStatus])

    const [changeStatusOpen, openChangeStatus, closeChangeStatus] =
      useModalState()

    const updateAssignmentStatusMutation =
      trpc.assignments['assignments:update-status'].useMutation()

    const {
      clockIn,
      clockOut,
      startTime: clockStartTime,
      endTime: clockEndTime,
      elapsed: clockElapsed,
      isRunning: clockIsRunning,
      isPaused: clockIsPaused,
      togglePaused: toggleClockPaused,
    } = useClockIn(assignment?.assignmentGuid)

    const otherActiveAssignmentGuid = useMemo(
      () => assignment?.technician.assignments[0]?.appointmentGuid,
      [assignment?.technician.assignments],
    )
    const principalHasAnotherActiveAssignment = !!otherActiveAssignmentGuid

    const [withNotifyCustomer, notifyCustomerProps] =
      useSideEffectConfirmModal()

    const [withCompleteForm, completeFormProps] = useConfirmationModal()

    // When completing, open the thing that asks all the questions. Stop timer. Show toast
    const updateAssignmentStatus = useCallback(
      (from: AssignmentStatus, to: AssignmentStatus) => {
        const starting = from === 'TO_DO' && to === 'EN_ROUTE'
        const completing = to === 'COMPLETED'

        const runUpdate = (notifyCustomer?: boolean) => {
          setLocalAssignmentStatus(to)
          updateAssignmentStatusMutation.mutate(
            {
              apptAssignmentGuid: bzExpect(assignment?.assignmentGuid),
              assignmentStatus: to,
              jobGuid,
              suppressEnRouteAccountNotification: isNullish(notifyCustomer)
                ? undefined
                : !notifyCustomer,

              apptGuid,
            },
            {
              onSuccess: () => {
                if (notifyCustomer) {
                  message.success('Homeowner has been notified.')
                }
                if (completing) {
                  message.success('Visit completed!')
                }
                onMutate()
              },
              onError: e => {
                if (
                  e?.shape?.message &&
                  e.shape.message.match(
                    TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX,
                  )
                ) {
                  const conflictingAppointmentGuid = e.shape.message.match(
                    TECHNICIAN_ALREADY_HAS_ACTIVE_ASSIGNMENT_REGEX,
                  )?.[1]
                  message.error(
                    <span>
                      Cannot update this assignment status because the
                      technician already has{' '}
                      <Link to={`/appointments/${conflictingAppointmentGuid}`}>
                        an active assignment
                      </Link>
                    </span>,
                  )
                } else {
                  message.error('Failed to update assignment status')
                }
              },
              onSettled: closeChangeStatus,
            },
          )
        }

        if (starting && !clockEndTime) {
          clockIn()
        }

        if (completing) {
          clockOut()
        }

        if (to === 'TO_DO' && clockIsRunning && !clockEndTime) {
          toggleClockPaused()
        }

        if (starting) {
          withNotifyCustomer(runUpdate)
        } else if (
          completing &&
          (isEndOfAppointmentWorkflowEnabledForCompany ||
            checklistInstances.length)
        ) {
          withCompleteForm(runUpdate)
        } else {
          runUpdate()
        }
      },
      [
        clockEndTime,
        clockIsRunning,
        isEndOfAppointmentWorkflowEnabledForCompany,
        checklistInstances.length,
        updateAssignmentStatusMutation,
        assignment?.assignmentGuid,
        jobGuid,
        apptGuid,
        closeChangeStatus,
        onMutate,
        message,
        clockIn,
        clockOut,
        toggleClockPaused,
        withNotifyCustomer,
        withCompleteForm,
      ],
    )

    const onNextStatusClick = useCallback(() => {
      const nextStatus = STATUSES[STATUS_TO_INDEX[assignmentStatus] + 1]
      if (nextStatus) {
        updateAssignmentStatus(assignmentStatus, nextStatus.status)
      } else {
        openChangeStatus()
      }
    }, [assignmentStatus, openChangeStatus, updateAssignmentStatus])

    const dropdownItems = useMemo(() => {
      return STATUSES.map(({ label, status }) => {
        const isSelected = status === assignmentStatus
        let icon = <FontAwesomeIcon icon={faCircleDashed} />
        let iconCircleColorClassName: string | undefined = undefined

        if (isSelected) {
          icon = <FontAwesomeIcon icon={faCircle} />
        }
        if (status === 'COMPLETED') {
          icon = (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-bz-green-800"
            />
          )
          iconCircleColorClassName = 'bg-bz-green-200'
        }
        return (
          <OnsiteMenuItem
            key={status}
            disabled={isSelected}
            label={label}
            onClick={() => updateAssignmentStatus(assignmentStatus, status)}
            icon={icon}
            iconCircleColorClassName={iconCircleColorClassName}
          />
        )
      })
    }, [assignmentStatus, updateAssignmentStatus])

    const currentStatusIndex = useMemo(
      () => STATUS_TO_INDEX[assignmentStatus],
      [assignmentStatus],
    )

    const changeStatusSectionContent = useMemo(() => {
      const isTerminalStatus =
        assignmentStatus === 'COMPLETED' || assignmentStatus === 'CANCELED'

      if (assignment) {
        const showClockButton = clockStartTime && !clockEndTime
        return (
          <div className="flex flex-row items-center space-x-2">
            {/* We want it to look exactly like the dropdown button, but it opens a custom modal instead of the
                dropdown. So we have to hack it a bit. */}
            <Dropdown.Button
              className="flex flex-1 flex-row"
              buttonsRender={([firstButton, secondButton]) => [
                React.cloneElement(
                  firstButton as React.ReactElement<
                    React.ComponentProps<typeof Button>,
                    string
                  >,
                  { className: 'flex-1', onClick: onNextStatusClick },
                ),
                React.cloneElement(
                  secondButton as React.ReactElement<
                    React.ComponentProps<typeof Button>,
                    string
                  >,
                  {
                    onClick: openChangeStatus,
                  },
                ),
              ]}
              disabled={principalHasAnotherActiveAssignment}
              size="large"
              type={isTerminalStatus ? undefined : 'primary'}
              icon={<FontAwesomeIcon icon={faChevronDown} />}
              // So clicking doesn't trigger the built-in dropdown
              trigger={[]}
            >
              {isTerminalStatus
                ? 'Change Status'
                : `Mark as ${STATUSES[currentStatusIndex + 1].label}`}
            </Dropdown.Button>
            <div
              className={classNames(
                'overflow-hidden transition-all',
                showClockButton ? 'max-w-full' : 'max-w-0',
              )}
            >
              <Button
                size="large"
                icon={
                  <FontAwesomeIcon icon={clockIsRunning ? faStop : faPlay} />
                }
                disabled={principalHasAnotherActiveAssignment}
                onClick={toggleClockPaused}
              >
                {!clockStartTime ? 'Timer' : clockIsPaused ? 'Start' : 'Stop'}
              </Button>
            </div>
          </div>
        )
      }
      return undefined
    }, [
      assignment,
      assignmentStatus,
      clockEndTime,
      clockIsPaused,
      clockIsRunning,
      clockStartTime,
      currentStatusIndex,
      onNextStatusClick,
      openChangeStatus,
      principalHasAnotherActiveAssignment,
      toggleClockPaused,
    ])

    const clockSectionContent = useMemo(() => {
      if (clockStartTime) {
        return (
          <div className="space-x-[6px] text-center">
            <span className="text-bz-text-tertiary">
              {clockEndTime ? 'Timer' : 'Started'}
            </span>
            <span className="font-semibold">
              {clockStartTime}
              {clockEndTime ? (
                <>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="mx-2 text-bz-text-tertiary"
                  />
                  <span>{clockEndTime}</span>
                </>
              ) : undefined}
            </span>
            <span className="text-bz-text-tertiary">•</span>
            <span className="text-bz-text-tertiary">Elapsed</span>
            <span className="font-semibold text-bz-green-800">
              {clockElapsed}
            </span>
          </div>
        )
      }
      return undefined
    }, [clockElapsed, clockEndTime, clockStartTime])

    const sections = useMemo<SectionedSection[]>(() => {
      const subSections: SectionedSection[] = [
        {
          verticalPaddingClassName: 'pb-3',
          content: (
            <ProgressSteps
              withLabels
              assignmentStatus={assignmentStatus}
              assignmentStatusUpdatedAt={
                assignment?.assignmentStatus?.updatedAt
              }
              appointmentCancellationStatus={appointmentCancellationStatus}
            />
          ),
        },
      ]

      if (changeStatusSectionContent) {
        subSections.push({
          verticalPaddingClassName: 'pt-3',
          content: changeStatusSectionContent,
        })
      }

      const sections: SectionedSection[] = [
        <SectionedContent dashed sections={subSections} />,
      ]
      if (clockSectionContent) {
        sections.push({
          coloredIn: true,
          verticalPaddingClassName: 'pt-1 pb-2',
          content: clockSectionContent,
        })
      }

      return sections
    }, [
      appointmentCancellationStatus,
      assignment?.assignmentStatus?.updatedAt,
      assignmentStatus,
      changeStatusSectionContent,
      clockSectionContent,
    ])

    const [cancelOpen, openCancel, closeCancel] = useModalState()

    const redirectOnCancel = useCallback(() => {
      navigate('/')
    }, [navigate])

    return (
      <>
        <SectionedCard sections={sections} />
        <OnsiteBasicModal
          open={changeStatusOpen}
          onClose={closeChangeStatus}
          header="Change status"
          headerBordered
        >
          <div className="flex flex-col gap-4">
            {dropdownItems}
            <OnsiteMenuItem
              label="Cancel Appointment"
              onClick={openCancel}
              icon={
                <FontAwesomeIcon icon={faSquareX} className="text-bz-red-800" />
              }
              iconCircleColorClassName="bg-bz-red-200"
            />
          </div>
        </OnsiteBasicModal>
        {notifyCustomerProps.open && (
          <SideEffectConfirmModal
            {...notifyCustomerProps}
            danger={false}
            header="Notify the homeowner?"
          >
            Would you like to send a notification to{' '}
            <span className="font-semibold">{pointOfContactFullName}</span> to
            notify them that you are on the way?
            <br />
            <br />
            The timer for your timesheet will also{' '}
            <span className="font-semibold">
              start at {BzDateFns.format(BzDateFns.now(tzId), 'h:mm a')}
            </span>
          </SideEffectConfirmModal>
        )}
        {completeFormProps.open && (
          <CompleteForm
            {...completeFormProps}
            jobAppointmentGuid={apptGuid}
            checklistInstances={checklistInstances}
            endOfAppointmentEnabled={
              !!isEndOfAppointmentWorkflowEnabledForCompany
            }
            pointOfContactFirstName={pointOfContactFirstName}
            pointOfContactPhoneNumber={pointOfContactPhoneNumber}
            pointOfContactEmailAddress={pointOfContactEmailAddress}
          />
        )}
        {cancelOpen && (
          <CancelAppointmentModal
            onClose={closeCancel}
            onConfirm={redirectOnCancel}
            appointmentGuid={apptGuid}
            // assignmentGuid={assignment?.jobAppointmentAssignmentGuid}
            // assignmentTechName={`${firstName} ${lastName}`}
          />
        )}
      </>
    )
  },
)
