import { z } from 'zod'
import { EquipmentTypeJobLink, JobToEquipmentRelationshipType } from '../Job'

export enum EquipmentType {
  AIR_CLEANER = 'AIR_CLEANER',
  AIR_CONDITIONER = 'AIR_CONDITIONER',
  AIR_FILTER = 'AIR_FILTER',
  GAS_FURNACE = 'GAS_FURNACE',
  OIL_FURNACE = 'OIL_FURNACE',
  HEAT_PUMP = 'HEAT_PUMP',
  AIR_HANDLER = 'AIR_HANDLER',
  DUCTLESS_INDOOR = 'DUCTLESS_INDOOR',
  DUCTLESS_OUTDOOR = 'DUCTLESS_OUTDOOR',
  DUCTLESS_SYSTEM = 'DUCTLESS_SYSTEM',
  DUCTLESS_SYSTEM_OTHER = 'DUCTLESS_SYSTEM_OTHER',
  GEOTHERMAL_HEAT_PUMP = 'GEOTHERMAL_HEAT_PUMP',
  EVAPORATIVE_COOLER = 'EVAPORATIVE_COOLER',
  EVAPORATOR_COIL = 'EVAPORATOR_COIL',
  BOILER = 'BOILER',
  VRF_INDOOR = 'VRF_INDOOR',
  VRF_OUTDOOR = 'VRF_OUTDOOR',
  AIR_CONDITION_FURNACE_PACKAGE_UNIT = 'AIR_CONDITION_FURNACE_PACKAGE_UNIT',
  HYBRID_HEAT_PUMP_FURNACE_PACKAGE_UNIT = 'HYBRID_HEAT_PUMP_FURNACE_PACKAGE_UNIT',
  ROOFTOP_UNIT = 'ROOFTOP_UNIT',
  MAKEUP_AIR_UNIT = 'MAKEUP_AIR_UNIT',
  DEHUMIDIFIER = 'DEHUMIDIFIER',
  HUMIDIFIER = 'HUMIDIFIER',
  IAQ_DEVICE_MISC = 'IAQ_DEVICE_MISC',
  WATER_HEATER = 'WATER_HEATER',
  HEAT_PUMP_WATER_HEATER = 'HEAT_PUMP_WATER_HEATER',
  TANKLESS_WATER_HEATER = 'TANKLESS_WATER_HEATER',
  STORAGE_TANK_WATER_HEATER = 'STORAGE_TANK_WATER_HEATER',
  GAS_WATER_HEATER = 'GAS_WATER_HEATER',
  OIL_WATER_HEATER = 'OIL_WATER_HEATER',
  HEATED_FLOORING = 'HEATED_FLOORING',
  HEATED_DRIVEWAY = 'HEATED_DRIVEWAY',
  AIR_SCRUBBER = 'AIR_SCRUBBER',
  UV_LAMP = 'UV_LAMP',
  VENTILATOR = 'VENTILATOR',
  BRANCH_BOX = 'BRANCH_BOX',
  THERMOSTAT = 'THERMOSTAT',
  ZONING_SYSTEM = 'ZONING_SYSTEM',
  DUCTWORK = 'DUCTWORK',
  SHEET_METAL = 'SHEET_METAL',
  // PTAC refers to "Packaged Terminal Air Conditioner." Common in hotels and commercial buildings.
  PTAC = 'PTAC',
  // Split air conditioning system or heat pump that is installed through an exterior wall of a building
  THROUGH_THE_WALL_CONDENSER = 'THROUGH_THE_WALL_CONDENSER',
  COMMERCIAL_SPLIT_SYSTEM = 'COMMERCIAL_SPLIT_SYSTEM',

  ///
  /// Electrician Equipment
  ///
  BREAKER_PANEL = 'BREAKER_PANEL',
  ELECTRIC_VEHICLE_CHARGING_STATION = 'ELECTRIC_VEHICLE_CHARGING_STATION',
  ELECTRIC_SERVICE = 'ELECTRIC_SERVICE',
  GENERATOR = 'GENERATOR',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_EQUIPMENT_TYPE = 'UNKNOWN_EQUIPMENT_TYPE',
}

export const EquipmentTypeSchema = z.nativeEnum(EquipmentType)

const displayNames = {
  [EquipmentType.AIR_CONDITIONER]: 'Air Conditioner',
  [EquipmentType.AIR_CLEANER]: 'Air Cleaner',
  [EquipmentType.AIR_FILTER]: 'Air Filter',
  [EquipmentType.GAS_FURNACE]: 'Gas Furnace',
  [EquipmentType.OIL_FURNACE]: 'Oil Furnace',
  [EquipmentType.HEAT_PUMP]: 'Heat Pump',
  [EquipmentType.AIR_HANDLER]: 'Air Handler',
  [EquipmentType.DUCTLESS_INDOOR]: 'Mini-split Indoor Unit',
  [EquipmentType.DUCTLESS_OUTDOOR]: 'Mini-split Outdoor Unit',
  [EquipmentType.DUCTLESS_SYSTEM]: 'Mini-split System',
  [EquipmentType.DUCTLESS_SYSTEM_OTHER]: 'Mini-split System Other',
  [EquipmentType.GEOTHERMAL_HEAT_PUMP]: 'Geothermal Heat Pump',
  [EquipmentType.EVAPORATIVE_COOLER]: 'Evaporative Cooler',
  [EquipmentType.EVAPORATOR_COIL]: 'Evaporator Coil',
  [EquipmentType.BOILER]: 'Boiler',
  [EquipmentType.VRF_INDOOR]: 'VRF Indoor',
  [EquipmentType.VRF_OUTDOOR]: 'VRF Outdoor',
  [EquipmentType.AIR_CONDITION_FURNACE_PACKAGE_UNIT]: 'Air Conditioner / Furnace Package Unit',
  [EquipmentType.HYBRID_HEAT_PUMP_FURNACE_PACKAGE_UNIT]: 'Hybrid Heat Pump / Furnace Package Unit',
  [EquipmentType.COMMERCIAL_SPLIT_SYSTEM]: 'Commercial Split System',
  [EquipmentType.ROOFTOP_UNIT]: 'Rooftop Unit',
  [EquipmentType.MAKEUP_AIR_UNIT]: 'Make-up Air Unit',
  [EquipmentType.DEHUMIDIFIER]: 'Dehumidifer',
  [EquipmentType.HUMIDIFIER]: 'Humidifier',
  [EquipmentType.IAQ_DEVICE_MISC]: 'IAQ Device (Misc)',
  [EquipmentType.UNKNOWN]: 'Unknown',
  [EquipmentType.BRANCH_BOX]: 'Branch Box',
  [EquipmentType.GENERATOR]: 'Generator',
  [EquipmentType.HEATED_FLOORING]: 'Radiant Flooring System',
  [EquipmentType.HEATED_DRIVEWAY]: 'Heated Driveway System',
  [EquipmentType.UV_LAMP]: 'UV Lamp',
  [EquipmentType.AIR_SCRUBBER]: 'Air Scrubber',
  [EquipmentType.VENTILATOR]: 'Ventilator (ERV / HRV)',
  [EquipmentType.THERMOSTAT]: 'Thermostat',
  [EquipmentType.ZONING_SYSTEM]: 'Zoning System',
  [EquipmentType.PTAC]: 'PTAC',
  [EquipmentType.THROUGH_THE_WALL_CONDENSER]: 'Through the Wall Condenser',
  [EquipmentType.ELECTRIC_VEHICLE_CHARGING_STATION]: 'EV Charging Station',
  [EquipmentType.HEAT_PUMP_WATER_HEATER]: 'Heat Pump Water Heater', // also a tank
  [EquipmentType.TANKLESS_WATER_HEATER]: 'Tankless Water Heater',
  [EquipmentType.STORAGE_TANK_WATER_HEATER]: 'Storage Tank Water Heater',
  [EquipmentType.WATER_HEATER]: 'Water Heater',
  [EquipmentType.GAS_WATER_HEATER]: 'Gas Water Heater',
  [EquipmentType.OIL_WATER_HEATER]: 'Oil Water Heater',
  [EquipmentType.BREAKER_PANEL]: 'Breaker Panel',
  [EquipmentType.ELECTRIC_SERVICE]: 'Electric Service',
  [EquipmentType.UNKNOWN_EQUIPMENT_TYPE]: 'Unknown Equipment Type',
  [EquipmentType.DUCTWORK]: 'Ductwork',
  [EquipmentType.SHEET_METAL]: 'Sheet Metal',
} satisfies Record<EquipmentType, string>

export const EquipmentDisplayNames = displayNames
export const EquipmentDisplayNamesSchema = z.nativeEnum(displayNames)
export type EquipmentDisplayName = z.infer<typeof EquipmentDisplayNamesSchema>
export const isEquipmentType = (x: unknown): x is EquipmentType => {
  return typeof x === 'string' && Object.keys(EquipmentType).includes(x)
}

export const formatMaybeEquipmentType = (s: EquipmentType | undefined): string =>
  s ? formatEquipmentType(s) : 'Unknown'
export const formatEquipmentType = (s: EquipmentType): string => displayNames[s]
export const formatEquipmentTypeString = (s: string): string => formatMaybeEquipmentType(s as EquipmentType)
export const formatJobToEquipmentRelationshipTypeActiveTense = (relationship: JobToEquipmentRelationshipType) => {
  switch (relationship) {
    case 'SERVICING':
      return 'Repairing'
    case 'MAINTAINING':
      return 'Maintaining'
    case 'INSTALLING':
      return 'Installing'
    case 'DEACTIVATING':
      return 'Replacing'
    case 'LINKED':
      return undefined
  }
}

export const formatEquipmentJobLink = (link: EquipmentTypeJobLink) => {
  return [
    formatJobToEquipmentRelationshipTypeActiveTense(link.relationshipType),
    formatMaybeEquipmentType(link.equipmentType),
    link.estimatedEquipmentAge ? `(${link.estimatedEquipmentAge})` : undefined,
  ]
    .filter(Boolean)
    .join(' ')
}
export const ESTIMATED_EQUIPMENT_AGE_OPTIONS = [
  '0 - 2 y/o',
  '3 - 6 y/o',
  '7 - 10 y/o',
  '11 - 15 y/o',
  '15+ y/o',
  'Unknown age',
] as const

export type EstimatedEquipmentAgeOption = (typeof ESTIMATED_EQUIPMENT_AGE_OPTIONS)[number]

export const ESTIMATED_EQUIPMENT_AGE_TO_MAX_AGE_MAP: Record<(typeof ESTIMATED_EQUIPMENT_AGE_OPTIONS)[number], number> =
  {
    '0 - 2 y/o': 2,
    '3 - 6 y/o': 6,
    '7 - 10 y/o': 10,
    '11 - 15 y/o': 15,
    '15+ y/o': 15,
    'Unknown age': 0,
  }
