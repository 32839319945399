import {
  BzDateFns,
  convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary,
  EquipmentInstallationParty,
  EquipmentInstallationPartyDisplayNames,
  formatEquipmentTypeString,
  InstalledEquipmentSummary,
  LocationCommon,
  noOp,
  toPlural,
} from '@breezy/shared'
import { faEdit } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import { Link } from '../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useModalState } from '../../utils/react-utils'
import { EquipmentDetailsModal } from '../EquipmentDetailsModal/EquipmentDetailsModal'
import { LoadingSpinner } from '../LoadingSpinner'
import { useTechExpDrawers } from '../TechnicianApp/TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'
import { EquipmentWidgetEquipment } from './EquipmentWidget.gql'

type EquipmentProps = Omit<EquipmentWidgetProps, 'fetching' | 'equipment'> & {
  equipment: EquipmentWidgetEquipment
}

const Equipment = React.memo<EquipmentProps>(
  ({ readonly, location, equipment }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const {
      equipmentType,
      manufacturer,
      modelNumber,
      serialNumber,
      installationDate,
      estimatedEndOfLifeDate,
      installationParty,
    } = equipment
    const [detailsOpen, openDetails, closeDetails] = useModalState()
    const drawers = useTechExpDrawers()

    const equipmentDetailsEquipment = useMemo<InstalledEquipmentSummary>(
      () =>
        convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary({
          ...equipment,
          locationGuid: location.locationGuid,
        }),
      [equipment, location.locationGuid],
    )

    const onEdit = useCallback(() => {
      drawers.beginUpsertInstalledEquipment(
        location,
        equipmentDetailsEquipment,
        noOp,
      )
    }, [drawers, equipmentDetailsEquipment, location])

    const estimatedInstallDateContent = useMemo(() => {
      if (!installationDate) {
        return undefined
      }
      const date = BzDateFns.parseLocalDate(installationDate)
      const year = BzDateFns.getYear(date)
      const thisYear = BzDateFns.getYear(BzDateFns.now(tzId))
      const years = thisYear - year
      return `${year} (${years} ${toPlural(years, 'yr')})`
    }, [installationDate, tzId])

    const estimatedEOLContent = useMemo(() => {
      if (!estimatedEndOfLifeDate) {
        return undefined
      }

      return BzDateFns.format(
        BzDateFns.parseLocalDate(estimatedEndOfLifeDate),
        'MMM d, yyyy',
      )
    }, [estimatedEndOfLifeDate])

    const lastJobDateContent = useMemo(() => {
      if (!equipment.location.jobs[0]?.account.lastJobDate) {
        return undefined
      }

      return BzDateFns.formatFromISO(
        equipment.location.jobs[0]?.account.lastJobDate,
        'MMM d, yyyy',
        tzId,
      )
    }, [equipment.location.jobs, tzId])

    const actionsSection = useMemo<SectionedSection | undefined>(() => {
      if (readonly) {
        return undefined
      }
      const items: React.ReactNode[] = [
        <Button
          icon={<FontAwesomeIcon icon={faEdit} />}
          size="large"
          onClick={onEdit}
        >
          Edit
        </Button>,
      ]

      if (items.length) {
        return {
          verticalPaddingClassName: 'pt-3 pb-4',
          content: (
            <div className="flex flex-row items-center space-x-2">{items}</div>
          ),
        }
      }
    }, [onEdit, readonly])

    const sections = useMemo<SectionedSection[]>(() => {
      const sections: SectionedSection[] = [
        {
          verticalPaddingClassName: 'pt-4 pb-2.5',
          content: (
            <Link bold onClick={openDetails}>
              {formatEquipmentTypeString(equipmentType)}
              {manufacturer ? ` (${manufacturer})` : ''}
            </Link>
          ),
        },
        {
          verticalPaddingClassName: 'pt-2 pb-3',
          content: (
            <LabeledItemGrid
              items={[
                'Model #',
                modelNumber,
                'Serial #',
                serialNumber,
                'Est. Install Date',
                estimatedInstallDateContent,
                'Est. End of Life',
                estimatedEOLContent,
                'Installation Party',
                installationParty
                  ? EquipmentInstallationPartyDisplayNames[
                      installationParty as EquipmentInstallationParty
                    ]
                  : undefined,
                'Last Job Date',
                lastJobDateContent,
              ]}
            />
          ),
        },
      ]
      if (actionsSection) {
        sections.push(actionsSection)
      }
      return sections
    }, [
      actionsSection,
      equipmentType,
      estimatedEOLContent,
      estimatedInstallDateContent,
      installationParty,
      lastJobDateContent,
      manufacturer,
      modelNumber,
      openDetails,
      serialNumber,
    ])

    return (
      <>
        <SectionedCard dashed sections={sections} />
        <EquipmentDetailsModal
          open={detailsOpen}
          onClose={closeDetails}
          equipment={equipmentDetailsEquipment}
        />
      </>
    )
  },
)

export type EquipmentWidgetProps = {
  equipment: EquipmentWidgetEquipment[]
  location: LocationCommon
  fetching?: boolean
  readonly?: boolean
}

export const EquipmentWidget = React.memo<EquipmentWidgetProps>(
  ({ equipment, fetching, ...rest }) => {
    if (fetching) {
      return <LoadingSpinner />
    }
    return (
      <div className="space-y-3">
        {equipment.map(equipment => (
          <Equipment
            key={equipment.installedEquipmentGuid}
            equipment={equipment}
            {...rest}
          />
        ))}
      </div>
    )
  },
)
