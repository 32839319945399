import React, { useCallback, useMemo, useState } from 'react'
import { useModalState } from '../../utils/react-utils'
import { OnsiteConfirmModal } from './OnsiteModal'

export type BaseSideEffectConfirmModalProps = {
  onYes: () => void
  onNo: () => void
  open?: boolean
}

type OnContinue = (confirmed: boolean) => void

export const useSideEffectConfirmModal = (): [
  withConfirmation: (onContinue: OnContinue) => void,
  props: BaseSideEffectConfirmModalProps,
] => {
  const [sideEffectConfirmOpen, openSideEffectConfirm, closeSideEffectConfirm] =
    useModalState()

  const [onContinueAction, setOnContinueAction] = useState<
    { action: OnContinue } | undefined
  >()

  const withConfirmation = useCallback(
    (onContinue: OnContinue) => {
      openSideEffectConfirm()
      setOnContinueAction({
        action: onContinue,
      })
    },
    [openSideEffectConfirm],
  )

  const onYes = useCallback(() => {
    onContinueAction?.action(true)
    closeSideEffectConfirm()
  }, [closeSideEffectConfirm, onContinueAction])

  const onNo = useCallback(() => {
    onContinueAction?.action(false)
    closeSideEffectConfirm()
  }, [closeSideEffectConfirm, onContinueAction])

  const props = useMemo(
    () => ({
      onYes,
      onNo,
      open: sideEffectConfirmOpen,
    }),
    [onNo, onYes, sideEffectConfirmOpen],
  )

  return [withConfirmation, props]
}

type SideEffectConfirmModalProps = BaseSideEffectConfirmModalProps &
  React.PropsWithChildren<{
    header: string
    confirmText?: string
    cancelText?: string
    danger?: boolean
  }>

export const SideEffectConfirmModal = React.memo<SideEffectConfirmModalProps>(
  ({
    onYes,
    onNo,
    children,
    open = true,
    danger = true,
    header,
    confirmText = 'Yes',
    cancelText = 'No',
  }) => {
    return (
      <OnsiteConfirmModal
        open={open}
        danger={danger}
        onCancel={onNo}
        onConfirm={onYes}
        header={header}
        confirmText={confirmText}
        cancelText={cancelText}
      >
        {children}
      </OnsiteConfirmModal>
    )
  },
)
