import {
  BzDateFns,
  CalculatePaths,
  formatUsc,
  getAllInstalledEquipmentFromVisits,
  getNumUnusedMaintenancePlanVisits,
  MaintenancePlanPaymentIntervalDisplayNames,
  toPlural,
} from '@breezy/shared'
import React, { useMemo } from 'react'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import { FormattedEmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import MaintenancePlanStatusTag from '../../pages/MaintenancePlanDetailsPage/MaintenancePlanStatusTag'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { LoadingSpinner } from '../LoadingSpinner'
import { MaintenancePlansWidgetPlan } from './useMaintenancePlansWidgetData'

type MPProps = MaintenancePlansWidgetPlan & {
  readonly?: boolean
}

const MP = React.memo<MPProps>(
  ({
    maintenancePlanGuid,
    status,
    paymentFlow,
    planTypeName,
    locationAddress,
    visits,
    activatedAt,
    billingAnchor,
    terminatesAt,
    paymentAmountUsc,
    paymentInterval,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const numCoveredEquipment = useMemo(
      () => getAllInstalledEquipmentFromVisits(visits),
      [visits],
    )

    const sections = useMemo(() => {
      const sections: SectionedSection[] = [
        {
          verticalPaddingClassName: 'pb-2.5 pt-4',
          content: (
            <div className="flex flex-row justify-between">
              <Link
                to={CalculatePaths.maintenancePlanDetails({
                  maintenancePlanGuid,
                })}
                bold
              >
                {planTypeName}
              </Link>
              <MaintenancePlanStatusTag
                status={status}
                paymentFlow={paymentFlow}
              />
            </div>
          ),
        },
        {
          verticalPaddingClassName: 'py-3',
          content: (
            <LabeledItemGrid
              items={[
                'Location',
                locationAddress.line1,
                'Visits Remaining',
                <Link
                  to={CalculatePaths.maintenancePlanDetails({
                    maintenancePlanGuid,
                  })}
                >
                  {getNumUnusedMaintenancePlanVisits(visits)} of {visits.length}
                </Link>,
                'Billing Frequency',
                paymentInterval
                  ? MaintenancePlanPaymentIntervalDisplayNames[paymentInterval]
                  : FormattedEmDash,
                'Billing Anchor',
                billingAnchor ?? FormattedEmDash,
                'Activation Date',
                activatedAt
                  ? BzDateFns.formatFromISO(activatedAt, 'MMM d, yyyy', tzId)
                  : FormattedEmDash,
                'Renewal Date',
                terminatesAt
                  ? BzDateFns.formatFromISO(terminatesAt, 'MMM d, yyyy', tzId)
                  : 'Auto-Renewing',
                'Covered Equipment',
                // TODO: this should link somewhere
                `${numCoveredEquipment.length} ${toPlural(
                  numCoveredEquipment.length,
                  'piece',
                )}`,
                'Plan Cost',
                `${formatUsc(paymentAmountUsc ?? 0)}${
                  paymentInterval
                    ? ` ${MaintenancePlanPaymentIntervalDisplayNames[paymentInterval]}`
                    : ''
                }`,
              ]}
            />
          ),
        },
      ]

      return sections
    }, [
      activatedAt,
      billingAnchor,
      locationAddress.line1,
      maintenancePlanGuid,
      numCoveredEquipment.length,
      paymentAmountUsc,
      paymentFlow,
      paymentInterval,
      planTypeName,
      status,
      terminatesAt,
      tzId,
      visits,
    ])

    return <SectionedCard dashed sections={sections} />
  },
)

export type MaintenancePlansWidgetProps = {
  maintenancePlans: MaintenancePlansWidgetPlan[]
  readonly?: boolean
  fetching?: boolean
}

export const MaintenancePlansWidget = React.memo<MaintenancePlansWidgetProps>(
  ({ maintenancePlans, readonly, fetching }) => {
    if (fetching) {
      return <LoadingSpinner />
    }
    return (
      <div className="space-y-3">
        {maintenancePlans.map(mp => (
          <MP key={mp.maintenancePlanGuid} readonly={readonly} {...mp} />
        ))}
      </div>
    )
  },
)
