import {
  BzDateFns,
  CalculatePaths,
  formatUsc,
  getDisplayNameForAccountType,
} from '@breezy/shared'
import {
  faEye,
  faHouseUser,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSubscription } from 'urql'
import { DetailChip } from '../../../../adam-components/ListPage/DetailChip'
import { ItemIconActionRow } from '../../../../adam-components/OnsitePage/OnsitePageSimpleSectionItem'
import { SectionedContent } from '../../../../adam-components/SectionedCard/SectionedContent'
import { FormattedEmDash } from '../../../../elements/EmDash/EmDash'
import { useExpectedCompanyTimeZoneId } from '../../../../providers/PrincipalUser'
import tailwindConfig from '../../../../tailwind.config'
import { LoadingSpinner } from '../../../LoadingSpinner'
import { Tag } from '../../../Tags'
import {
  AppointmentDetails,
  VISIT_ACCOUNT_DETAILS_DATA_SUBSCRIPTION,
  VisitAccountDetailsAccount,
} from './AppointmentDetail.gql'

type BasicSectionProps = React.PropsWithChildren<{
  title: React.ReactNode
}>

const BasicSection = React.memo<BasicSectionProps>(({ title, children }) => (
  <div className="text-base">
    <div className="font-semibold">{title}</div>
    <div className="mt-2">{children}</div>
  </div>
))

type AccountDetailsProps = {
  accountGuid: string
  maintenancePlansEnabled: boolean
  maintenancePlan?: AppointmentDetails['job']['location']['maintenancePlans'][0]
}

type AccountDetailsWithDataProps = AccountDetailsProps &
  VisitAccountDetailsAccount

const AccountDetailsWithData = React.memo<AccountDetailsWithDataProps>(
  ({
    accountType,
    accountGuid,
    accountDisplayName,
    tags,
    maintenancePlansEnabled,
    maintenancePlan,
    jobsAggregate,
    lastJobDate,
    createdAt,
    accountLeadSource,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const navigate = useNavigate()
    const maintenancePlanColor =
      maintenancePlan?.maintenancePlanDefinition?.flare?.primaryColorHex ??
      tailwindConfig.theme.extend.colors.bz.primary

    const { avgInvoiceUsc, ltvUsc } = useMemo(() => {
      let totalUsc = 0
      let jobsWithPaidInvoices = 0
      for (const { jobInvoices } of jobsAggregate.nodes) {
        if (jobInvoices.length) {
          jobsWithPaidInvoices++
          for (const { invoice } of jobInvoices) {
            totalUsc += invoice.totalUsc
          }
        }
      }
      return {
        ltvUsc: totalUsc,
        avgInvoiceUsc: jobsWithPaidInvoices
          ? totalUsc / jobsWithPaidInvoices
          : 0,
      }
    }, [jobsAggregate.nodes])

    const leadSource = useMemo(() => {
      const leadSource = accountLeadSource?.[0]?.companyLeadSource
      return (
        leadSource?.canonicalLeadSourceNameOverride ??
        leadSource?.canonicalLeadSourceName
      )
    }, [accountLeadSource])

    return (
      <SectionedContent
        dashed
        sections={[
          {
            verticalPaddingClassName: 'pt-4 pb-6',
            content: (
              <ItemIconActionRow
                squareIcon
                smallIconMode
                icon={<FontAwesomeIcon icon={faHouseUser} />}
                subContent={getDisplayNameForAccountType(accountType)}
                rightContent={
                  <Button
                    type="link"
                    size="large"
                    onClick={() => {
                      navigate(CalculatePaths.accountDetails({ accountGuid }))
                    }}
                    icon={<FontAwesomeIcon icon={faEye} />}
                  >
                    View
                  </Button>
                }
              >
                {accountDisplayName}
              </ItemIconActionRow>
            ),
          },
          {
            verticalPaddingClassName: 'pt-6',
            content: (
              <div className="space-y-6">
                <BasicSection title="Scorecards">
                  <div className="flex flex-row flex-wrap gap-2">
                    {(
                      [
                        {
                          label: 'LTV',
                          value: formatUsc(ltvUsc),
                        },
                        {
                          label: '# of Jobs',
                          value: jobsAggregate?.aggregate?.count ?? 0,
                        },
                        {
                          label: 'Avg. Job',
                          value: formatUsc(avgInvoiceUsc),
                        },
                        {
                          label: 'Last Job',
                          value: lastJobDate
                            ? BzDateFns.formatFromISO(
                                lastJobDate,
                                'MMM d, ‘yy',
                                tzId,
                              )
                            : 'N/A',
                        },
                      ] as const
                    ).map(({ label, value }) => (
                      <div
                        key={label}
                        className="rounded-md border border-solid border-bz-border px-2 py-1 text-sm"
                      >
                        <span className="mr-1 font-semibold text-bz-text-tertiary">
                          {label}
                        </span>
                        <span>{value}</span>
                      </div>
                    ))}
                  </div>
                </BasicSection>
                <BasicSection title="Created On">
                  {BzDateFns.formatFromISO(createdAt, 'MMMM d, yyyy', tzId)}
                </BasicSection>
                <BasicSection title="Lead Source">
                  {leadSource ?? FormattedEmDash}
                </BasicSection>
                <BasicSection title="Account Tags">
                  {(maintenancePlansEnabled && maintenancePlan) ||
                  tags.length > 0 ? (
                    <div className="flex flex-row flex-wrap gap-2">
                      {maintenancePlansEnabled && maintenancePlan && (
                        <DetailChip
                          accentColor={maintenancePlanColor}
                          icon={
                            <FontAwesomeIcon
                              icon={faScrewdriverWrench}
                              style={{ color: maintenancePlanColor }}
                            />
                          }
                        >
                          {maintenancePlan.maintenancePlanDefinition
                            ?.marketingInfo?.name ?? 'Maintenance Plan'}
                        </DetailChip>
                      )}
                      {tags.map(({ tag }) => (
                        <Tag key={tag.name} tag={tag} tagStyleVersion="v2" />
                      ))}
                    </div>
                  ) : (
                    FormattedEmDash
                  )}
                </BasicSection>
              </div>
            ),
          },
        ]}
      />
    )
  },
)

export const AccountDetails = React.memo<AccountDetailsProps>(
  ({ accountGuid, ...rest }) => {
    const [{ fetching, data }] = useSubscription({
      query: VISIT_ACCOUNT_DETAILS_DATA_SUBSCRIPTION,
      variables: {
        accountGuid,
      },
    })

    if (fetching || !data?.accountsByPk) {
      return <LoadingSpinner />
    }

    return (
      <AccountDetailsWithData
        accountGuid={accountGuid}
        {...data.accountsByPk}
        {...rest}
      />
    )
  },
)
