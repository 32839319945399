import { gql } from '../../generated'

export const STATUS_CARD_QUERY = gql(/* GraphQL */ `
  subscription UserTimeClockStatusCardQuery(
    $companyGuid: uuid!
    $userGuid: uuid!
    $startTime: timestamptz!
  ) {
    timesheetEntries(
      where: {
        companyGuid: { _eq: $companyGuid }
        userGuid: { _eq: $userGuid }
        finalStartTime: { _gte: $startTime }
      }
      orderBy: { finalStartTime: ASC, finalEndTime: DESC_NULLS_LAST }
    ) {
      finalStartTime
      finalEndTime
      entryLengthInMinutes
      timesheetEntryActivity {
        activityName
      }
      timesheetEntryLinkData {
        jobGuid
        jobAppointmentGuid
        jobAppointmentAssignmentGuid
      }
    }
  }
`)
